import React from 'react'

export function arrowLeft() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14" style={{enableBackground: "new 0 0 8 14"}} xmlSpace="preserver">
      <g className="stroke" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M7 1L1 7M1 7l6 6"/>
      </g>
    </svg>
  )
}
