import React from 'react'

export function cargoTrailer() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373 125" style={{enableBackground: "new 0 0 373 125"}} xmlSpace="preserver">
      <g className="fill">
        <path d="M295 94v31h-5V94h-10.644l-4.235 7.752A12 12 0 0 1 264.59 108h-85.414v-4c0-12.703-10.297-23-23-23h-2.175c-12.703 0-23 10.297-23 23v4H85.176V12c0-6.627 5.373-12 12-12h168.722a12 12 0 0 1 11.114 7.473l8.365 20.536a22 22 0 0 1 1.626 8.3v38.33a21 21 0 0 1-2.57 10.066L282.087 89H302v5h-7zm-140 31c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20-8.954 20-20 20zm0-8c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12z"/>
      </g>
    </svg>
  )
}
