import React, { Component, Fragment } from 'react'
import { DEFAULT_PUBLICATION_TYPE, ROUTE, UI } from '../config'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import Loader from '../components/Loader'
import AutoBreadCrumb from '../components/AutoBreadCrumb'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'
import * as ContentsActions from 'tw-oi-core/actions/ContentsActions'

import ScreenHead from '../components/ScreenHead'
import { filterTopicsByField } from 'tw-oi-core/utils/data'
import { trackTextView, trackFavoritesTopicAdded } from 'tw-oi-core/services/analytics'

import TopicVideo from '../components/TopicVideo'
import {
  getRootFolderResourceKey,
  getVideosAndPdfsFromRelatedContents,
  isRootFolder,
  updatePhoneNumberToClickable,
  updateUrlsPlaceholders,
  isCollapsedFolder,
  recalculateTopicsHeightForIOS
} from '../extras/utils'
import ErrorMessage from '../components/ErrorMessage'
import { getConfig } from 'tw-oi-core'
import TopicNavigation from '../components/TopicNavigation'
import Icon from '../components/Icon'
import Toast from '../components/Toast'
import RelatedContentsSidePanelView from '../components/RelatedContentsSidePanelView'
import Media from '../components/Media'
import StickyBar from '../components/StickyBar'
import OwnersManualPdfs from '../components/OwnersManualPdfs'
import RelatedContentTabs, { RelatedContentTabNames } from '../components/RelatedContentTabs'
import RelatedContentList from '../components/RelatedContentList'
import { ScrollSink } from '../components/MotionScroll'


class Topic extends Component {

  static placements = {
    [ROUTE.TOPPICKS]: 'Recommended',
    [ROUTE.EXPLORE]: 'Explore',
    [ROUTE.SEARCH]: 'Search',
    [ROUTE.FAVORITES]: 'Favorites',
  }

  static propTypes = {
    publications: ImmutablePropTypes.list,
    ownersManualPdfs: ImmutablePropTypes.list,
    topics: ImmutablePropTypes.list.isRequired,
    foldersIndex: ImmutablePropTypes.map,
    folders: ImmutablePropTypes.list,
    videosIndex: PropTypes.object,
    favorites: ImmutablePropTypes.map,
    assetMap: PropTypes.object,
    fetchingAsset: PropTypes.bool,
    errorAsset: PropTypes.object,
    ContentsActions:PropTypes.shape({
      getAsset: PropTypes.func.isRequired
    }).isRequired,
    UserActions: PropTypes.shape({
      addRecent: PropTypes.func.isRequired,
      removeFavorite: PropTypes.func.isRequired,
      addFavorite: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        topicId: PropTypes.string.isRequired,
        referrer: PropTypes.string.isRequired,
        videoId: PropTypes.string,
        time: PropTypes.string,
      }).isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    currentProductType: PropTypes.string,
    currentProductSubtype: PropTypes.string,
    baseRoute: PropTypes.string.isRequired,
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool
    }).isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      activeFolder: null,
      rootFolderResourceKey: null,
      isActiveFolderRoot: false,

      currentTopic: null,
      currentVideo: null,
      time: null,

      isFavorite: false,

      relatedContentActiveTab: RelatedContentTabNames.TOPIC,
      hasRelatedContents: false,
      relatedContents: {},
      collapsedTopics: null,

      fetchingAsset: false
    }

    this.toast = React.createRef()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.fetchingAsset && nextProps.fetchingAsset) {
      return { fetchingAsset: true }
    }
  }

  componentDidMount() {
    this.loadTopic(this.props.match.params.topicId)
  }

  componentDidUpdate(prevProps, prevState) {
    const {topicId: curTopicId, videoId: curVideoId} = this.props.match.params
    const {topicId: prevTopicId, videoId: prevVideoId} = prevProps.match.params
    const { currentTopic } = this.state

    if (prevTopicId !== curTopicId || prevVideoId !== curVideoId) {
      this.loadTopic(curTopicId)
    }

    if (prevState.currentTopic !== currentTopic && currentTopic) {
      this.updateAssets(currentTopic)
    }

    // toggle shadow in case we show mobile version in portrait and desktop in landscape
    // timeout is needed as at the moment of rendering there is no .topic-subtitle element yet
    if (prevProps.userMedia.isDesktop !== this.props.userMedia.isDesktop) {
      setTimeout(() => this.toggleShadowOnSubtitle(), 0)
    }
  }

  toggleShadowOnSubtitle = () => {
    const subtitleEl = document.querySelector('.Topics .topics-subtitle')
    const { currentVideo, hasRelatedContents } = this.state

    if (subtitleEl && currentVideo) {
      return subtitleEl.classList.remove('no-shadow')
    }

    if (subtitleEl && hasRelatedContents) {
      return subtitleEl.classList.add('no-shadow')
    }

    if (subtitleEl && !hasRelatedContents) {
      return subtitleEl.classList.remove('no-shadow')
    }
  }

  /**
   * Loads topic data for provided resource key
   *
   * @param {String} topicResourceKey
   */
  loadTopic(topicResourceKey) {
    const { currentYear , currentModel, baseRoute, topics, folders, foldersIndex, publications, videosIndex, history, match, currentBrand, currentProductType, currentProductSubtype } = this.props
    const { videoId } =  this.props.match.params
    const { addRecent } = this.props.UserActions
    const { BROWSE_ROOT_KEY } = getConfig()

    let topic = topics.find(item => item.get('resourceKey') === topicResourceKey)

    // redirect to /browse/folder/XXXX if topicId belongs to folder without bodyHtml inside it
    if (!topic) {
      topic = folders.find(item => item.get('resourceKey') === topicResourceKey)

      if (topic && !topic.get('bodyHtml')) {
        const url = `${baseRoute}${ROUTE.BROWSE}${ROUTE.FOLDER}/${topicResourceKey}`
        history.replace(url)
        topic = null
        return
      }

      // topic not found
      if (!topic) return
    }

    const activeFolder = foldersIndex.find(folder => {
      return folder.get('contents').keySeq().contains(match.params.topicId)
    })
    const rootFolderResourceKey = getRootFolderResourceKey(publications, DEFAULT_PUBLICATION_TYPE, foldersIndex, BROWSE_ROOT_KEY)
    const isActiveFolderRoot = isRootFolder(activeFolder, rootFolderResourceKey)

    const video = videoId && videosIndex[videoId]

    const favorites = this.props.favorites.getIn([currentBrand, currentYear, currentModel, currentProductType, currentProductSubtype].filter(path => path))
    const isFavorite = favorites && favorites.includes(topic.get('resourceKey'))
    let collapsedTopics = null

    // check for collapsed topics
    if (isCollapsedFolder(topic.toJS())) {
      collapsedTopics = foldersIndex.getIn([topic.get('resourceKey'), 'contents']).map(el => el.set('objectKey', el.getIn(['bodyHtml', 'objectKey'])).delete('bodyHtml'))
    }

    // check for related contents in collapsed topics
    let relatedContents = topic && topic.get('relatedContents')
    if (collapsedTopics) {

      const collapsedTopicsInstances = filterTopicsByField(topics, collapsedTopics.keySeq().toJS(), 'resourceKey')
      const attachments = collapsedTopicsInstances.map(topic => topic.get('relatedContents')).flatten().filter(c => c)

      relatedContents = relatedContents ? relatedContents.concat(attachments) : attachments
    }
    const hasRelatedContents = relatedContents && !!relatedContents.size

    this.setState({
      activeFolder,
      rootFolderResourceKey,
      isActiveFolderRoot,

      fetchingAsset: false,
      currentTopic: topic,
      currentVideo: video,

      isFavorite,
      collapsedTopics,
      hasRelatedContents,
      relatedContents: hasRelatedContents && getVideosAndPdfsFromRelatedContents(relatedContents, foldersIndex, publications, videosIndex),
    })

    if (!video) { // Do not load HTML asset in case video was selected
      trackTextView(topic.get('title'))
      addRecent(currentYear, currentModel, topic.get('resourceKey'), currentBrand, currentProductType, currentProductSubtype)
    }
  }

  /**
   * Loads HTML asset for the specified asset object Key
   *
   * @param {object} topic
   */
  updateAssets(topic) {
    const { getAsset } = this.props.ContentsActions
    const { assetMap  } = this.props
    const { collapsedTopics } = this.state
    let assetsPromises = []

    const assetObjectKey = topic.getIn(['bodyHtml', 'objectKey'])
    if (!assetMap[assetObjectKey]) {
      assetsPromises.push(getAsset(assetObjectKey))
    }

    // find additional collapsed assets when treating folder as topic
    if (collapsedTopics) {
      collapsedTopics.forEach(el => {
        const objectKey = el.get('objectKey')

        if (!assetMap[objectKey]) {
          assetsPromises.push(getAsset(objectKey))
        }
      })
    }

    Promise.all(assetsPromises).then(() => {
      this.setState({ fetchingAsset: false })
    })
  }

  /**
   * Constructs the route for going back
   *
   * @return {String}
   */
  getBackRoute() {
    return this.props.match.url.split(ROUTE.TOPIC)[0]
  }

  /**
   * Toggles favorites state and shows appropriate toast message
   */
  toggleFavorite() {
    const {removeFavorite, addFavorite} = this.props.UserActions
    const {currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype, userMedia} = this.props
    const {currentTopic, isFavorite} = this.state

    const topicResourceKey = currentTopic.get('resourceKey')

    if (isFavorite) {
      removeFavorite(currentYear, currentModel, topicResourceKey, currentBrand, currentProductType, currentProductSubtype)
    } else {
      addFavorite(currentYear, currentModel, topicResourceKey, currentBrand, currentProductType, currentProductSubtype)
      trackFavoritesTopicAdded(currentTopic.get('title'))
    }

    this.setState({
      isFavorite: !isFavorite
    })

    let message
    if (userMedia.isDesktop) {
      message =
        <div className='toast-message'>
          <Icon type='success'/>
          <div className='text'>{`"${currentTopic.get('title')}" ${isFavorite ? 'removed from' : 'added to'} favorites`}</div>
        </div>
    } else {
      message = `Bookmark ${isFavorite ? 'removed' : 'added'}.`
    }
    this.toast.current.show(message)
  }

  /**
   * Construct the back route and title for the header
   *
   * @return {{back: (String), backTitle: (String)}}
   */
  getScreenHeadBackRoute() {
    const { match, baseRoute, history } = this.props
    const { isActiveFolderRoot, currentTopic, activeFolder, currentVideo } = this.state

    const isBrowseReferrer = match.params.referrer === ROUTE.BROWSE

    let backRoute = null, backTitle = null

    if (isBrowseReferrer && activeFolder) {
      backRoute = `${baseRoute}${ROUTE.BROWSE}${ROUTE.FOLDER}/${activeFolder.get('resourceKey')}`
      backTitle = isActiveFolderRoot ? UI.OWNERS_MANUAL : activeFolder.get('title')
    } else {
      backRoute = this.getBackRoute()
      backTitle = Topic.placements[match.params.referrer]
    }

    if (currentVideo) {
      backRoute = () => {
        history.replace(`${baseRoute}${match.params.referrer}${ROUTE.TOPIC}/${currentTopic.get('resourceKey')}`)
        this.setState({relatedContentActiveTab: RelatedContentTabNames.VIDEO})
      }
      backTitle = UI.RELATED_CONTENT_PANEL_TITLE.VIDEOS
    }

    return {
      back : backRoute,
      backTitle: backTitle
    }
  }

  renderTopicContent() {
    const { assetMap, match, history, errorAsset, baseRoute } = this.props
    const { currentTopic, activeFolder, currentVideo, hasRelatedContents, collapsedTopics, fetchingAsset, relatedContentActiveTab, relatedContents } = this.state

    const currentTopicRoute = `${baseRoute}${match.params.referrer}${ROUTE.TOPIC}/${currentTopic.get('resourceKey')}`
    const isBrowseReferrer = match.params.referrer === ROUTE.BROWSE

    const assetObjectKey = currentTopic.getIn(['bodyHtml', 'objectKey'])
    const mainAsset = assetMap[assetObjectKey]

    this.toggleShadowOnSubtitle()

    if (currentVideo) {
      return <TopicVideo video={currentVideo} baseRoute={currentTopicRoute} match={match} history={history}/>
    }

    if (errorAsset) {
      return <ErrorMessage className="inverse inline" retryAction={() => this.updateAssets(currentTopic)}/>
    }

    if (fetchingAsset || !mainAsset) {
      return <Loader className="inverse"/>
    }

    recalculateTopicsHeightForIOS()

    // render pdfs or videos on non-desktop
    if (relatedContentActiveTab !== RelatedContentTabNames.TOPIC && !this.props.userMedia.isDesktop) {
      return <div className='tab-content'>
        <RelatedContentList
          relatedContents={relatedContents[relatedContentActiveTab]}
          emptyText={UI.NO_RELATED_CONTENT[relatedContentActiveTab.toUpperCase()]}
          baseRoute={currentTopicRoute}/>
      </div>
    }

    // render additional collapsed assets
    const additionalAssets = collapsedTopics ? collapsedTopics.valueSeq().map(collapsedTopic => {
      const objectKey = collapsedTopic.get('objectKey')
      const currentAsset = assetMap[objectKey]

      if (!currentAsset) return null

      return <Fragment key={objectKey}>
        <div className="topic-subtitle">
          {collapsedTopic.get("title")}
        </div>
        <div className="topic-content" dangerouslySetInnerHTML={{__html: updateUrlsPlaceholders(updatePhoneNumberToClickable(currentAsset.content))}}/>
      </Fragment>
    }) : null

    return <div className='topic-text-container'>
      <div className="topic-content" dangerouslySetInnerHTML={{__html: updateUrlsPlaceholders(updatePhoneNumberToClickable(mainAsset.content))}}/>
      {additionalAssets}
      {isBrowseReferrer &&
      <TopicNavigation
        currentTopic={currentTopic}
        currentFolder={activeFolder}
        className={hasRelatedContents ? 'topic-with-related-contents' : ''}/>}
    </div>
  }

  setActiveTab = (tab) => {
    this.setState({ relatedContentActiveTab: tab})
  }

  render() {
    const { assetMap, match, currentYear, currentModel, currentBrand, foldersIndex, baseRoute, ownersManualPdfs } = this.props
    const { rootFolderResourceKey, currentTopic, activeFolder, currentVideo, relatedContentActiveTab, hasRelatedContents, relatedContents, fetchingAsset } = this.state

    if (!currentTopic) {
      return <Loader type="status" className="inverse">Topic not found</Loader>
    }

    const mainAsset = assetMap[currentTopic.getIn(['bodyHtml', 'objectKey'])]
    const isBrowseReferrer = match.params.referrer === ROUTE.BROWSE
    const currentTopicTitle = currentVideo ? currentVideo.title : currentTopic.get('title')
    const isTopicAssetLoaded = mainAsset && !fetchingAsset
    const currentTopicRoute = `${baseRoute}${match.params.referrer}${ROUTE.TOPIC}/${currentTopic.get('resourceKey')}`

    const favoriteBtn = !currentVideo && <button style={{cursor: 'pointer'}} onClick={this.toggleFavorite.bind(this)}>
      <Icon type='bookmark' className={classNames(this.state.isFavorite && 'active')}/>
    </button>

    return (
      <div className={classNames("Topics", "one", currentVideo && "video")}>
        <ScreenHead {...this.getScreenHeadBackRoute()} landscapeOverlay={true} ownersManualPdfs={ownersManualPdfs}>
          <em>{currentYear} {currentBrand}</em>
          <span>{currentModel}</span>
        </ScreenHead>

        <Media type="mobile">
          <div className="topics-subtitle">
            {currentTopicTitle}
            {favoriteBtn}
          </div>

          {hasRelatedContents && isTopicAssetLoaded && !currentVideo &&
            <RelatedContentTabs
              setActiveTab={this.setActiveTab}
              activeTab={relatedContentActiveTab}
              relatedContents={relatedContents}
              baseRoute={currentTopicRoute} />
          }
        </Media>

        <div className="screen-content">

          <Toast ref={this.toast}/>
          <Media type="desktop">
            <Fragment>
              <StickyBar className="sticky-topics-bar">
                <div className="topics-bar">
                  {isBrowseReferrer ?
                    <AutoBreadCrumb {...{ activeFolder: currentVideo ? currentTopic : activeFolder, foldersIndex, baseRoute, rootFolderResourceKey}} inactive/> :

                    <ul className="topics-breadcrumb inactive">
                      <li><Link to={match.url.split(ROUTE.TOPIC)[0]}>{Topic.placements[match.params.referrer]}</Link></li>

                      {
                        // show current topic in breadcrumbs when video is selected
                        currentVideo &&
                          <React.Fragment>
                            <li><Icon type="arrow-left"/></li>
                            <li><Link to={currentTopicRoute}>{currentTopic.get('title')}</Link></li>
                          </React.Fragment>
                      }
                    </ul>
                  }
                  <OwnersManualPdfs
                    model={currentModel}
                    ownersManualPdfs={ownersManualPdfs}
                  />
                </div>
              </StickyBar>

              <div className="screen-container">
                <div className='content-container'>
                  <div className='content-container-wrapper'>
                    <h1 className="topic-title">
                      {favoriteBtn}
                      {currentTopicTitle}
                    </h1>

                    {this.renderTopicContent()}
                  </div>

                  {
                    hasRelatedContents && (isTopicAssetLoaded || currentVideo) &&
                    <StickyBar className="sticky-related-contents-bar">
                      <RelatedContentsSidePanelView
                        currentVideo={currentVideo}
                        relatedContents={relatedContents}
                        baseRoute={currentTopicRoute}/>
                    </StickyBar>
                  }
                </div>
              </div>
            </Fragment>
          </Media>

          <Media type="mobile">
            <ScrollSink scrollTop={0} resetScroll={true}>
              <div className="screen-container">
                {this.renderTopicContent()}
              </div>
            </ScrollSink>
          </Media>

        </div>
      </div>
    )
  }
}

function mapStateToProps({ contents, vehicle, user }) {
  const { assetMap, publications, topics, folders, foldersIndex, videosIndex, fetchingAsset, errorAsset } = contents
  const { currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype } = vehicle
  const { media:userMedia, favorites } = user

  return { assetMap, publications, topics, folders, foldersIndex, videosIndex, fetchingAsset, errorAsset, currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype, userMedia, favorites }
}

function mapDispatchToProps(dispatch) {
  return {
    ContentsActions: bindActionCreators(ContentsActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topic)

