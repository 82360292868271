import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class DraftSelector extends Component {

  static propTypes = {
    draftPreviewEnabled: PropTypes.bool,
    onChangeMode: PropTypes.func.isRequired,
  }

  static defaultProps = {
    draftPreviewEnabled: false
  }

  render() {
    const { draftPreviewEnabled } = this.props

    return (
      <div className="editor-section">
        <label className="section-label">Content Browsing</label>
        <div className="Select">
          <select value={draftPreviewEnabled} onChange={e => this.props.onChangeMode(e.currentTarget.value === 'true')}>
            <option value="false">Published Content</option>
            <option value="true">Draft Content</option>
          </select>
        </div>
      </div>
    )
  }
}
