import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as CheckedIcon} from './CheckedIcon.svg'
import { ReactComponent as UncheckedIcon} from './UncheckedIcon.svg'

import './RadioInput.scss'

const RadioInput = ({ options, value, label, ...rest }) => {
  return (
    <div className="RadioInputGroup">
      <div className="RadioInputGroup__label">
        {label}
      </div>
      {options.map((option) => {
        const isChecked = option.value === value
        return (
          <label htmlFor={option.label} key={option.label} className='RadioInput'>
            {isChecked ? <CheckedIcon /> : <UncheckedIcon />}
            <input id={option.label} type="radio" {...rest} value={option.value} checked={isChecked} />
            <label htmlFor={option.label}>{option.label}</label>
          </label>
        )
      })}
    </div>
  )
}

RadioInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string
  })),
}

export default RadioInput
