import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Icon from '../components/Icon'

import { UI } from '../config'

import '../styles/SearchInput.scss'

export default class SearchInput extends Component {

  static propTypes = {
    onChange: PropTypes.func,
    onToggle: PropTypes.func,
    searchQuery: PropTypes.string,
    autoFocus: PropTypes.bool
  }

  static defaultProps = {
    onChange: null,
    onToggle: null,
    searchQuery: '',
    autoFocus: true
  }

  input = null

  constructor(props) {
    super(props)

    this.state = {
      query: this.props.searchQuery
    }

    this.handleChangeDelayed = _.debounce(this.handleChangeDelayed.bind(this), UI.SEARCH_BOX_DELAY)
  }

  clearSearchQuery = () => {
    this.setState({query: ""})
    this.props.onChange("")
  }

  handleChange = (event) => {
    this.setState({query: event.target.value})
    this.handleChangeDelayed()
  }

  handleFocus = () => {
    if (this.props.onToggle) this.props.onToggle(true)
  }

  handleBlur = () => {
    if (this.props.onToggle) setTimeout(() => this.props.onToggle(false), UI.FRAME_DELAY)
  }

  /**
   * Initiate change event with delay
   */
  handleChangeDelayed() {

    // Do not initiate search event when user typed in too few characters
    const len = this.state.query.length
    if (len > 0 && len < UI.SEARCH_MIN_CHARACTERS) {
      return
    }

    this.props.onChange(this.state.query.trim())
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => {
        if (this.input) {
          // set carret position to the end of searchQuery
          if (this.state.query) {
            let caretPos = this.state.query.length

            if (typeof this.input.createTextRange !== 'undefined') {
              let range = this.input.createTextRange()
              range.move('character', caretPos)
              range.select()
            } else {
              if (typeof this.input.selectionStart !== 'undefined') {
                this.input.focus()
                this.input.setSelectionRange(caretPos, caretPos)
              } else {
                this.input.focus()
              }
            }
          } else
            this.input.focus()

        }
      }, UI.ANIMATION_DELAY)
    }
  }

  render() {
    return (
      <div className="SearchInput">
        <Icon type="search"/>
        <input type="text" ref={input => this.input = input} value={this.state.query} placeholder="Search"
          onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleBlur} />
        {this.state.query ? <button className="cancel-search" onClick={this.clearSearchQuery}><Icon type="cancel"/></button> : null }
      </div>
    )
  }
}
