import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'tw-oi-core'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { trackBrowseTopicClick, trackBrowseFolderClick, trackHomeFolderClick } from 'tw-oi-core/services/analytics'
import { recalculateTopicsHeightForIOS, getPublicationsForVehicle } from '../extras/utils'

import { CONTENT_TYPE, DEFAULT_PUBLICATION_TYPE, ROUTE, UI } from '../config'

import { connect } from 'react-redux'

import ContentList from '../components/ContentList'
import ScreenHead from '../components/ScreenHead'
import Media from '../components/Media'
import Loader from '../components/Loader'
import ScreenContainer from '../components/ScreenContainer'
import BreadCrumb from '../components/BreadCrumb'
import Icon from '../components/Icon'

import '../styles/Topics.scss'
import {
  OWNERS_MANUAL_TITLE,
  getRootFolderResourceKey,
  getParentFolder,
  isRootFolder,
  isFolder,
  isCollapsedFolder,
  isDitaTopic,
  getArticlesWithTags,
  getRootLevelArticles,
  getUniqueTags,
  tagToRenderItem,
  containsDita,
  containsArticles,
  containsPdfs,
  containsVideos,
  sortContentsByTitle,
} from '../extras/utils'
import StickyBar from '../components/StickyBar'
import OwnersManualPdfs from '../components/OwnersManualPdfs'
import { filteredContents } from './BrandSpecificInformation/BrandSpecificInformation'

class Browse extends Component {
  static propTypes = {
    ownersManualPdfs: ImmutablePropTypes.list,
    fetching: PropTypes.bool,
    contents: PropTypes.shape({
      publications: ImmutablePropTypes.list,
      topics: ImmutablePropTypes.list,
      videos: ImmutablePropTypes.list,
    }),
    publications: ImmutablePropTypes.list,
    topics: ImmutablePropTypes.list,
    folders: ImmutablePropTypes.list,
    foldersIndex: ImmutablePropTypes.map,
    currentFolder: PropTypes.string,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    match: PropTypes.object.isRequired,
    baseRoute: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool,
    }),
    brandSpecificContents: PropTypes.array,
    pubs: PropTypes.array,
    articles: PropTypes.articles,
  }

  constructor(props) {
    super(props)

    const { BROWSE_ROOT_KEY } = getConfig()
    const { foldersIndex, topics, baseRoute, match, history, publications } = props
    const rootFolderResourceKey = getRootFolderResourceKey(
      publications,
      DEFAULT_PUBLICATION_TYPE,
      foldersIndex,
      BROWSE_ROOT_KEY
    )
    let folderId = match.params.folderId || rootFolderResourceKey

    let activeFolder = foldersIndex && foldersIndex.size ? foldersIndex.get(folderId) : null
    if (!isFolder(activeFolder.toJS())) {
      let possibleTopic = topics.find(item => item.get('resourceKey') === match.params.folderId)
      // redirect to topic if trying to open folder with id of topic
      if (isCollapsedFolder(activeFolder.toJS()) || (possibleTopic && isDitaTopic(possibleTopic.toJS()))) {
        const url = `${baseRoute}${ROUTE.BROWSE}${ROUTE.TOPIC}/${match.params.folderId}`
        history.replace(url)
        activeFolder = null
      }
    }
    const isActiveFolderRoot = isRootFolder(activeFolder, rootFolderResourceKey)
    this.state = {
      activeFolder,
      rootFolderResourceKey,
      isActiveFolderRoot,
      parentFolder: activeFolder && !isActiveFolderRoot ? getParentFolder(activeFolder, foldersIndex) : null,
    }
  }

  componentDidUpdate(prevProps) {
    const { rootFolderResourceKey } = this.state

    if (prevProps.match.params.folderId !== this.props.match.params.folderId) {
      const { foldersIndex, match } = this.props

      const folderId = match.params.folderId || rootFolderResourceKey
      const activeFolder = foldersIndex && foldersIndex.size ? foldersIndex.get(folderId) : null
      const isActiveFolderRoot = isRootFolder(activeFolder, rootFolderResourceKey)
      this.setState({
        activeFolder,
        isActiveFolderRoot,
        parentFolder: activeFolder && !isActiveFolderRoot ? getParentFolder(activeFolder, foldersIndex) : null,
      })

      recalculateTopicsHeightForIOS()
    }
  }

  /**
   * Triggers browse analytics event when item is clicked
   *
   * @param {Object} item
   */
  trackItem(item) {
    switch (item.type) {
      case CONTENT_TYPE.FOLDER:
        isCollapsedFolder(item) ? trackBrowseTopicClick(item.title) : trackBrowseFolderClick(item.title)
        trackHomeFolderClick(item.title)
        break
      case CONTENT_TYPE.TOPIC:
        trackBrowseTopicClick(item.title)
        break
      default:
      // Do nothing in case of unsupported content type
    }
  }

  renderMenu = () => {
    const { contents, baseRoute, brandSpecificContents, pubs, articles } = this.props
    const { rootFolderResourceKey } = this.state

    if (!contents.publications) return []

    const rootArticles = getRootLevelArticles(articles)
    const articlesUniqTags = getUniqueTags(articles)
    const rootArticlesUniqTags = getUniqueTags(rootArticles)
    const rootMenuItems = rootArticlesUniqTags.map(tagToRenderItem)

    const items = [] // Menu items will appear here based on content availability

    // Show owners manual if there is at least one DITA XML publication available
    if (containsDita(pubs)) {
      items.push({
        title: OWNERS_MANUAL_TITLE,
        icon: <Icon type='browse' />,
        link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.FOLDER}/${rootFolderResourceKey}`,
        type: CONTENT_TYPE.FOLDER,
      })
    } else {
      // Show owners manual if there is at least one article available
      if (containsArticles(pubs) && articlesUniqTags.includes(OWNERS_MANUAL_TITLE)) {
        items.push({
          title: OWNERS_MANUAL_TITLE,
          icon: <Icon type='browse' />,
          link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.TAGS}/${encodeURIComponent(OWNERS_MANUAL_TITLE)}`,
          type: CONTENT_TYPE.FOLDER,
        })
      }
    }

    if (brandSpecificContents && brandSpecificContents.length) {
      items.push({
        title: 'Brand Specific Information',
        icon: <Icon type='browse' />,
        link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.BRAND_SPECIFIC}`,
        type: CONTENT_TYPE.FOLDER,
      })
    }

    // Show components manuals when there is at least one PDF publication available
    if (containsPdfs(pubs)) {
      items.push({
        title: 'Component Manuals',
        icon: <Icon type='component-manuals' />,
        link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.PDFS}`,
        type: CONTENT_TYPE.FOLDER,
      })
    }

    // Show videos if there is at least one video available
    if (containsVideos(contents)) {
      items.push({
        title: 'Videos',
        icon: <Icon type='video' />,
        link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.VIDEOS}`,
        type: CONTENT_TYPE.FOLDER,
      })
    }

    // Show the rest of tags that are not included into the Owner's Manual
    sortContentsByTitle(rootMenuItems).forEach(item => {
      if (item.id) {
        items.push({
          title: item.title,
          topicIcon: <Icon type='list-topic' />,
          link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.ARTICLES}${ROUTE.CONTENT}/:${item.resourceKey}`,
          type: CONTENT_TYPE.TOPIC,
        })
      } else {
        items.push({
          title: item.title,
          icon: <Icon type='browse' />,
          link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.ARTICLES}/${encodeURIComponent(item.title)}`,
          type: CONTENT_TYPE.FOLDER,
        })
      }
    })

    return items
  }

  render() {
    const { BROWSE_ROOT_KEY } = getConfig()
    const { currentBrand, currentYear, currentModel, baseRoute, fetching, match, userMedia, ownersManualPdfs } =
      this.props
    const { rootFolderResourceKey, parentFolder, activeFolder } = this.state

    if (!activeFolder) {
      return (
        <Loader type='status' className='inverse'>
          Folder not found
        </Loader>
      )
    }

    const breadcrumbs = [
      {
        title: 'Browse',
        link: `${baseRoute}${ROUTE.BROWSE}`,
      },
    ]

    return (
      <div className='Topics'>
        <ScreenHead
          back={
            parentFolder
              ? `${baseRoute}${ROUTE.BROWSE}${ROUTE.FOLDER}/${
                  parentFolder.get('resourceKey') ? parentFolder.get('resourceKey') : BROWSE_ROOT_KEY
                }`
              : null
          }
          backTitle={
            parentFolder && !isRootFolder(parentFolder, rootFolderResourceKey)
              ? parentFolder.get('title')
              : UI.OWNERS_MANUAL
          }
          ownersManualPdfs={ownersManualPdfs}
        >
          <em>
            {currentYear} {currentBrand}
          </em>
          <span>{currentModel}</span>
        </ScreenHead>

        <Media type='mobile'>
          <div className='topics-subtitle'>{UI.BROWSE_TITLE}</div>
        </Media>

        <div className='screen-content'>
          <Media type='desktop'>
            <StickyBar className='sticky-topics-bar'>
              <div className='topics-bar'>
                <BreadCrumb items={breadcrumbs} />
                <OwnersManualPdfs model={currentModel} ownersManualPdfs={ownersManualPdfs} />
              </div>
            </StickyBar>
          </Media>
          <ScreenContainer currentUrl={match.url}>
            <div className='topics'>
              {fetching ? (
                <Loader className='inverse' />
              ) : (
                <ContentList
                  isDesktop={userMedia.isDesktop}
                  topics={this.renderMenu()}
                  baseRoute={baseRoute + ROUTE.BROWSE}
                  onClick={topic => this.trackItem(topic)}
                />
              )}
            </div>
          </ScreenContainer>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ contents, vehicle, user }) {
  const { topics, folders, foldersIndex, publications, fetching, currentAsset, fetchingAsset, currentFolder } = contents
  const { currentYear, currentModel, currentBrand } = vehicle
  const { media: userMedia } = user

  const processedContents = filteredContents(contents)
  const pubs = getPublicationsForVehicle(processedContents.publications)
  const articles = getArticlesWithTags(pubs)

  return {
    contents: processedContents,
    topics,
    folders,
    foldersIndex,
    publications,
    fetching,
    currentAsset,
    fetchingAsset,
    currentYear,
    currentModel,
    currentBrand,
    currentFolder,
    userMedia,
    brandSpecificContents: processedContents.brandSpecificContents,
    pubs,
    articles,
  }
}

export default connect(mapStateToProps)(Browse)
