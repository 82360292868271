import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { trackContentViewVideo, trackContentViewByType, trackContentView } from 'tw-oi-core/services/analytics'

import { CONTENT_TYPE, ROUTE } from '../config'
import { getStartingBreadCrumb } from '../extras/utils'
import ScreenHead from '../components/ScreenHead'
import TopicVideo from '../components/TopicVideo'
import BreadCrumb from '../components/BreadCrumb'
import StickyBar from '../components/StickyBar'
import Media from '../components/Media'
import { ScrollSink } from '../components/MotionScroll'
import { bindActionCreators } from 'redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'
import { BRAND_SPECIFIC_TAG } from './BrandSpecificInformation'

/*
  This component is used for displaying non-dita videos
*/
const VideoViewer = ({
  baseRoute,
  ownersManualPdfs,
  match,
  videos,
  UserActions,
  currentYear,
  currentModel,
  currentBrand,
  currentProductType,
  currentProductSubtype,
}) => {
  const [video, breadcrumbs] = useMemo(() => {
    const video = videos.find(video => video.resourceKey === match.params.videoId)
    const breadcrumbs = [
      { title: getStartingBreadCrumb(match.params.referrer), link: `${baseRoute}${match.params.referrer}` },
    ]

    if (match.params.referrer === ROUTE.BROWSE && !match.params.brandSpecific) {
      breadcrumbs.push({ title: 'Videos', link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.VIDEOS}` })
    }

    if (match.params.brandSpecific) {
      breadcrumbs.push({ title: BRAND_SPECIFIC_TAG, link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.BRAND_SPECIFIC}` })
    }

    if (match.params.pseudoFolderName) {
      breadcrumbs.push({
        title: match.params.pseudoFolderName,
        link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.VIDEOS}/${match.params.pseudoFolderName}`,
      })
    }
    breadcrumbs.push({ title: video.title, link: '' })

    return [video, breadcrumbs]
  }, [match])

  useEffect(() => {
    trackContentViewVideo(video.title)
    trackContentViewByType(video.title, CONTENT_TYPE.VIDEO)
    trackContentView(video.title)
    UserActions.addRecent(
      currentYear,
      currentModel,
      video.resourceKey,
      currentBrand,
      currentProductType,
      currentProductSubtype
    )
  }, [video.resourceKey, currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype])

  return (
    <div className='VideoViewer Topics one video'>
      <ScreenHead
        landscapeOverlay={true}
        ownersManualPdfs={ownersManualPdfs}
        back={
          match.params.pseudoFolderName
            ? `${baseRoute}${ROUTE.BROWSE}${ROUTE.VIDEOS}/${match.params.pseudoFolderName}`
            : `${baseRoute}${match.params.referrer}`
        }
        backTitle={match.params.pseudoFolderName || getStartingBreadCrumb(match.params.referrer)}
      >
        <em>
          {currentYear} {currentBrand}
        </em>
        <span>{currentModel}</span>
      </ScreenHead>

      <Media type='mobile'>
        <div className='topics-subtitle'>{video.title}</div>
      </Media>

      <div className='screen-content'>
        <Media type='desktop'>
          <StickyBar className='sticky-topics-bar'>
            <div className='topics-bar'>
              <BreadCrumb items={breadcrumbs} />
            </div>
          </StickyBar>

          <div className='screen-container'>
            <div className='content-container'>
              <div className='content-container-wrapper'>
                <h1 className='topic-title'>{video.title}</h1>

                {match.params.videoId ? (
                  <TopicVideo video={video} baseRoute={baseRoute} match={match} history={history} />
                ) : null}
              </div>
            </div>
          </div>
        </Media>

        <Media type='mobile'>
          <ScrollSink scrollTop={0} resetScroll={true}>
            <div className='screen-container'>
              {match.params.videoId ? (
                <TopicVideo video={video} baseRoute={baseRoute} match={match} history={history} />
              ) : null}
            </div>
          </ScrollSink>
        </Media>
      </div>
    </div>
  )
}

VideoViewer.propTypes = {
  baseRoute: PropTypes.string,
  ownersManualPdfs: PropTypes.array,
  currentYear: PropTypes.string,
  currentModel: PropTypes.string,
  currentBrand: PropTypes.string,
  currentProductType: PropTypes.string,
  currentProductSubtype: PropTypes.string,
  match: PropTypes.object,
  videos: PropTypes.array,
  UserActions: PropTypes.shape({
    addRecent: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = ({ vehicle, contents }) => {
  const { currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype } = vehicle
  const { videos } = contents

  return { currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype, videos }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoViewer)
