import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import onClickOutside from 'react-onclickoutside'
import { PRODUCT_SUBTYPE_MAP, UI } from '../config'

import Icon from './Icon'

import '../styles/VehicleFilter.scss'

class VehicleFilter extends Component {

  static propTypes = {
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onSwitch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    items: []
  }

  state = {
    opened: false,
    filter: null
  }

  setFilter = filter => {
    if (typeof this.props.onChange === 'function') this.props.onChange(filter)

    this.setState({filter, opened: false})
  }

  handleTriggerClick = () => {
    this.setState({opened: !this.state.opened})
  }

  // Method will be called by onClickOutside HOC
  handleClickOutside = () => {
    this.setState({opened: false})
  }

  componentDidUpdate(prevProps, prevState) {
    const { opened } = this.state
    // trigger onSwitch callback on every open / close of the filter
    if (typeof this.props.onSwitch === 'function' && opened !== prevState.opened) {
      this.props.onSwitch(opened)
    }
  }

  render() {
    const { filter } = this.state
    const { items } = this.props

    if (!items.length || items.length < 2) return null

    return (
      <div className={classNames("VehicleFilter", this.state.opened && "opened")}>
        <div className="filter-trigger" onClick={this.handleTriggerClick}>{filter ? PRODUCT_SUBTYPE_MAP[filter] : UI.VEHICLE_FILTER_ALL}</div>
        <ul className="filter-inner">
          <li key={0}><div  className={classNames("filter-link", !filter && "current")} onClick={() => this.setFilter(null)}><Icon type="check"/>{UI.VEHICLE_FILTER_ALL}</div></li>
          {this.props.items.map((item, i) => {
            return <li key={i + 1}><div className={classNames("filter-link", filter === item && "current")} onClick={() => this.setFilter(item)}><Icon type="check"/>{PRODUCT_SUBTYPE_MAP[item]}</div></li>
          })}
        </ul>
      </div>
    )
  }
}


export default onClickOutside(VehicleFilter)
