// TODO: enable lint after enabling Promo
/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import Responsive from 'react-responsive-decorator'
import createBrowserHistory from 'history/createBrowserHistory'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getConfig } from 'tw-oi-core'
import * as UserActions from 'tw-oi-core/actions/UserActions'
import * as VehicleActions from 'tw-oi-core/actions/VehicleActions'
import { initFirebaseAnalytics, setApplicationType, trackScreenView } from 'tw-oi-core/services/analytics'
import { isAndroid, isIos } from 'tw-oi-core/utils/userAgent'

import { ROUTE, MESSAGE, GOOGLE_ANALYTICS_APP_TYPE, GOOGLE_ANALYTICS_FIREBASE_CONFIG } from '../config'
import { breakpoints } from '../components/Media'
import ErrorMessage from '../components/ErrorMessage'

import Vehicles from './Vehicles'
import Guide from './Guide'
import EditorTools from './EditorTools'
import Promo from './Promo'

class Main extends Component {
  static propTypes = {
    currentModel: PropTypes.string,
    currentYear: PropTypes.string,
    currentGroup: PropTypes.string,
    media: PropTypes.func.isRequired,
    promoSkipped: PropTypes.bool,
    UserActions: PropTypes.shape({
      setMedia: PropTypes.func.isRequired
    }),
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool,
      isLandscape: PropTypes.bool
    }),
    acceptedConditions: PropTypes.bool,
    match: PropTypes.shape({
      path: PropTypes.string,
      url: PropTypes.string,
    }).isRequired,
  }

  constructor(props) {
    super(props)
    this.handleUrlHashChange = this.handleUrlHashChange.bind(this)
  }

  handleUrlHashChange() {
    const { userMedia } = this.props
    if (userMedia.isDesktop && window.pageYOffset > 0 ) {
      // scroll to top when url updated
      window.scrollTo(window.pageXOffset, 0)
    }
  }

  componentDidMount() {
    this.props.media(breakpoints.desktop, () => {
      // disabling overscroll blocker for Desktop version shown on iOS Tablet
      iNoBounce.disable()
      this.props.UserActions.setMedia({isDesktop: true, isLandscape: false})
    })
    this.props.media(breakpoints.mobile, () => {
      this.props.UserActions.setMedia({isDesktop: false, isLandscape: false})
    })
    this.props.media(breakpoints.mobileLandscape, () => {
      this.props.UserActions.setMedia({isDesktop: false, isLandscape: true})
    })
    this.props.media(breakpoints.mobilePortrait, () => {
      this.props.UserActions.setMedia({isDesktop: false, isLandscape: false})
    })
    window.addEventListener('hashchange', this.handleUrlHashChange)

    // analytics
    initFirebaseAnalytics(GOOGLE_ANALYTICS_FIREBASE_CONFIG)
    setApplicationType(GOOGLE_ANALYTICS_APP_TYPE)

    // Subscribe to navigation events
    const history = createBrowserHistory()
    history.listen(trackScreenView)
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleUrlHashChange)
  }

  renderWithProps = (Component, additionalProps) => (props) => {
    return <Component {...props} {...additionalProps}/>
  }

  render() {
    const { match: { path: basePath, url: baseRoute }, promoSkipped, userMedia } = this.props
    const { FEATURE_ENABLED_PROMO } = getConfig()

    // should wait for user media isDesktop/isLandscape set
    if (!userMedia) return null

    return <Switch>
      <Redirect exact from={`${basePath}`} to={`${baseRoute.replace(/\/$/, '')}${ROUTE.VEHICLES}`} />

      {/* PROMOTE mobile wrapper app on mobile browsers */}
      <Route path={`${basePath}${ROUTE.PROMO}`} component={Promo} />
      {FEATURE_ENABLED_PROMO && (isAndroid(navigator.userAgent) || isIos(navigator.userAgent)) && !promoSkipped && <Route render={props => {
        return (
          <Redirect from={`${ROUTE.INDEX}`} to={{
            pathname: `${baseRoute}${ROUTE.PROMO}`,
            state: { referrer: props.location.pathname }
          }}/>
        )
      }}/>}

      <Route path={`${basePath}${ROUTE.EDITOR}`} component={EditorTools} />
      <Route path={`${basePath}${ROUTE.VEHICLES}/:year?/:group?`} render={this.renderWithProps( Vehicles, {baseRoute})} />
      <Route path={`${basePath}${ROUTE.GUIDE}/:year/:model`} component={Guide} />
      <Route render={() => <ErrorMessage className="inverse" title={MESSAGE.ERROR_NOT_FOUND} retryAction={baseRoute} retryTitle="Visit Home Page" message={MESSAGE.ERROR_NOT_FOUND_MESSAGE}/>}/>
    </Switch>
  }
}

function mapStateToProps({vehicle, user}) {
  const { currentYear, currentModel, currentGroup, currentBrand } = vehicle
  const { promoSkipped, media:userMedia, acceptedConditions } = user
  return { currentYear, currentModel, currentGroup, currentBrand, promoSkipped, userMedia, acceptedConditions }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
    VehicleActions: bindActionCreators(VehicleActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Responsive(Main))
