import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { UI } from '../config'
import RelatedContentList from './RelatedContentList'
import '../styles/RelatedContents.scss'

export default class RelatedContentsSidePanelView extends Component {
  static propTypes = {
    relatedContents :  PropTypes.shape({
      pdfs: PropTypes.array.isRequired,
      videos: PropTypes.array.isRequired,
    }).isRequired,
    baseRoute: PropTypes.string.isRequired,
    currentVideo: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.calculateComponentHeight = this.calculateComponentHeight.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  componentDidMount() {

    this.resetInitialWidth()
    this.calculateRelatedContentsMaxHeight()
    this.calculateComponentHeight()

    window.addEventListener('scroll', this.calculateComponentHeight)
    window.addEventListener('resize', this.onResize)

    //recalculate component height after topic image load (IO-1089)
    const topicTextContainer =  document.querySelector('.topic-text-container')
    _.forEach((topicTextContainer && topicTextContainer.getElementsByTagName('img')) || [], (img) => {
      img.onload = () => { this.forceUpdate() }
    })

  }

  componentDidUpdate() {
    this.calculateRelatedContentsMaxHeight()
    this.calculateComponentHeight()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.calculateComponentHeight)
    window.removeEventListener('resize', this.onResize)
  }

  onResize() {
    this.resetInitialWidth()
    this.calculateComponentHeight()
    this.calculateRelatedContentsMaxHeight()
  }

  resetInitialWidth() {
    // set initial width for sticky panel as it's static width can be different on tablets
    this.relatedContentsPanel.style.width = this.relatedContentsPanel.parentNode.parentNode.clientWidth + 'px'
  }

  calculateComponentHeight() {
    // this viewport logic made for Android Chrome, where address bar doesn't affect document.clientHeight
    // @see https://stackoverflow.com/questions/50990006/get-android-chrome-browser-address-bar-height-in-js
    const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

    const stickyRelatedContentsBar = document.querySelector('.sticky-related-contents-bar')
    const relatedContentsPanelTop = Math.max(this.relatedContentsPanel.getBoundingClientRect().top, stickyRelatedContentsBar.getBoundingClientRect().top)

    const contentNode =  document.querySelector('.topic-text-container') ||  document.querySelector('.video-container') || stickyRelatedContentsBar
    const contentNodeBottom = contentNode.getBoundingClientRect().top + contentNode.getBoundingClientRect().height

    const relatedContentsPanelHeight = viewportHeight < contentNodeBottom ?
      viewportHeight - relatedContentsPanelTop : contentNodeBottom - relatedContentsPanelTop

    this.relatedContentsPanel.style.height = relatedContentsPanelHeight + 'px'

    //show vertical scrollbar only if parent container overflow is more than 16px (one text line)
    _.forEach(this.relatedContentsPanel.querySelectorAll('.related-contents-container'), (relatedContentsContainer) => {
      const relatedContentList = relatedContentsContainer.querySelector('.RelatedContentList')
      if (relatedContentList.getBoundingClientRect().height - relatedContentsContainer.getBoundingClientRect().height < 16) {
        relatedContentsContainer.classList.remove('show-vertical-scroll')
      } else {
        relatedContentsContainer.classList.add('show-vertical-scroll')
      }
    })
  }

  calculateRelatedContentsMaxHeight() {
    _.forEach(this.relatedContentsPanel.querySelectorAll('.related-contents-container'), (relatedContentsContainer) => {
      const relatedContentList = relatedContentsContainer.querySelector('.RelatedContentList')
      relatedContentsContainer.style.maxHeight = relatedContentList.getBoundingClientRect().height + 'px'
    })
  }

  render() {
    const { relatedContents, baseRoute, currentVideo } = this.props

    return <div className='RelatedContentsSidePanelView' ref={node => this.relatedContentsPanel = node}>
      {!_.isEmpty(relatedContents.pdfs) &&
          <React.Fragment>
            <div className='panel-title'>
              {`${UI.RELATED_CONTENT_PANEL_TITLE.PDFS} (${relatedContents.pdfs.length})`}
            </div>

            <hr />

            <div className="related-contents-container pdfs show-vertical-scroll">
              <RelatedContentList relatedContents={relatedContents.pdfs} baseRoute={baseRoute}/>
            </div>
          </React.Fragment>
      }

      {!_.isEmpty(relatedContents.videos) &&
        <React.Fragment>
          <div className='panel-title'>
            {currentVideo ? UI.RELATED_CONTENT_MORE_VIDEOS : `${UI.RELATED_CONTENT_PANEL_TITLE.VIDEOS} (${relatedContents.videos.length})`}
          </div>

          <hr />

          <div className="related-contents-container videos show-vertical-scroll">
            <RelatedContentList relatedContents={relatedContents.videos} baseRoute={baseRoute}/>
          </div>
        </React.Fragment>
      }
    </div>
  }
}
