import React, { useCallback, useRef, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './FeedbackInput.scss'

const FeedbackInput = ({ innerRef, placeholder, optional, ...rest }) => {
  const inputRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)

  const containerClickCallback = useCallback(() => {
    inputRef.current?.focus()
    setIsFocused(true)
  }, [])

  return (
    <div className={classNames('FeedbackInput', (isFocused || rest.value?.length > 0) && 'FeedbackInput--focused')} onClick={containerClickCallback}>
      <span className='FeedbackInput__placeholder'>
        {optional ? `${placeholder} (optional)` : placeholder}
      </span>
      <input
        ref={el => {
          innerRef.current = el
          inputRef.current = el
        }}
        {...rest}
        onFocus={() => setIsFocused(true)}
        onBlur={(ev) => {
          setIsFocused(false)
          rest?.onBlur(ev)
        }}
      />
    </div>
  )
}

FeedbackInput.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.any
  }),
  placeholder: PropTypes.string,
  optional: PropTypes.bool
}

export default FeedbackInput