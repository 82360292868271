import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import Media from './Media'
momentDurationFormatSetup(moment)

export default class VideoMarkers extends React.Component {

  static propTypes = {
    items: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired
  }

  /**
   * Orders the markers by timestamp
   *
   * @param {Array} items
   * @returns {Array}
   */
  getOrderedMarkers(items) {
    return [...items].sort((a, b) => a.timestamp - b.timestamp)
  }

  render() {
    const { items, onClick } = this.props

    return <div className="video-markers-container">
      <div className="video-markers">
        {this.getOrderedMarkers(items).map((marker, index) => {
          return <button key={`marker-${index + 1}-${marker.timestamp}`} onClick={() => onClick(marker)}>
            <Media type="mobile">
              {marker.title} ({ moment.duration(marker.timestamp, 'seconds').format('mm:ss', { trim: false }) })
            </Media>
            <Media type="desktop">
                Chapter {index + 1} - {marker.title} ({ moment.duration(marker.timestamp, 'seconds').format('mm:ss', { trim: false }) })
            </Media>
          </button>
        })}
      </div>
    </div>
  }

}
