import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import {ROUTE} from '../config'
import Icon from '../components/Icon'
import '../styles/NavBar.scss'


export default class NavBar extends Component {
  static propTypes = {
    baseRoute: PropTypes.string.isRequired,
    topPicks: PropTypes.bool,
    favorites: PropTypes.bool,
  }

  static defaultProps = {
    topPicks: false,
    favorites: false
  }

  render() {
    const { topPicks, favorites } = this.props
    return <div className="NavBar">
      <div className="nav-inner">
        <NavLink className="nav-link" to={`${this.props.baseRoute}${ROUTE.BROWSE}`}><Icon type="book"/>Browse</NavLink>
        <NavLink className="nav-link" to={`${this.props.baseRoute}${ROUTE.SEARCH}`}><Icon type="search-no-dot"/>Search</NavLink>
        {topPicks && <NavLink className="nav-link" to={`${this.props.baseRoute}${ROUTE.TOPPICKS}`}><Icon type="flag"/>Top Picks</NavLink>}
        {favorites && <NavLink className="nav-link" to={`${this.props.baseRoute}${ROUTE.FAVORITES}`}><Icon type="bookmark"/>Favorites</NavLink>}
      </div>
    </div>
  }
}
