import React from 'react'

export function navArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19" style={{enableBackground: "new 0 0 18 19"}} xmlSpace="preserve">
      <g fill="none" stroke="none" strokeWidth="1">
        <g className="fill" transform="translate(-816.000000, -651.000000)">
          <g transform="translate(816.000000, 651.000000)">
            <path d="M17.2857143,9.57142857 C17.2857143,9.97321629 17.1480669,10.3117546 16.8727679,10.5870536 L9.60714286,17.8526786 C9.31696283,18.1279776 8.97842455,18.265625 8.59151786,18.265625 C8.21205167,18.265625 7.87723359,18.1279776 7.58705357,17.8526786 L6.75,17.015625 C6.46726049,16.7328855 6.32589286,16.3943472 6.32589286,16 C6.32589286,15.6056528 6.46726049,15.2671145 6.75,14.984375 L10.0200893,11.7142857 L2.16294643,11.7142857 C1.77603973,11.7142857 1.46168276,11.5747782 1.21986607,11.2957589 C0.978049386,11.0167397 0.857142857,10.6800615 0.857142857,10.2857143 L0.857142857,8.85714286 C0.857142857,8.46279565 0.978049386,8.12611747 1.21986607,7.84709821 C1.46168276,7.56807896 1.77603973,7.42857143 2.16294643,7.42857143 L10.0200893,7.42857143 L6.75,4.14732143 C6.46726049,3.87946295 6.32589286,3.54464487 6.32589286,3.14285714 C6.32589286,2.74106942 6.46726049,2.40625134 6.75,2.13839286 L7.58705357,1.30133929 C7.86979308,1.01859978 8.20461116,0.877232143 8.59151786,0.877232143 C8.98586507,0.877232143 9.32440335,1.01859978 9.60714286,1.30133929 L16.8727679,8.56696429 C17.1480669,8.82738225 17.2857143,9.16220033 17.2857143,9.57142857 Z" transform="translate(9.071429, 9.571429) scale(-1, 1) translate(-9.071429, -9.571429) "/>
          </g>
        </g>
      </g>
    </svg>
  )
}
