import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'

import Loader from '../components/Loader'
import { Link } from 'react-router-dom'
import Highlight from 'react-highlighter'

import * as UserActions from 'tw-oi-core/actions/UserActions'

import { ROUTE } from '../config'
import Icon from '../components/Icon'
import { isFolder, isDitaTopic, isVideo, isPdf, isTopic, isArticlePublication } from '../extras/utils'

import '../styles/ContentList.scss'

class ContentList extends Component {
  static propTypes = {
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.string,
        icon: PropTypes.object,
        type: PropTypes.string,
        resourceKey: PropTypes.string,
        link: PropTypes.string,
        bodyHtml: PropTypes.object,
      })
    ),
    searchQuery: PropTypes.string,
    emptyText: PropTypes.string,
    baseRoute: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    UserActions: PropTypes.shape({
      addRecent: PropTypes.func.isRequired,
    }).isRequired,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    currentProductType: PropTypes.string,
    currentProductSubtype: PropTypes.string,
  }

  static defaultProps = {
    searchQuery: '',
  }

  renderItemIcon = item => {
    if (item.icon) {
      return item.icon
    }
    if (isFolder(item)) {
      return <Icon type='folder' />
    } else if (isDitaTopic(item) || isTopic(item) || isArticlePublication(item)) {
      return <Icon type='list-topic' />
    } else if (isVideo(item)) {
      return <Icon type='list-film' />
    } else if (isPdf(item)) {
      return <Icon type='list-pdf' />
    }
  }

  handleClick = (e, item) => {
    const { onClick } = this.props
    onClick && onClick(item)
  }

  getLink = item => {
    return (
      item.link ||
      `${this.props.baseRoute}${isFolder(item) ? ROUTE.FOLDER : ROUTE.TOPIC}/${encodeURIComponent(item.resourceKey)}`
    )
  }

  render() {
    const { topics, searchQuery } = this.props

    if (topics === null || topics.length === 0) {
      return (
        <Loader type='status' className='inverse'>
          {this.props.emptyText || ''}
        </Loader>
      )
    }

    const topicElements = topics.map(topic => (
      <li key={topic.id || topic.title}>
        <Link className='item-inner' to={this.getLink(topic)} onClick={e => this.handleClick(e, topic)}>
          <div className='item-iconbar'>{this.renderItemIcon(topic)}</div>

          <div className='item-title'>
            {searchQuery ? (
              <Highlight className='highlighted' matchElement='mark' search={searchQuery}>
                {topic.title}
              </Highlight>
            ) : (
              topic.title
            )}
          </div>
        </Link>
      </li>
    ))

    return <ul className={classNames('ContentList', this.props.className)}>{topicElements}</ul>
  }
}

function mapStateToProps({ vehicle }) {
  const { currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype } = vehicle
  return { currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentList)
