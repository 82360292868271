import React from 'react'

export function travelTrailer() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373 125" style={{enableBackground: "new 0 0 373 125"}} xmlSpace="preserver">
      <g className="fill">
        <path d="M119 124c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20-8.954 20-20 20zm0-8c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm44 8c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20-8.954 20-20 20zm0-8c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12z"/>
        <path d="M360 93h-28.389L323 108H188v-5c0-12.703-10.297-23-23-23h-7.988c-7.831 1.759-13.192 6.005-16.081 12.738-3.102-6.733-8.31-10.979-15.624-12.738H118c-12.703 0-23 10.297-23 23v5H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h257.875A80.444 80.444 0 0 1 330 27c14.336 16.128 16.758 39.615 6.015 58.33L334.48 88H373v5h-8v31h-5V93zM239 17v85h37V17h-37zM40 17v85h37V17H40zm55 9v32h72V26H95z"/>
      </g>
    </svg>
  )
}
