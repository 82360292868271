/*eslint no-restricted-globals: 0*/
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'tw-oi-core'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'

import { filterTopicsByField, sortItemsByTitle } from 'tw-oi-core/utils/data'
import api from 'tw-oi-core/services/ContentDelivery'
import {
  trackSearchResultsTopicClick, trackSearchKeyword, trackEmptyResultsSearchKeyword,
  trackRecentlyViewedTopicClick
} from 'tw-oi-core/services/analytics'

import {
  mergeTopicsWithCollapsedFolders, getCollapsedSearchResults, isSupportedContent,
  isVideo, isArticlePublication, isPdf
} from '../extras/utils'

import {
  ROUTE,
  MESSAGE
} from '../config'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ContentsActions from 'tw-oi-core/actions/ContentsActions'
import ScreenHead from '../components/ScreenHead'
import ScreenContainer from '../components/ScreenContainer'
import ContentList from '../components/ContentList'
import Loader from '../components/Loader'
import SearchInput from '../components/SearchInput'
import ErrorMessage from '../components/ErrorMessage'
import BodyClass from '../components/BodyClass'
import Media from '../components/Media'

import '../styles/Topics.scss'


class Search extends Component {

  static propTypes = {
    fetching: PropTypes.bool,
    errorSearch: PropTypes.object,
    topics: ImmutablePropTypes.list,
    content: ImmutablePropTypes.list,
    recent: ImmutablePropTypes.list,
    ownersManualPdfs: ImmutablePropTypes.list,
    currentId: PropTypes.string,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    currentProductType: PropTypes.string,
    currentProductSubtype: PropTypes.string,
    ContentsActions: PropTypes.shape({
      setSearchQuery: PropTypes.func.isRequired,
      searchContentsByVehicleId: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.object.isRequired,
    baseRoute: PropTypes.string.isRequired,
    searchQuery: PropTypes.string,
    searchTopics: ImmutablePropTypes.list,
    searchResults: PropTypes.array,
    isDesktop: PropTypes.bool,
    isLandscape: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.state = {
      sortDescend: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { searchQuery } = this.props

    if (prevProps.searchQuery !== searchQuery && searchQuery) {
      this.getSearchContents(searchQuery)
    }
  }

  getSearchContents = (searchQuery) => {
    const { DEFAULT_LOCALE, REQUEST_ID_SEARCH } = getConfig()
    const {currentId, fetching, ContentsActions} = this.props

    if (this.searchRequest && fetching) {
      this.searchRequest = this.searchRequest.then(
        () => ContentsActions.searchContentsByVehicleId(currentId, DEFAULT_LOCALE, searchQuery).then(() => this.trackAnalyticsEvents())
      )

      api.cancelRequest(REQUEST_ID_SEARCH)
    } else {
      this.searchRequest = ContentsActions.searchContentsByVehicleId(currentId, DEFAULT_LOCALE, searchQuery).then(() => this.trackAnalyticsEvents())
    }
  }

  /**
   * Captures analytics search events
   */
  trackAnalyticsEvents() {
    const { searchQuery, searchTopics } = this.props

    if (!searchQuery) {
      return
    }

    // track empty search results and return early
    if (searchTopics && searchTopics.size === 0) {
      trackEmptyResultsSearchKeyword(searchQuery)
    }

    // track search keyword
    trackSearchKeyword(searchQuery)
  }

  addLinksToContent = item => {
    const { baseRoute } = this.props

    if (isArticlePublication(item)) {
      return {
        ...item,
        link: `${baseRoute}${ROUTE.SEARCH}${ROUTE.ARTICLES}${ROUTE.CONTENT}/${item.resourceKey}`
      }
    }

    if (isVideo(item)) {
      return {
        ...item,
        link: `${baseRoute}${ROUTE.SEARCH}${ROUTE.VIDEOS}${ROUTE.CONTENT}/${item.resourceKey}`
      }
    }

    if (isPdf(item)) {
      return {
        ...item,
        link: `${baseRoute}${ROUTE.SEARCH}${ROUTE.PDFS}${ROUTE.CONTENT}/${item.resourceKey}`
      }
    }

    return item
  }

  renderSearchResults = () => {
    const { content, recent, searchQuery, errorSearch, fetching, searchResults, baseRoute } = this.props

    if (!searchQuery) {
      const recentlyViewed = sortItemsByTitle(
        filterTopicsByField(content || List(), recent ? recent.toJS() : [], 'resourceKey', true)
      ).toJS()

      return (
        <Fragment>
          <div className="topics-subtitle">{recentlyViewed.length === 0 ? MESSAGE.NO_RECENTLY_VIEWED : MESSAGE.RECENTLY_VIEWED}</div>
          <ContentList
            topics={recentlyViewed.map(this.addLinksToContent)}
            baseRoute={baseRoute + ROUTE.SEARCH}
            onClick={topic => trackRecentlyViewedTopicClick(topic.title)}
          />
        </Fragment>
      )
    }

    if (errorSearch) {
      return <ErrorMessage className="inverse inline" retryAction={() => this.getSearchContents(this.props.searchQuery)}/>
    }

    if (searchQuery && fetching) {
      return (
        <Loader className="inverse"/>
      )
    }

    if (searchQuery && !fetching) {
      return <>
        <div className="topics-subtitle">{!searchResults ? MESSAGE.NO_RESULTS_FOUND : MESSAGE.SEARCH_RESULTS}</div>
        {searchResults && <ContentList
          topics={searchResults.map(this.addLinksToContent)}
          searchQuery={searchQuery}
          baseRoute={baseRoute + ROUTE.SEARCH}
          onClick={item => trackSearchResultsTopicClick(item.title, searchQuery)}
        />}
      </>
    }
  }

  render() {
    const { currentYear, currentBrand, currentModel, match, searchQuery, ContentsActions, isDesktop, isLandscape, ownersManualPdfs } = this.props

    return (
      <div className="Topics">
        <ScreenHead landscapeOverlay={false} ownersManualPdfs={ownersManualPdfs}><em>{currentYear} {currentBrand}</em><span>{currentModel}</span></ScreenHead>

        <Media type="mobile">
          <SearchInput searchQuery={searchQuery} onChange={ContentsActions.setSearchQuery} autoFocus={isDesktop}/>
        </Media>

        <div className="screen-content">
          <ScreenContainer currentUrl={match.url}>
            <div className="topics">
              <BodyClass className="collapsed-search" condition={isLandscape}/>
              <Media type="desktop">
                <SearchInput searchQuery={searchQuery} onChange={ContentsActions.setSearchQuery} autoFocus={isDesktop}/>
              </Media>
              {this.renderSearchResults()}
            </div>
          </ScreenContainer>
        </div>
      </div>
    )
  }
}


function mapStateToProps({
  contents, contents: { searchTopics }, vehicle, user: { recent, media:{ isLandscape, isDesktop }}
}) {
  const {
    topics, folders, fetching, currentAsset, fetchingAsset, searchQuery, errorSearch, searchFolders,
    searchContents, foldersIndex, publications, videos
  } = contents
  const { currentId, currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype } = vehicle

  recent = recent.getIn([currentBrand, currentYear, currentModel, currentProductType, currentProductSubtype].filter(path => path))
  const dita =  // handle collapsed content by merging folders as well
    (topics && topics.concat && folders) ? topics.concat(folders.filter(folder => !!folder.get('bodyHtml'))) : topics

  const pubs = (dita || []).concat(publications)
  const content = pubs.concat((videos || []).map(Map))

  if (searchTopics) {
    // include collapsed content to search results (show folders with html content as topics)
    searchTopics = getCollapsedSearchResults(searchTopics, searchFolders, folders, foldersIndex)
  }

  const searchResults = searchContents && searchContents.filter(isSupportedContent) || []

  return {
    topics: mergeTopicsWithCollapsedFolders(topics, folders),
    recent,
    content, // a list of all content used in recently viewed
    fetching,
    currentAsset,
    fetchingAsset,
    currentId,
    currentYear,
    currentModel,
    currentBrand,
    currentProductType,
    currentProductSubtype,
    searchQuery,
    searchTopics,
    errorSearch,
    isDesktop,
    isLandscape,
    searchResults
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ContentsActions: bindActionCreators(ContentsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
