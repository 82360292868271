import React, { Component } from 'react'
import PropTypes from 'prop-types'

import VideoPlayer from '../components/VideoPlayer'
import VideoMarkers from '../components/VideoMarkers'
import { trackVideoChapterClick } from 'tw-oi-core/services/analytics'
import { isYouTubeUrl } from '../extras/utils'
import { SOURCE_TYPES } from './VideoPlayer'
import ErrorMessage from "./ErrorMessage"
import { MESSAGE } from "../config"


class TopicVideo extends Component {

  static propTypes = {
    video: PropTypes.object.isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        topicId: PropTypes.string.isRequired,
        referrer: PropTypes.string.isRequired,
        time: PropTypes.string,
      }).isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    baseRoute: PropTypes.string.isRequired,
  }

  /**
   * Updates the topic URL and starts video playback from specified time
   * @param {object} marker
   */
  onVideoMarkerClick(marker) {
    const { history, baseRoute, video } = this.props

    if (marker) {
      const url = `${baseRoute}/related-contents/${video.resourceKey}/${marker.timestamp}`
      // play video
      history.replace(url)
      // track analytics event
      trackVideoChapterClick(`${marker.title} :: ${video.title}`)
    }
  }

  getVideoSource = () => {
    const { video } =  this.props
    const file  = video.file
    const externalLink = video.externalLink

    if (file) {
      return {
        thumbnail: video.thumbnailFile && video.thumbnailFile.url,
        src: file.url,
        type: SOURCE_TYPES.MP4
      }
    } else if (isYouTubeUrl(externalLink)) {
      return {
        src: externalLink,
        type: SOURCE_TYPES.YT
      }
    }
    return null
  }

  render() {
    const { video, match } =  this.props
    const { time } = match.params

    const videoSource = this.getVideoSource()

    if (!videoSource) {
      return <div className='topic-text-container'>
        <ErrorMessage className="inverse" title={MESSAGE.ERROR_VIDEO_NOT_AVAILABLE} message={MESSAGE.ERROR_TRY_AGAIN_LATER} />
      </div>
    }

    const markers = video.markers

    const videoProps = {
      poster: videoSource.thumbnail,
      source: {src: videoSource.src, type: videoSource.type},
      time: time ? parseInt(time, 10) : null,
      title: video.title,
      videoResourceKey: video.resourceKey
    }

    return (
      <div className="video-container">
        <div className="video-player-container">
          <VideoPlayer {...videoProps} />
        </div>
        {markers && <VideoMarkers items={markers} onClick={(marker) => this.onVideoMarkerClick(marker)}/>}
      </div>
    )
  }
}

export default TopicVideo
