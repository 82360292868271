import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { MESSAGE, ROUTE } from '../config'
import { getPublicationsForVehicle, getArticlesWithTags, getContentByTag, sortContentsByTitle } from '../extras/utils'

import { connect } from 'react-redux'
import ScreenHead from '../components/ScreenHead'
import ScreenContainer from '../components/ScreenContainer'
import ContentList from '../components/ContentList'
import Icon from '../components/Icon'
import Media from '../components/Media'
import StickyBar from '../components/StickyBar'
import BreadCrumb from '../components/BreadCrumb'

import '../styles/Topics.scss'

const ArticlesTags = ({ articles, currentYear, currentModel, currentBrand, match, baseRoute, history }) => {
  const [items, breadcrumbs] = useMemo(() => {
    const tag = decodeURIComponent(match.params.tag)
    const content = tag ? getContentByTag(tag, articles) : articles

    // Skip a special type of tag (Owner's Manual) and order tags A-Z
    const contentsList = sortContentsByTitle(content).map(item => ({
      ...item,
      link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.ARTICLES}/${tag}${ROUTE.CONTENT}/${encodeURIComponent(
        item.resourceKey
      )}`,
      icon: <Icon type='list-topic' />,
    }))

    const breadcrumbs = [
      { title: 'Browse', link: `${baseRoute}${ROUTE.BROWSE}` },
      { title: tag, link: '' },
    ]

    return [contentsList, breadcrumbs]
  }, [match])

  return (
    <div className='Topics'>
      <ScreenHead back={() => history.goBack()}>
        <em>
          {currentYear} {currentBrand}
        </em>
        <span>{currentModel}</span>
      </ScreenHead>

      <Media type='mobile'>
        <div className='topics-subtitle'>{decodeURIComponent(match.params.tag) || 'Articles'}</div>
      </Media>

      <div className='screen-content'>
        <Media type='desktop'>
          <StickyBar className='sticky-topics-bar'>
            <div className='topics-bar'>
              <BreadCrumb items={breadcrumbs} />
            </div>
          </StickyBar>
        </Media>

        <ScreenContainer currentUrl={match.url}>
          <div className='topics'>
            <ContentList topics={items} emptyText={MESSAGE.EMPTY_TOPICS} baseRoute={match.url} />
          </div>
        </ScreenContainer>
      </div>
    </div>
  )
}

ArticlesTags.propTypes = {
  tag: PropTypes.string,
  articles: PropTypes.array,
  currentYear: PropTypes.string,
  currentModel: PropTypes.string,
  currentBrand: PropTypes.string,
  match: PropTypes.object.isRequired,
  baseRoute: PropTypes.string,
  history: PropTypes.object,
}

function mapStateToProps({ contents: { publications }, vehicle }) {
  const pubs = getPublicationsForVehicle(publications.toJS())
  const articles = getArticlesWithTags(pubs)
  const { currentYear, currentModel, currentBrand } = vehicle
  return { articles, currentYear, currentModel, currentBrand }
}

export default connect(mapStateToProps)(withRouter(ArticlesTags))
