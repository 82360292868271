import React from 'react'

export function pontoon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373 125" style={{enableBackground: "new 0 0 373 125"}} xmlSpace="preserver">
      <g className="fill">
        <path d="M72 91v2h229v3H72v.113c-3.503 1.494-6.29 4.79-8.36 9.887H60v7h-8v12h-3v-25h3v10h5V63.076h28V85H72v3h16V63h27V20.121L103.665 8.787 94 0h116v10H109.121l53 53H301v28H72zm46-28h39.879L118 23.121V63zM79 97h236.586A7.416 7.416 0 0 1 319 111l-26.35 13.663a3 3 0 0 1-1.382.337H79c-7.732 0-14-6.268-14-14s6.268-14 14-14zm12-31v22h32V66H91zm35 0v22h32V66h-32zm35 0v22h32V66h-32zm35 0v22h32V66h-32zm35 0v22h32V66h-32zm35 0v22h32V66h-32z"/>
      </g>
    </svg>
  )
}
