/*eslint no-restricted-globals: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import {filterTopicsByTopPicks, sortItemsByTitle} from 'tw-oi-core/utils/data'
import { trackTopPicksTopicClick } from 'tw-oi-core/services/analytics'

import { MESSAGE } from '../config'

import { connect } from 'react-redux'
import ScreenHead from '../components/ScreenHead'
import ScreenContainer from '../components/ScreenContainer'
import ContentList from '../components/ContentList'

import '../styles/Topics.scss'

class TopPicks extends Component {

  static propTypes = {
    fetching: PropTypes.bool,
    topics: ImmutablePropTypes.list,
    favorites: PropTypes.array,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    match: PropTypes.object.isRequired
  }

  render() {
    const { topics, currentYear, currentBrand, currentModel, match } = this.props

    // TODO: move these to selector function
    const topTics = filterTopicsByTopPicks(topics)
    const sortedTopPics = sortItemsByTitle(topTics)

    return (
      <div className="Topics">
        <ScreenHead><em>{currentYear} {currentBrand}</em><span>{currentModel}</span></ScreenHead>
        <div className="screen-content">
          <ScreenContainer currentUrl={match.url}>
            <div className="topics">
              <div className="topics-subtitle">Recommended</div>
              <ContentList
                topics={sortedTopPics.toJS()}
                emptyText={MESSAGE.EMPTY_TOPICS}
                baseRoute={match.url}
                className="TopPicksList"
                onClick={topic => {
                  console.log('TODO', topic)
                  trackTopPicksTopicClick(topic.title)
                }}
              />
            </div>
          </ScreenContainer>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ contents, vehicle }) {
  const { topics, fetching, currentAsset, fetchingAsset } = contents
  const { currentYear, currentModel, currentBrand } = vehicle

  return { topics, fetching, currentAsset, fetchingAsset, currentYear, currentModel, currentBrand }
}

export default connect(mapStateToProps)(TopPicks)
