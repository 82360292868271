import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import Loader from "./Loader"
import { Link } from 'react-router-dom'
import VehicleListItem from './VehicleListItem'
import VehicleFilter from './VehicleFilter'
import Media from '../components/Media'
import { getPathAlias, getModelUrl } from 'tw-oi-core/utils/vehicle'
import { getVehicleSubTypes, filterVehiclesBySubType, isLegacyYear } from '../extras/utils'
import { MESSAGE, ROUTE, PRODUCT_MAP, PRODUCT_SUBTYPE_MAP, UI } from '../config'

import '../styles/VehicleList.scss'
import Icon from './Icon'
import StickyBar from '../components/StickyBar'

export default class VehicleList extends Component {

  state = {
    currentFilter: null,
    filterOpened: false
  }

  static propTypes = {
    vehicles: PropTypes.array,
    groups: PropTypes.array,
    currentYear: PropTypes.string.isRequired,
    productType: PropTypes.string,
    heroShotMap: PropTypes.object,
    setVehicleModel: PropTypes.func.isRequired,
    getHeroShot: PropTypes.func.isRequired,
    isDesktop: PropTypes.bool,
    baseRoute: PropTypes.string.isRequired
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.vehicles !== this.props.vehicles) {
      this.onChangeFilter(null)
    }
    // scroll to top when list updated
    if (this.scrollArea && (prevState.currentFilter !== this.state.currentFilter || prevProps.vehicles !== this.props.vehicles)) {
      this.scrollArea.scrollTop = 0

      // scroll to top when list updated and header is sticky
      if (this.props.isDesktop && this.scrollArea.getBoundingClientRect().top < UI.STICKY_VEHICLES_BAR_HEIGHT) {
        window.scrollTo(window.pageXOffset, UI.STICKY_VEHICLES_BAR_HEIGHT)
      }
    }
  }

  onChangeFilter = filter => {
    this.setState({currentFilter: filter})
  }

  onSwitchFilter = opened => {
    this.setState({filterOpened: opened})
  }

  renderList = (items, renderGroups = false) => {
    const { baseRoute, currentYear, setVehicleModel, heroShotMap, productType, getHeroShot, isDesktop } = this.props

    let list = []
    if (renderGroups) {
      items.forEach( item => {
        const product = PRODUCT_MAP[item] || {}
        if (product.title) {
          list.push(
            <VehicleListItem
              key={item}
              title={product.title}
              subtitle={this.props.isDesktop ? UI.ALL_TYPES : null}
              heroshot={{vehicleProductType: item}}
              link={`${baseRoute}${ROUTE.VEHICLES}/${currentYear}/${getPathAlias(item)}`}
              isDesktop={isDesktop}
            />
          )
        } else {
          // eslint-disable-next-line no-console
          console.error(`Couldn't find product by key ${item}`)
        }
      })
    } else {
      for (let i = 0; i < items.length; i++) {

        const { model } = items[i]

        list.push(
          <VehicleListItem
            key={items[i].id}
            heroshot={{vehicle: items[i], vehicleProductType:productType, heroShotMap, productType, getHeroShot, imageSize: isDesktop ? UI.HERO_SHOT_MEDIUM_SIZE : UI.HERO_SHOT_SMALL_SIZE}}
            title={ isLegacyYear(currentYear) && items[i].FOREST_RIVER_PRODUCT_SUB_TYPE ?
              PRODUCT_SUBTYPE_MAP[items[i].FOREST_RIVER_PRODUCT_SUB_TYPE] :
              model}
            subtitle={!isLegacyYear(currentYear) || !items[i].FOREST_RIVER_PRODUCT_SUB_TYPE ?
              PRODUCT_SUBTYPE_MAP[items[i].FOREST_RIVER_PRODUCT_SUB_TYPE] :
              null
            }
            link={`${baseRoute}${ROUTE.GUIDE}${getModelUrl(currentYear,`${items[i].id} ${model}`)}${ROUTE.BROWSE}`}
            onClick={() => setVehicleModel && setVehicleModel(items[i])}
          />
        )
      }
    }

    return list
  }

  /**
   * Renders back link for mobile for vehicles with more than 1 item or plain text if there is only one item
   * @param {String} title
   * @param {Boolean} isSingleItem
   * @returns {HTMLElement|React.Component}
   */
  renderBackLinkMobile = (title, isSingleItem) => {
    const { baseRoute } = this.props

    if (isSingleItem) {
      return <span className="back">
        {title}
      </span>
    }

    return <Link className="back" to={`${baseRoute}${ROUTE.VEHICLES}/${this.props.currentYear}/`}>
      <Icon type="arrow-left"/>
      {title}
    </Link>
  }

  render() {
    const { currentFilter, filterOpened } = this.state
    const { baseRoute, currentYear, productType, vehicles, groups } = this.props

    // in case vehicle group has only one model, we should skip 'ALL_MODELS' breadcrumb
    const isSingleItem = !isLegacyYear(currentYear) && groups && groups.length === 1

    if (productType && !vehicles.length) {
      return <Loader type="status">{MESSAGE.EMPTY_VEHICLES}</Loader>
    }

    return <div className="VehicleList">
      <Media type="desktop">
        <StickyBar className="sticky-vehicles-bar">
          <div className="vehicles-bar">
            <ul className="breadcrumb">
              {
                !isSingleItem &&
                <li>
                  {productType ? <Link to={`${baseRoute}${ROUTE.VEHICLES}/${currentYear}`}>{UI.ALL_MODELS}</Link> : <span>{UI.ALL_MODELS}</span>}
                  <Icon type="arrow-left"/>
                </li>
              }

              {productType &&
              <li>
                <span>{PRODUCT_MAP[productType].title}</span>
                <Icon type="arrow-left"/>
              </li>
              }
            </ul>
            { !isLegacyYear(currentYear) && <VehicleFilter
              items={getVehicleSubTypes(vehicles)}
              onChange={this.onChangeFilter}
              onSwitch={this.onSwitchFilter}
            />}
          </div>
        </StickyBar>
      </Media>

      {productType &&
      <Media type="mobile">
        <div className="vehicles-bar">
          {
            PRODUCT_MAP[productType].title &&
            this.renderBackLinkMobile(PRODUCT_MAP[productType].title, isSingleItem)
          }
          { !isLegacyYear(currentYear) && <VehicleFilter
            items={getVehicleSubTypes(vehicles)}
            onChange={this.onChangeFilter}
            onSwitch={this.onSwitchFilter}
          />}
        </div>
      </Media>
      }

      <div className={classNames("vehicles-content", filterOpened && "darkened")}>
        <div className="vehicles-container" ref={div => this.scrollArea = div}>
          <div className="vehicles-inner">
            {this.renderList( productType ? filterVehiclesBySubType(vehicles, currentFilter) : groups, !productType)}
          </div>
        </div>
      </div>
    </div>
  }
}
