import React from 'react'

export function componentManuals() {
  return (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>component_materials</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-104.000000, -31.000000)">
          <g transform="translate(104.000000, 31.000000)">
            <rect x="0" y="0" width="24" height="24"></rect>
            <path d="M10,13 C10.5522847,13 11,13.4477153 11,14 L11,14 L11,22 C11,22.5522847 10.5522847,23 10,23 L10,23 L2,23 C1.44771525,23 1,22.5522847 1,22 L1,22 L1,14 C1,13.4477153 1.44771525,13 2,13 L2,13 Z M22,13 C22.5522847,13 23,13.4477153 23,14 L23,14 L23,22 C23,22.5522847 22.5522847,23 22,23 L22,23 L14,23 C13.4477153,23 13,22.5522847 13,22 L13,22 L13,14 C13,13.4477153 13.4477153,13 14,13 L14,13 Z M9,15 L3,15 L3,21 L9,21 L9,15 Z M21,15 L15,15 L15,21 L21,21 L21,15 Z M10,1 C10.5522847,1 11,1.44771525 11,2 L11,2 L11,10 C11,10.5522847 10.5522847,11 10,11 L10,11 L2,11 C1.44771525,11 1,10.5522847 1,10 L1,10 L1,2 C1,1.44771525 1.44771525,1 2,1 L2,1 Z M18,1 C20.7614237,1 23,3.23857625 23,6 C23,8.76142375 20.7614237,11 18,11 C15.2385763,11 13,8.76142375 13,6 C13,3.23857625 15.2385763,1 18,1 Z M9,3 L3,3 L3,9 L9,9 L9,3 Z M18,3 C16.3431458,3 15,4.34314575 15,6 C15,7.65685425 16.3431458,9 18,9 C19.6568542,9 21,7.65685425 21,6 C21,4.34314575 19.6568542,3 18,3 Z" fill="#6E6E6E" fillRule="nonzero"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
