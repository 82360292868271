import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { MESSAGE, ROUTE, UI, CONTENT_TYPE } from '../config'
import { sortContentsByTitle } from '../extras/utils'
import { isPdf } from '../extras/utils'
import { trackComponentManualFolderClick } from 'tw-oi-core/services/analytics'

import { connect } from 'react-redux'
import ScreenHead from '../components/ScreenHead'
import ScreenContainer from '../components/ScreenContainer'
import ContentList from '../components/ContentList'
import BreadCrumb from '../components/BreadCrumb'
import StickyBar from '../components/StickyBar'
import Media from '../components/Media'
import { filteredContents } from './BrandSpecificInformation/BrandSpecificInformation'

import '../styles/Topics.scss'

const ComponentManuals = ({ baseRoute, publications, currentYear, currentModel, currentBrand, match }) => {
  // publications are manually placed in fake folders (tags) to implement some sort of navigation
  const [publicationsWithinTag, foldersAsTags, breadcrumbs] = useMemo(() => {
    const pdfs = publications.filter(isPdf)

    const breadcrumbs = [
      { title: 'Browse', link: `${baseRoute}${ROUTE.BROWSE}` },
      { title: 'Component Manuals', link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.PDFS}` },
    ]

    if (match.params.pseudoFolderName) {
      let pubsWithoutTags = pdfs
        .filter(pub => pub.tags && pub.tags.includes(match.params.pseudoFolderName))
        .map(pub => ({
          ...pub,
          link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.PDFS}/${match.params.pseudoFolderName}${ROUTE.CONTENT}/${pub.resourceKey}`,
        }))
      breadcrumbs.push({ title: match.params.pseudoFolderName, link: '' })

      return [sortContentsByTitle(pubsWithoutTags), [], breadcrumbs]
    }

    let pubsWithoutTags = [...pdfs]
      .filter(pub => !pub.tags || !pub.tags.length)
      .map(pub => ({
        ...pub,
        link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.PDFS}${ROUTE.CONTENT}/${pub.resourceKey}`,
        type: CONTENT_TYPE.TOPIC,
      }))
      .sort((a, b) => a.title.localeCompare(b.title))

    const folders = [...new Set(pdfs.reduce((tags, pub) => tags.concat(pub.tags || []), []))].sort().map(tagTitle => ({
      title: tagTitle,
      type: CONTENT_TYPE.FOLDER,
      resourceKey: encodeURIComponent(tagTitle),
      link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.PDFS}/${tagTitle}`,
    }))

    return [pubsWithoutTags, folders, breadcrumbs]
  }, [publications, match])

  return (
    <div className='Topics'>
      <ScreenHead
        back={
          match.params.pseudoFolderName ? `${baseRoute}${ROUTE.BROWSE}${ROUTE.PDFS}` : `${baseRoute}${ROUTE.BROWSE}`
        }
        backTitle={match.params.pseudoFolderName ? 'Component Manuals' : UI.BROWSE_TITLE}
      >
        <em>
          {currentYear} {currentBrand}
        </em>
        <span>{currentModel}</span>
      </ScreenHead>

      <Media type='mobile'>
        <div className='topics-subtitle'>{match.params.pseudoFolderName || 'Component Manuals'}</div>
      </Media>

      <div className='screen-content'>
        <Media type='desktop'>
          <StickyBar className='sticky-topics-bar'>
            <div className='topics-bar'>
              <BreadCrumb items={breadcrumbs} />
            </div>
          </StickyBar>
        </Media>

        <ScreenContainer currentUrl={match.url}>
          <div className='topics'>
            {foldersAsTags.length ? (
              <ContentList
                topics={foldersAsTags}
                emptyText={MESSAGE.EMPTY_TOPICS}
                baseRoute={match.url}
                className='FoldersAsTags'
                onClick={item => trackComponentManualFolderClick(item.title)}
              />
            ) : null}

            {publicationsWithinTag.length ? (
              <ContentList
                topics={publicationsWithinTag}
                emptyText={MESSAGE.EMPTY_TOPICS}
                baseRoute={match.url}
                className='ComponentManuals'
              />
            ) : null}
          </div>
        </ScreenContainer>
      </div>
    </div>
  )
}

ComponentManuals.propTypes = {
  publications: PropTypes.array,
  currentYear: PropTypes.string,
  currentModel: PropTypes.string,
  currentBrand: PropTypes.string,
  match: PropTypes.object,
  baseRoute: PropTypes.object,
}

function mapStateToProps({ contents, vehicle }) {
  const { fetching, currentAsset, fetchingAsset } = contents
  const { currentYear, currentModel, currentBrand } = vehicle

  const processedContents = filteredContents(contents)

  return {
    publications: processedContents.publications,
    fetching,
    currentAsset,
    fetchingAsset,
    currentYear,
    currentModel,
    currentBrand,
  }
}

export default connect(mapStateToProps)(ComponentManuals)
