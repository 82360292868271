import React, { Component } from 'react'
import { UI } from '../config'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import '../styles/RelatedContents.scss'

export const RelatedContentTabNames = {
  VIDEO: 'videos',
  PDF: 'pdfs',
  TOPIC: 'topic'
}

export default class RelatedContentTabs extends Component {

  static propTypes = {
    relatedContents :  PropTypes.shape({
      pdfs: PropTypes.array.isRequired,
      videos: PropTypes.array.isRequired,
    }).isRequired,
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func
  }

  renderTab = (tabName) => {
    const { relatedContents, setActiveTab, activeTab } = this.props

    if (relatedContents[tabName] && !relatedContents[tabName].length) {
      return null
    }

    return <div
      onClick={() => setActiveTab(tabName)}
      className={classNames('tab', activeTab === tabName && 'tab-activated')}
    >
      {
        tabName === RelatedContentTabNames.PDF ?
          UI.RELATED_CONTENT_PANEL_TITLE.PDFS :
          tabName === RelatedContentTabNames.VIDEO ?
            UI.RELATED_CONTENT_PANEL_TITLE.VIDEOS :
            UI.RELATED_CONTENT_PANEL_TITLE.TOPIC
      }

      {
        tabName !== RelatedContentTabNames.TOPIC &&
        <span className='related-content-count'>{relatedContents[tabName].length}</span>
      }
    </div>
  }

  render() {
    return <div className='RelatedContentsTabView'>
      <div className='tabs'>
        {this.renderTab(RelatedContentTabNames.TOPIC)}

        {this.renderTab(RelatedContentTabNames.PDF)}

        {this.renderTab(RelatedContentTabNames.VIDEO)}
      </div>
    </div>
  }
}
