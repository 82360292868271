import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'tw-oi-core'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Immutable from 'immutable'
import Icon from './Icon'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { ROUTE, UI } from '../config'

class AutoBreadCrumb extends Component {

  static propTypes = {
    foldersIndex: ImmutablePropTypes.map.isRequired,
    activeFolder: PropTypes.object.isRequired,
    rootFolderResourceKey: PropTypes.string.isRequired,
    baseRoute: PropTypes.string.isRequired,
    inactive: PropTypes.bool
  }

  static defaultProps = {
    inactive: false
  }

  renderFoldersPath = activeFolder => {
    if (!activeFolder) {
      return []
    }

    const { BROWSE_ROOT_KEY } = getConfig()
    const { foldersIndex, baseRoute, rootFolderResourceKey } = this.props
    const resourceKey = activeFolder.get('resourceKey')
    const isRootFolder =  resourceKey === rootFolderResourceKey
    let foldersPath
    const parentFolder = resourceKey ? foldersIndex.find(folder => {
      return folder.get("contents").keySeq().contains(resourceKey)
    }) : null

    if (!isRootFolder && parentFolder) {
      foldersPath = this.renderFoldersPath(parentFolder)
    } else {
      foldersPath = new Immutable.List()
    }

    return foldersPath.push(
      <li key={resourceKey || BROWSE_ROOT_KEY}>
        <Link to={`${baseRoute}${ROUTE.BROWSE}${ROUTE.FOLDER}/${resourceKey ? resourceKey : ""}`}>
          {isRootFolder ? UI.OWNERS_MANUAL : activeFolder.get('title') || UI.BROWSE_TITLE}
        </Link>
        <Icon type="arrow-left"/>
      </li>)
  }

  render() {
    const { activeFolder, inactive, baseRoute } = this.props
    return (
      <ul className={classNames("Auto-breadcrumb topics-breadcrumb", inactive && "inactive")}>
        {[
          <li key="Browse">
            <Link to={`${baseRoute}${ROUTE.BROWSE}`}>{UI.BROWSE_TITLE}</Link>
            <Icon type="arrow-left"/>
          </li>,
          ...this.renderFoldersPath(activeFolder)
        ]}
      </ul>
    )
  }
}

export default AutoBreadCrumb
