import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class BrandSelector extends Component {

  static propTypes = {
    currentBrand: PropTypes.string.isRequired,
    onBrandChange: PropTypes.func.isRequired,
    brands: PropTypes.array.isRequired
  }

  render() {
    const { currentBrand, onBrandChange, brands } = this.props

    return (
      <div className="editor-section">
        <label className="section-label">Brand</label>
        <div className="Select">
          <select value={currentBrand.key} onChange={e => onBrandChange(e.currentTarget.value)}>
            {brands.map(brand => {
              return (<option key={brand.key} value={brand.key}>{brand.name}</option>)
            })}
          </select>
        </div>
      </div>
    )
  }
}
