import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { ROUTE, BRANDS, CONTENT_DELIVERY, ENV_PROD } from '../config'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getConfig, setConfig, saveUserSettings, loadUserSettings } from 'tw-oi-core'
import { VEHICLES_RESET, FLUSH_CONTENTS } from 'tw-oi-core/actions'
import { getEnv } from '../extras/utils'

import Loader from "../components/Loader"
import api from 'tw-oi-core/services/ContentDelivery'
import Media from '../components/Media'

import * as VehicleActions from 'tw-oi-core/actions/VehicleActions'
import ScreenHead from '../components/ScreenHead'
import BrandSelector from "../components/BrandSelector"
import LoginForm from "../components/LoginForm"
import DraftSelector from "../components/DraftSelector"

import { Link } from 'react-router-dom'

const brandList = [...BRANDS].sort((a, b) => a.name > b.name ? 1 : -1)

class EditorTools extends Component {

  static propTypes = {
    currentBrand: PropTypes.string.isRequired,
    VehicleActions: PropTypes.shape({
      setVehicleBrand: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  }

  state = {
    loading: false,
    userSession: false,
    currentEnv: ENV_PROD,
    draftPreviewEnabled: false,
    clientId: null,
    clientSecret: null,
    loginFormError: null,
  }

  async componentDidMount() {
    const configEnv = getEnv()

    // Load CD credentials from config
    const env = CONTENT_DELIVERY[configEnv]

    this.setState({
      loading: true
    })
    const credentials = await loadUserSettings(true)

    // Do not show the default credentials in a text box if they are public
    // Only the editor's credentials will be pre-populated
    if (env.CLIENT_ID === credentials.CLIENT_ID) {
      return this.setState({
        currentEnv: configEnv,
        loading: false
      })
    }

    // Pre-populate the login form with Editor's credentials
    this.setState({
      currentEnv: configEnv,
      clientId: credentials.CLIENT_ID,
      clientSecret: credentials.CLIENT_SECRET,
      draftPreviewEnabled: getConfig().FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW,
      loading: false
    })
  }

  /**
   * Resets application state
   *
   * @return {Promise<void>}
   */
  resetApplicationData = async() => {

    api.clearSession() // Logout user, which will cause to forget old session and re-login into new service

    // Reset already loaded data
    this.props.dispatch({type: VEHICLES_RESET})
    this.props.dispatch({type: FLUSH_CONTENTS})

  }

  /**
   * Sets vehicle brand
   *
   * @param {String} brandKey to select
   * @returns {void}
   */
  onBrandChange = (brandKey) => {
    const { history } = this.props
    const brand = BRANDS.find(brand => brand.key === brandKey)

    this.props.VehicleActions.setVehicleBrand(brand.name)

    history.push(`/${brand.key}${ROUTE.EDITOR}`)
  }

  switchCDInstance = async(currentEnv) => {
    this.setState({loading: true})
    const env = CONTENT_DELIVERY[currentEnv]
    const configUpdate = {
      FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW: false,
      ...env
    }
    setConfig(configUpdate) // Update global configuration

    api.setGlobalBaseUrl(env.BASE_URL) // Update API base URL (credentials will be picked up from real-time conf)

    // If content delivery instances are switched, we should disable draft preview mode
    await this.resetApplicationData() // logout the user and reload content

    this.setState({loading: false, currentEnv, draftPreviewEnabled: false}) // Trigger view render to reflect changes in select box
  }

  onLogin = async(clientId, clientSecret, newEnv) => {
    this.setState({loading: true, loginFormError: null})

    try {
      const accessToken = await this.login(clientId, clientSecret)
      await this.fetchInternalContents(accessToken) // make sure there are permissions to fetch draft content
    } catch (e) {
      return this.setState({
        loading: false,
        loginFormError: e.message
      })
    }

    // Switch Prod/Stage environments (if changed)
    const configEnv = getEnv()
    if (newEnv !== configEnv) {
      await this.switchCDInstance(newEnv)
    }

    // Saves editor's credentials for further use
    const configUpdate = {
      CLIENT_ID: clientId,
      CLIENT_SECRET: clientSecret
    }
    setConfig(configUpdate)
    await saveUserSettings(configUpdate)

    this.setState({
      loading: false,
      userSession: true
    })
  }

  onDraftModeChange = async(mode) => {
    // DISABLE the draft preview mode first
    if (!mode) {
      this.setState({
        loading: true
      })
      const configUpdate = {
        FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW: false
      }
      // TODO: explicitly cleanup the CLIENT_ID & CLIENT_SECRET
      setConfig(configUpdate)
      await saveUserSettings(configUpdate)
      await this.resetApplicationData() // logout the user and reload content
      // re-open vehicle selector
      this.setState({loading: false, draftPreviewEnabled: mode})
      return null
    }

    const credentials = await loadUserSettings(true)

    const configUpdate = {
      FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW: true,
      CLIENT_ID: credentials.CLIENT_ID,
      CLIENT_SECRET: credentials.CLIENT_SECRET
    }
    setConfig(configUpdate)
    await saveUserSettings(configUpdate)
    await this.resetApplicationData() // logout the user and reload content

    // re-open vehicle selector
    this.setState({
      loading: false,
      draftPreviewEnabled: mode
    })
  }

  /**
   * Signs in with provided credentials
   *
   * @param {String} login
   * @param {String} pass
   * @return {Promise<String>}
   */
  login = async(login, pass) => {
    try {
      const response = await api.auth(login, pass)
      return response.data.accessToken
    } catch (e) {
      if (e.response.status === 401) {
        throw new Error('Login failed. Check your ID or Secret.')
      }
      throw e // cannot handle this exception, so rethrow
    }
  }

  /**
   * Check if the account actually has a permissions to access the internal content
   *
   * @param {String} accessToken
   * @return {Promise}
   */
  fetchInternalContents = async(accessToken) => {
    try {
      await api.getAuthenticated({
        url: api.endpoints.CONTENTS_INTERNAL, // just need to make sure the internal endpoint is accessible
        params: {
          make: 'Forest River', // any brand will fit here, as we just checking the API connectivity
          year: '2018', // we actually can have any year here, just filtering redundant data
          model: 'Foo', // we actually need a fake model, to avoid HTTP 422 Too many results
          locale: 'en_US'
        }
      }, accessToken)
    } catch (e) {
      if (e.response.status === 403) {
        throw new Error('Access denied. Not enough permissions to view draft content.')
      }
      throw e // cannot handle this exception, so rethrow
    }
  }

  render() {
    const { currentBrand: currentBrandName } = this.props

    const currentBrand = BRANDS.find(brand => brand.name === currentBrandName)
    const { userSession } = this.state
    return (
      <div className="EditorToolsScreen">
        <ScreenHead back={userSession ? () => {this.setState({userSession: false}) } : ROUTE.INDEX + currentBrand.key} landscapeOverlay={false}/>
        <div className="editor-content">
          {this.state.loading ? <Loader className="inverse"/> :
            this.state.userSession ?
              <Fragment>
                <BrandSelector currentBrand={currentBrand} onBrandChange={this.onBrandChange} brands={brandList}/>
                <DraftSelector onChangeMode={this.onDraftModeChange} draftPreviewEnabled={this.state.draftPreviewEnabled}/>
                <div className="editor-section editor-accept">
                  <Media type="desktop">
                    <button className="SecondaryButton" onClick={() => this.setState({userSession: false})}>Back</button>
                  </Media>
                  <Link to={ROUTE.INDEX + currentBrand.key} className="PrimaryButton">Accept</Link>
                </div>
              </Fragment> :
              <LoginForm onLogin={this.onLogin} clientId={this.state.clientId} clientSecret={this.state.clientSecret}  currentEnv={this.state.currentEnv} onEnvChange={this.onEnvChange} loginFormError={this.state.loginFormError}/>
          }

        </div>
      </div>
    )
  }

}

function mapStateToProps({vehicle: { currentBrand }}) {
  return { currentBrand }
}

function mapDispatchToProps(dispatch) {
  return {
    VehicleActions: bindActionCreators(VehicleActions, dispatch),
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorTools)
