import React, { useCallback } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { StarIcon } from '../Icons'

import './Rating.scss'

const STAR_LIST = [0, 1, 2, 3, 4]

const Rating = ({ value, onChange }) => {
  const onRateCallback = useCallback((orderNumber) => {
    return () => {
      onChange(orderNumber)
    }
  }, [onChange])

  return (
    <div className='Rating'>
      {STAR_LIST.map((orderNumber) => {
        return (
          <button
            key={orderNumber}
            className={classNames('Rating__star', Number.isFinite(value) && orderNumber <= value && 'Rating__star--selected')}
            onClick={onRateCallback(orderNumber)}
          >
            <StarIcon />
          </button>
        )
      })}
    </div>
  )
}

Rating.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
}

export default Rating
