import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import onClickOutside from 'react-onclickoutside'

import Icon from './Icon'
import FeedbackModal from './FeedbackModal'

class TopMenu extends Component {
  static propTypes = {
    links: PropTypes.array.isRequired,
    currentBrand: PropTypes.string,
    currentModel: PropTypes.string,
    currentYear: PropTypes.string,
  }
  static defaultProps = {
    links: []
  }

  constructor(props) {
    super(props)

    this.state = {
      opened: false,
      isFeedbackModalOpen: false
    }
  }

  handleTriggerClick = () => {
    this.setState({opened: !this.state.opened})
  }

  /**
   * Handle menu item click and close menu
   *
   * @param {function} [onMenuClick]
   */
  handleMenuItemClick = (onMenuClick) => {
    if (onMenuClick) {
      onMenuClick()
    }
    this.setState({opened: false})
  }

  // Method will be called by onClickOutside HOC
  handleClickOutside = () => {
    this.setState({ opened: false })
  }

  render() {
    const { links, currentBrand, currentModel, currentYear } = this.props
    const { isFeedbackModalOpen } = this.state

    if (!links.length) return <div className="screen-topmenu"/>

    return (
      <div className={classNames("screen-topmenu", this.state.opened && "opened")}>
        {isFeedbackModalOpen && (
          <FeedbackModal
            onClose={() => {
              this.setState({ isFeedbackModalOpen: false })
            }}
            currentBrand={currentBrand}
            currentModel={currentModel}
            currentYear={currentYear}
          />
        )}

        <button className="topmenu-trigger" onClick={this.handleTriggerClick}><Icon type="dots"/></button>
        <ul className="topmenu-inner">
          <li>
            <span
              className="topmenu-link"
              onClick={() => {
                this.setState({ isFeedbackModalOpen: true })
              }}
            >
              SEND FEEDBACK
            </span>
          </li>

          <li>
            <a
              className="topmenu-link"
              href="https://www.forestriverinc.com/Contact-Us"
              target='_blank'
              rel="noreferrer"
            >
              CONTACT US
            </a>
          </li>

          {this.props.links.map((link, i) => {
            if (link.url) {
              const {url, iconType, onMenuClick, title, ...attr} = link
              return <li key={i}>
                <a className="topmenu-link" href={url} onClick={() => this.handleMenuItemClick(onMenuClick)} {...attr}>
                  {title}
                  {iconType && <Icon type={iconType}/>}
                </a>
              </li>
            }
            return <li key={i}><button className="topmenu-link" onClick={link.onClick ? link.onClick : null}>{link.title}</button></li>
          })}
        </ul>
      </div>
    )
  }
}


export default onClickOutside(TopMenu)
