import React from 'react'

export function fifthWheel() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373 125" style={{enableBackground: "new 0 0 373 125"}} xmlSpace="preserver">
      <g className="fill">
        <path d="M299 108.283H178.923c-.868-9.688-9.008-17.281-18.922-17.281-9.914 0-18.054 7.593-18.923 17.28h-.156c-.869-9.687-9.01-17.28-18.923-17.28-9.914 0-18.054 7.593-18.922 17.28H12c-6.627 0-12-5.372-12-12v-78.11C0 11.659 5.195 6.335 11.706 6.175L263.802.002C316.215-.1 347.282 4.014 357 12.344c6.192 4.409 10.853 11.443 13.982 21.103 4.569 14.102-1.07 29.48-13.62 37.299l.638.256c3.333 1.333 8.17 2.686 14.507 4.057v7h-32a7 7 0 0 1-7-7v-3.5c0-.199.009-.396.025-.591h-12.62a9 9 0 0 0-9 9v28.315H304v16.719h-5v-16.72zm-99-84.281v69h33v-69h-33zm0 75v3h33v-3h-33zm-146-75v69h33v-69H54zm-39 4v44h27v-44H15zm100 0v44h52v-44h-52zm198 0v28h21v-28h-21zm-259 71v3h33v-3H54zm68 26c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-8a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm38 8c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-8a7 7 0 1 0 0-14 7 7 0 0 0 0 14z"/>
      </g>
    </svg>
  )
}
