import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../components/Icon'
import { UI, PRODUCT_MAP } from '../config'
import { getConfig } from 'tw-oi-core'
import { getBrandYearModelTypeSubtypeKey } from 'tw-oi-core/utils/data'


export default class HeroShot extends React.Component {
  state = {
    vehicleHeroShot : null,
    showProductTypeHeroShot: true,
    highQuality: false
  }

  static propTypes = {
    vehicleProductType: PropTypes.string.isRequired,
    vehicle: PropTypes.object,
    heroShotMap: PropTypes.object,
    getHeroShot: PropTypes.func,
    imageSize: PropTypes.string
  }

  setHeroShortSource(heroShotKey) {
    const { heroShotMap, imageSize } = this.props

    const heroShot = heroShotMap[heroShotKey]

    if (heroShot) {
      this.setState({vehicleHeroShot: heroShot, highQuality: imageSize === UI.HERO_SHOT_MEDIUM_SIZE})
    }
  }

  loadHeroShot(update = false) {
    const { vehicle, heroShotMap, getHeroShot, imageSize } = this.props
    const { DEFAULT_LOCALE } = getConfig()

    if (vehicle) {
      const { make, year, model, FOREST_RIVER_PRODUCT_TYPE, FOREST_RIVER_PRODUCT_SUB_TYPE } = vehicle
      const heroShotKey = getBrandYearModelTypeSubtypeKey(make, year, model, FOREST_RIVER_PRODUCT_TYPE, FOREST_RIVER_PRODUCT_SUB_TYPE)
      const heroShot = heroShotMap[heroShotKey]

      if (heroShot && !update) {
        this.setState({vehicleHeroShot: heroShot})
      } else {
        getHeroShot(make, year, model, DEFAULT_LOCALE, imageSize, FOREST_RIVER_PRODUCT_TYPE, FOREST_RIVER_PRODUCT_SUB_TYPE)
          .then(() => this.setHeroShortSource(heroShotKey))
      }
    }
  }

  componentDidMount() {
    this.loadHeroShot()
  }

  componentDidUpdate(prevProps) {
    const { imageSize } = this.props
    const { highQuality } = this.state

    if (imageSize !== prevProps.imageSize && (!highQuality && imageSize !== UI.HERO_SHOT_SMALL_SIZE)) {
      this.loadHeroShot(true)
    }
  }

  onHeroShotLoad() {
    this.setState({showProductTypeHeroShot: false})
  }

  render() {
    const {vehicleProductType} = this.props
    const {vehicleHeroShot, showProductTypeHeroShot} = this.state

    const productTypeIcon = PRODUCT_MAP[vehicleProductType] && PRODUCT_MAP[vehicleProductType].heroShotIcon

    return <div className="heroshot">
      <img
        src={vehicleHeroShot || ''} alt=""
        onLoad={() => this.onHeroShotLoad()}
        className={showProductTypeHeroShot ? 'vehicle-hero-shot-hide' : 'vehicle-hero-shot'}
      />
      {showProductTypeHeroShot && <Icon type={productTypeIcon} className='product-type-icon'/>}
    </div>
  }
}
