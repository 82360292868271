import React from 'react'
import videojs from 'video.js'
import 'videojs-youtube'
import { checkGlobals } from 'tw-oi-core/utils/ui'
import PropTypes from 'prop-types'
import { trackVideoView } from 'tw-oi-core/services/analytics'

import '../styles/VideoPlayer.scss'

export const SOURCE_TYPES = {
  MP4: 'video/mp4',
  YT:  'video/youtube'
}

export default class VideoPlayer extends React.Component {

  state = {
    isPlayerReady: false,
    videoResourceKey: null,
    isVideoTracked: false
  }

  static propTypes = {
    videoResourceKey: PropTypes.string.isRequired,
    poster: PropTypes.string,
    source: PropTypes.shape({
      src: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    time: PropTypes.number,
    title: PropTypes.string,
  }

  /**
   * Instantiate Video.js
   * as per http://docs.videojs.com/tutorial-react.html
   *
   * @returns {void}
   */
  componentDidMount() {
    const dataSetup = {techOrder: ["html5", "youtube"],
      playsinline: true,
      preload: 'metadata',
      youtube: {modestbranding: 1, playsinline: 1, ytControls: 1}
    }

    this.player = videojs(this.videoNode, dataSetup, function onPlayerReady() {
      this.setState({isPlayerReady: true})
    }.bind(this))

    this.player.on('play', this.trackVideoPlay.bind(this))

    if (checkGlobals(['cordova','screen'])) {
      this.player.on('fullscreenchange', () => {
        if (this.player.isFullscreen()) {
          window.screen.orientation.lock('landscape')
        } else {
          window.screen.orientation.lock('portrait')
        }
      })
    }

  }

  /**
   * Detects whether there is a time offset available, and start playback if it is
   *
   */
  componentDidUpdate() {
    const { time, videoResourceKey, source, poster } = this.props
    const { isPlayerReady } = this.state

    if (isPlayerReady && videoResourceKey !== this.state.videoResourceKey) {
      this.player.poster(source.type === SOURCE_TYPES.YT ? null : poster)
      this.player.controls(source.type === SOURCE_TYPES.MP4)
      this.player.src(source)

      this.setState({videoResourceKey, isVideoTracked: false})
      return
    }

    if (time !== null && isPlayerReady) {
      this.player.currentTime(time)
      this.player.play()
    }
  }

  /**
   * Destroy player on unmount
   *
   * @returns {void}
   */
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }


  /**
   * Send analytics on video play
   */
  trackVideoPlay = () => {
    if (!this.state.isVideoTracked) {
      trackVideoView(this.props.title)
      this.setState({isVideoTracked: true})
    }
  }

  /**
   * Wrap the player in a div with a `data-vjs-player` attribute
   * so videojs won't create additional wrapper in the DOM
   * see https://github.com/videojs/video.js/pull/3856
   *
   * @returns {React.Component} <video> + wrapper
   */
  render() {
    return <div data-vjs-player>
      <video ref={ node => this.videoNode = node } className="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"/>
    </div>
  }
}
