import React from 'react'

export function exploreRear() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 48" style={{enableBackground:"new 0 0 78 48"}} xmlSpace="preserve">
      <g fill="none" fillRule="evenodd">
        <path className="fill" d="M23 12h16v21H27.953l-1.306-3.82L23 26.844z"/>
        <path className="stroke" strokeWidth="2" d="M26.915 33.858c.176.002.3 0 .364-.008l.137-.006c6.878.177 14.073.257 22.543.27.499.001.899 0 1.8-.004l.54-.003c.742-.003 1.294-.003 1.818 0a5.19 5.19 0 0 1-.125-.913c-.027-1.145.293-2.224.947-3.131a5.086 5.086 0 0 1 1.964-1.607 5.184 5.184 0 0 1 2.476-.444c2.54.213 4.584 2.379 4.61 4.997-.003.331-.039.657-.104.974.406-.02.735-.042.985-.068 1.953-.12 3.23-.386 4.26-.988.166-.097.257-.164.285-.199.024-.03.065-.14.11-.358.013-.06.023-.113.047-.241.019-.097.027-.141.04-.2a19.57 19.57 0 0 0 .37-3.153 17.05 17.05 0 0 0 .007-.799 19.537 19.537 0 0 0-.132-2.043l-.038-.302-.007-.055-.002-.014a29.536 29.536 0 0 0-.093-.67 6.851 6.851 0 0 0-.227-1.043c-.098-.308-.435-.588-1.09-.898-1.214-.575-2.57-.983-4.421-1.378a92.951 92.951 0 0 0-1.846-.37c-2.345-.456-4.73-.832-7.14-1.124l-.294-.036-.228-.19c-3.031-2.543-5.804-4.435-8.64-5.792-1.587-.76-2.502-1.118-3.474-1.294a3.948 3.948 0 0 1-.511-.133l-.11-.033a2.2 2.2 0 0 0-.131-.035c-.31-.07-.641-.129-1.056-.188-.91-.13-1.871-.218-2.961-.277-2.164-.117-4.37-.127-7.402-.063-4.459.095-9.12.34-14.618.74-1.112.082-2.093.158-3.03.236h-.005c-.248.021-.297.026-.392.04a1.7 1.7 0 0 0-.132.025c.033.157.182.35.497.603l.69.554-.464.752c-1.27 2.056-2.318 4.012-3.172 5.963-.46 1.018-.616 3.528-.458 5.135l.012.12-.017.12c-.08.557-.284.896-.672 1.313l-.07.076c-.161.174-.196.235-.222.408-.173 1.15-.182 1.74-.003 2.368.176.618.524 1.097.932 1.295.564.274 1.208.468 2.115.66.237.051.332.07.89.18l.026.004c.408.08 2.4.29 4.898.517-.018-1.127.302-2.19.948-3.085a5.086 5.086 0 0 1 1.964-1.607 5.184 5.184 0 0 1 2.476-.444c2.54.213 4.584 2.379 4.61 4.997-.003.288-.03.572-.08.85h.006z"/>
        <path className="stroke" strokeWidth="2" d="M18.86 36.902a5.076 5.076 0 0 1-1.868-3.689c-.027-1.146.283-2.232.943-3.146a5.096 5.096 0 0 1 1.962-1.608 5.23 5.23 0 0 1 2.474-.448c2.489.209 4.592 2.342 4.619 4.984-.026 2-1.165 3.704-2.959 4.565-1.654.795-3.773.475-5.17-.658zM55.86 36.902a5.076 5.076 0 0 1-1.868-3.689c-.027-1.146.283-2.232.943-3.146a5.096 5.096 0 0 1 1.962-1.608 5.23 5.23 0 0 1 2.474-.448c2.489.209 4.592 2.342 4.619 4.984-.026 2-1.165 3.704-2.959 4.565-1.654.795-3.773.475-5.17-.658z"/>
      </g>
    </svg>
  )
}
