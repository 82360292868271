import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'tw-oi-core'
import {ROUTE, UI, MESSAGE, BRANDS, ENV_PROD} from '../config'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { getEnv } from '../extras/utils'

import { connect } from 'react-redux'

import TopMenu from './TopMenu'
import Media from './Media'
import Icon from './Icon'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { trackDownloadOwnersManualPDF } from 'tw-oi-core/services/analytics'

class ScreenHead extends Component {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    back: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    backTitle: PropTypes.string,
    location: PropTypes.object.isRequired,
    menuEnabled: PropTypes.bool,
    landscapeOverlay: PropTypes.bool,
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool,
      isLandscape: PropTypes.bool
    }),
    brandRoute: PropTypes.string.isRequired,
    ownersManualPdfs: ImmutablePropTypes.list,
    currentBrand: PropTypes.string,
    currentModel: PropTypes.string,
    currentYear: PropTypes.string,
  }

  static defaultProps = {
    title: "",
    back: null,
    backTitle: "Back",
    menuEnabled: true,
    landscapeOverlay: true,
  }

  terms = null

  /**
   * Build top menu items
   * In case userMedia is mobile, will add menu items with a link to owner manual pdf file
   *
   * @returns {Array}
   */
  buildTopMenuLinks() {
    const { location, menuEnabled, userMedia, brandRoute, ownersManualPdfs } = this.props

    if (!menuEnabled || (location.pathname && location.pathname.indexOf(`${brandRoute}${ROUTE.VEHICLES}`) === 0)) {
      return []
    }
    const menuSelectModel = {
      url: `#${brandRoute}${ROUTE.VEHICLES}`,
      title: userMedia.isDesktop ? UI.MENU_TITLE_SELECT_MODEL.desktop : UI.MENU_TITLE_SELECT_MODEL.mobile
    }

    const menuOwnersManualPdfs = !userMedia.isDesktop && ownersManualPdfs ? ownersManualPdfs.map(item => {
      return {
        url: item.getIn(['bodyPdf', 'url']),
        title: `Owner’s Manual PDF: ${UI.OWNERS_MANUAL_PDF_TITLE[item.get('locale')]}`,
        iconType: 'list-download-pdf',
        onMenuClick: () => trackDownloadOwnersManualPDF(this.props.currentModel, item.get('language')),
        target:"_blank",
        rel:"noopener noreferrer",
      }
    }) : []

    return [menuSelectModel, ...menuOwnersManualPdfs]
  }

  render() {
    const { FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW } = getConfig()
    const {
      back,
      backTitle,
      userMedia,
      landscapeOverlay,
      brandRoute,
      currentBrand,
      currentModel,
      currentYear
    } = this.props

    let backLink = null

    if (back) {
      if (typeof back === 'function') {
        backLink = <button onClick={back}><Icon type="arrow-left"/>{backTitle}</button>
      } else {
        backLink = <Link to={back} replace><Icon type="arrow-left"/>{backTitle}</Link>
      }
    }

    const title = this.props.children || this.props.title
    const titleText = (title[1] && title[1].props && title[1].props.children) || title
    const showRotationWarning = userMedia.isLandscape && landscapeOverlay
    const topMenuLinks = this.buildTopMenuLinks()
    const currentEnv = getEnv()

    //TODO: refactor Media conditional rendering -> for using redux condition state isDesktop
    return (
      <div className="screen-head">
        <Media type="desktop">
          <Link to={`${brandRoute}`} className="screen-logo"/>
        </Media>

        { showRotationWarning &&
        <div className={classNames("screen-overlay")}>
          <Icon type="rotate-device"/>
          <div className="overlay-text">{MESSAGE.ROTATE_DEVICE_ORIENTATION}</div>
        </div>}

        <Media type="desktop">
          <h1 title={titleText} className={classNames(
            "screen-title",
            titleText.length > 25 && "title-long",
          )}>{title}</h1>
        </Media>

        <Media type="mobile">
          {back ?
            <div className={classNames(
              "screen-back",
              backTitle.length > 25 && "title-long",
            )}>{backLink}</div>  :
            <div className="screen-title">{title}</div>}
        </Media>

        <TopMenu
          links={topMenuLinks}
          currentBrand={currentBrand}
          currentModel={currentModel}
          currentYear={currentYear}
        />

        {(FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW || currentEnv !== ENV_PROD) && <Link to={brandRoute + ROUTE.EDITOR} className='content-preview-mode'>{currentEnv + " Env"} / {FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW ? "Draft content" : "Published content"}</Link>}
      </div>
    )
  }
}

function mapStateToProps({user, vehicle: { currentBrand, currentModel, currentYear } }) {
  const brand = BRANDS.find(brand => brand.name === currentBrand)
  return {
    userMedia: user.media,
    currentModel,
    currentYear,
    currentBrand,
    brandRoute: `/${brand.key}`
  }
}

export default connect(mapStateToProps, null)(withRouter(ScreenHead))
