import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setVehicleBrand } from 'tw-oi-core/actions/VehicleActions'
import { getPathAlias } from 'tw-oi-core/utils/vehicle'
import { BRANDS } from '../config'

import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import ThemeRoute from './ThemeRoute'

import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import '../styles/App.scss'

class ThemeContainer extends Component {
  static propTypes = {
    currentBrand:PropTypes.object.isRequired,
    setVehicleBrand: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.array,
      PropTypes.func
    ])
  }

  render() {
    const { currentBrand } = this.props
    const brand = getPathAlias(currentBrand.name)

    return <React.StrictMode>
      <div className={classNames("container", brand ? "theme-" + brand : "")}>
        <Helmet>
          <title>{`${currentBrand.title} Owner's Kit`}</title>
        </Helmet>
        <Router>
          <Switch>
            <Route path={'/:brandKey?'} render={props => <ThemeRoute {...{...this.props, ...props}}/>}/>
          </Switch>
        </Router>
      </div>
    </React.StrictMode>
  }
}

function mapStateToProps({vehicle:{ currentBrand }}) {

  const brand = BRANDS.find(brand => brand.name === currentBrand)

  return { currentBrand: brand }
}

function mapDispatchToProps(dispatch) {
  return {
    setVehicleBrand: bindActionCreators(setVehicleBrand, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeContainer)
