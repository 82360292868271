import React from 'react'

export function video() {
  return (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>videos</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-364.000000, -31.000000)">
          <g transform="translate(364.000000, 31.000000)">
            <rect x="0" y="0" width="24" height="24"></rect>
            <path d="M16,21 C16.5522847,21 17,21.4477153 17,22 C17,22.5522847 16.5522847,23 16,23 L8,23 C7.44771525,23 7,22.5522847 7,22 C7,21.4477153 7.44771525,21 8,21 L16,21 Z M21,2 C22.1045695,2 23,2.8954305 23,4 L23,4 L23,18 C23,19.1045695 22.1045695,20 21,20 L21,20 L3,20 C1.8954305,20 1,19.1045695 1,18 L1,18 L1,4 C1,2.8954305 1.8954305,2 3,2 L3,2 Z M21,4 L3,4 L3,18 L21,18 L21,4 Z M9,6.5 L16,11 L9,15.5 L9,6.5 Z" fill="#6E6E6E" fillRule="nonzero"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
