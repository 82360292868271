import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getTopicsInFolder } from 'tw-oi-core/utils/data'
import classNames from 'classnames'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { trackPreviousTopicClick, trackNextTopicClick } from 'tw-oi-core/services/analytics'
import Icon from './Icon'
import PropTypes from 'prop-types'
import '../styles/TopicNavigation.scss'
import Media from './Media'


export default class TopicNavigation extends Component {
  static propTypes = {
    currentTopic: ImmutablePropTypes.map,
    currentFolder: ImmutablePropTypes.map,
    className: PropTypes.string
  }

  render() {
    const {currentTopic, currentFolder} = this.props
    if (!currentTopic || !currentFolder) {
      return null
    }

    const topicResourceKey = currentTopic.get('resourceKey')
    const topicsInFolder = getTopicsInFolder(currentFolder, true)
    const topicIndex = topicsInFolder.findIndex(topic => topic.get('resourceKey') === topicResourceKey)
    const prevTopic = topicIndex > 0 ? topicsInFolder.get(topicIndex - 1) : null
    const nextTopic = topicIndex < topicsInFolder.size ? topicsInFolder.get(topicIndex + 1) : null

    return <div className={classNames('TopicNavigation', this.props.className)} >
      <div className={'topic-nav-wrapper'}>
        {prevTopic &&
          <Link
            onClick={() => trackPreviousTopicClick(currentTopic.get('title'))}
            className='topic-nav-link-prev'
            to={prevTopic.get('resourceKey')}
          >
            <div className='topic-nav-link-direction'>
              <Icon type='nav-arrow' className='icon-nav-arrow-prev'/>
              <Media type='desktop'>Previous</Media>
            </div>
            <div className='topic-nav-link-title'>{prevTopic.get('title')}</div>
          </Link>
        }
      </div>
      <div className={'topic-nav-wrapper'}>
        {nextTopic &&
          <Link
            onClick={() => trackNextTopicClick(currentTopic.get('title'))}
            className='topic-nav-link-next'
            to={nextTopic.get('resourceKey')}
          >
            <div className='topic-nav-link-direction'>
              <Media type='desktop'>Next</Media>
              <Icon type='nav-arrow' className='icon-nav-arrow-next'/>
            </div>
            <div className='topic-nav-link-title'>{nextTopic.get('title')}</div>
          </Link>
        }
      </div>
    </div>
  }
}
