import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getConfig } from 'tw-oi-core'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import qs from 'qs'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { filterTopicsByTopPicks } from 'tw-oi-core/utils/data'
import { setVehicleProgram, setBrand, setTypeSubType, setYearDimension } from 'tw-oi-core/services/analytics'
import {
  DEFAULT_PUBLICATION_TYPE,
  OWNERS_MANUAL_PDF_LOCALES,
  MESSAGE,
  ROUTE,
  VEHICLE_FAMILIES,
  BRANDS,
  PRODUCT_MAP,
  PRODUCT_SUBTYPE_MAP,
} from '../config'
import * as VehicleActions from 'tw-oi-core/actions/VehicleActions'
import * as ContentsActions from 'tw-oi-core/actions/ContentsActions'
import { getPathAlias } from 'tw-oi-core/utils/vehicle'

import NavBar from '../components/NavBar'
import Browse from './Browse'
import BrowseDita from './BrowseDita'
import Videos from './Videos'
import ArticlesTags from './ArticlesTags'
import ArticlesContent from './ArticlesContent'
import ArticleViewer from './ArticleViewer'
import PdfViewer from './PdfViewer'
import ComponentManuals from './ComponentManuals'
import Topic from './Topic'
import TopPicks from './TopPicks'
import Favorites from './Favorites'
import VideoViewer from './VideoViewer'
import Search from './Search'
import Loader from '../components/Loader'
import ErrorMessage from '../components/ErrorMessage'
import { getVehiclesYears, getVehicleForBrand } from 'tw-oi-core/utils/vehicle'
import api from 'tw-oi-core/services/ContentDelivery'

import '../styles/Guide.scss'
import { isPdfPublication, sortPublicationByLocale } from '../extras/utils'
import { fromJS } from 'immutable'
import StickyBar from '../components/StickyBar'
import Media from '../components/Media'
import BrandSpecificInformation from './BrandSpecificInformation'

export class Guide extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        year: PropTypes.string.isRequired,
        model: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    contents: PropTypes.shape({
      topics: ImmutablePropTypes.list,
      publications: ImmutablePropTypes.list,
      error: PropTypes.string,
      imageMaps: PropTypes.array,
      imageMapBlobs: PropTypes.object,
    }),
    vehicle: PropTypes.shape({
      vehicles: PropTypes.array,
      currentBrand: PropTypes.string,
      currentYear: PropTypes.string,
      currentProductType: PropTypes.string,
      currentProductSubtype: PropTypes.string,
      currentModel: PropTypes.string,
      currentId: PropTypes.string,
      error: PropTypes.object,
    }).isRequired,
    VehicleActions: PropTypes.shape({
      getVehicles: PropTypes.func.isRequired,
      setVehicleProductType: PropTypes.func.isRequired,
      setVehicleProductSubtype: PropTypes.func.isRequired,
      setVehicleModel: PropTypes.func.isRequired,
      setVehicleId: PropTypes.func.isRequired,
      setVehicleYear: PropTypes.func.isRequired,
    }).isRequired,
    ContentsActions: PropTypes.shape({
      getContentsByVehicleId: PropTypes.func.isRequired,
      getImageMaps: PropTypes.func.isRequired,
    }),
    brandRoute: PropTypes.string.isRequired,
    ownersManualPdfs: ImmutablePropTypes.list,
  }

  constructor(props) {
    super(props)

    this.state = {
      ready: false,
      found: false,
      ownersManualPdfs: this.props.ownersManualPdfs ? this.props.ownersManualPdfs : null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.found && !(nextProps.contents.publications || nextProps.contents.topics)) {
      return {
        ready: false,
      }
    }

    return null
  }

  getOwnersManualPdfs(currentBrand, currentYear, currentModel, vehicleId = null) {
    //load fr and eng owners manual pdfs
    return api
      .get({
        // this is needed because axios by default serializes arrays with square brackets,
        // e.g. ?locale[]=1&locale[]=2, and CD's configuration doesn't support those brackets
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),

        url: '/api/v2/contents/publications',
        params: {
          make: currentBrand,
          year: currentYear,
          model: currentModel,
          locale: OWNERS_MANUAL_PDF_LOCALES,
          publicationType: DEFAULT_PUBLICATION_TYPE,
        },
      })
      .then(response => fromJS(response.data))
      .then(pubs =>
        pubs.filter(
          pub =>
            isPdfPublication(pub) &&
            (!vehicleId ||
              pub
                .get('vehicles')
                .map(vehicle => vehicle.get('id'))
                .find(id => id === vehicleId))
        )
      )
      .then(pubs => sortPublicationByLocale(pubs, OWNERS_MANUAL_PDF_LOCALES))
      .then(pubs => this.setState({ ownersManualPdfs: pubs }))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error(`Couldn't fetch owner's manual pdfs for '${currentYear}:${currentBrand}:${currentModel}'`)
        this.setState({ ownersManualPdfs: fromJS([]) })
      })
  }

  loadVehicleContent = (year, model) => {
    const { DEFAULT_LOCALE } = getConfig()
    const { vehicles, currentBrand, currentYear, currentModel, currentId, currentProductType, currentProductSubtype } =
      this.props.vehicle
    const { imageMapBlobs, imageMaps, publications } = this.props.contents
    const {
      getVehicles,
      setVehicleModel,
      setVehicleId,
      setVehicleYear,
      setVehicleProductType,
      setVehicleProductSubtype,
    } = this.props.VehicleActions
    const { getContentsByVehicleId, getImageMaps } = this.props.ContentsActions

    // #0 load vehicles if doesn't exist
    if (vehicles === null) {
      getVehicles().then(() => this.loadVehicleContent(year, model))
      return
    }

    // #1 check years availability
    const brandVehicles = getVehicleForBrand(vehicles, currentBrand)
    const years = getVehiclesYears(brandVehicles)

    if (years.indexOf(year) === -1) {
      return this.setState({ ready: true, found: false })
    }

    if (year !== currentYear) setVehicleYear(year)

    // #2 check model availability (for currentYear)
    const currentVehicle = vehicles.find(
      vehicle =>
        vehicle.make === currentBrand &&
        vehicle.year === year &&
        getPathAlias(`${vehicle.id} ${vehicle.model}`) === model
    )

    // return early if vehicle isn't available
    if (!currentVehicle) {
      return this.setState({ ready: true, found: false })
    }

    // update vehicle program state (model, productType and productSubType)
    if (currentVehicle.model !== currentModel) setVehicleModel(currentVehicle.model)
    if (currentVehicle.id !== currentId) setVehicleId(currentVehicle.id)
    if (currentVehicle.FOREST_RIVER_PRODUCT_TYPE !== currentProductType)
      setVehicleProductType(currentVehicle.FOREST_RIVER_PRODUCT_TYPE)
    if (currentVehicle.FOREST_RIVER_PRODUCT_SUB_TYPE !== currentProductSubtype)
      setVehicleProductSubtype(currentVehicle.FOREST_RIVER_PRODUCT_SUB_TYPE)

    // #3 load vehicle content if not loaded
    if (
      publications === null ||
      currentVehicle.year !== currentYear ||
      currentVehicle.model !== currentModel ||
      currentVehicle.FOREST_RIVER_PRODUCT_TYPE !== currentProductType ||
      currentVehicle.FOREST_RIVER_PRODUCT_SUB_TYPE !== currentProductSubtype
    ) {
      return Promise.all([
        getContentsByVehicleId(currentVehicle.id, DEFAULT_LOCALE),
        this.getOwnersManualPdfs(currentBrand, year, currentVehicle.model, currentVehicle.id),
      ]).then(() => this.loadVehicleContent(year, model))
    } else if (this.state.ownersManualPdfs === null) {
      return this.getOwnersManualPdfs(currentBrand, year, currentVehicle.model, currentVehicle.id).then(() =>
        this.loadVehicleContent(year, model)
      )
    } else {
      this.setState({ ready: true, found: true })
    }

    // #4 preload vehicle image maps
    if (imageMapBlobs === null && imageMaps !== null) {
      getImageMaps(imageMaps)
    }

    // analytics
    setVehicleProgram(year, currentBrand, currentVehicle.model)
    setBrand(currentBrand)
    setTypeSubType(
      PRODUCT_MAP[currentProductType] ? PRODUCT_MAP[currentProductType].title : currentProductType,
      PRODUCT_SUBTYPE_MAP[currentProductSubtype] || currentProductSubtype
    )
    setYearDimension(year)
  }

  renderWithProps = (Component, additionalProps) => props => {
    return <Component {...props} {...additionalProps} />
  }

  componentDidMount() {
    const { year, model } = this.props.match.params
    this.loadVehicleContent(year, model)
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { year, model },
      },
      vehicle: { currentBrand },
    } = this.props
    const {
      match: {
        params: { year: prevYear, model: prevModel },
      },
      vehicle: { currentBrand: prevCurrentBrand },
    } = prevProps

    if (currentBrand !== prevCurrentBrand || year !== prevYear || model !== prevModel) {
      this.setState({ ready: false, found: false })
      this.loadVehicleContent(year, model)
    }
  }

  render() {
    const { FEATURE_ENABLED_FAVORITES } = getConfig()
    const {
      match,
      match: {
        params: { year, model },
        url: baseRoute,
      },
      brandRoute,
    } = this.props
    const { error } = this.props.vehicle
    const { error: errorContents, publications, topics } = this.props.contents
    const { ready, found, ownersManualPdfs } = this.state

    if (error || errorContents) {
      return <ErrorMessage className='inverse' retryAction={() => this.loadVehicleContent(year, model)} />
    }

    if (!ready) {
      return <Loader className='inverse' />
    }

    if (!found) {
      return (
        <ErrorMessage
          className='inverse'
          title={MESSAGE.ERROR_VEHICLE_NOT_FOUND}
          retryAction={brandRoute + ROUTE.VEHICLES}
          retryTitle='Select vehicle'
          message={MESSAGE.ERROR_VEHICLE_RETRY}
        />
      )
    }

    const topPicksAvailable = filterTopicsByTopPicks(topics).size > 0

    // show "Content is being updated" message when there are no topics available
    if (!topics && !publications) {
      return (
        <ErrorMessage
          className='inverse wider'
          title={MESSAGE.INFORMATION_IS_BEING_UPDATED}
          retryAction={`${brandRoute}${ROUTE.VEHICLES}`}
          retryTitle={MESSAGE.GO_BACK}
          message={null}
        />
      )
    }

    const modelFamilyAvailable =
      year in VEHICLE_FAMILIES && typeof VEHICLE_FAMILIES[year] === 'object' && model in VEHICLE_FAMILIES[year]

    return (
      <div className='Guide'>
        <Switch>
          {modelFamilyAvailable ? (
            <Redirect exact from={`${match.path}`} to={`${ROUTE.VEHICLES}/${year}/${model}`} />
          ) : (
            <Redirect exact from={match.path} to={`${match.url}${ROUTE.BROWSE}`} />
          )}
          <Route
            exact
            path={`${match.path}${ROUTE.BROWSE}`}
            render={this.renderWithProps(Browse, { baseRoute, ownersManualPdfs })}
          />
          <Route
            exact
            path={`${match.path}${ROUTE.BROWSE}${ROUTE.TAGS}/:tag?`}
            render={this.renderWithProps(ArticlesTags, { baseRoute })}
          />
          <Route
            exact
            path={`${match.path}${ROUTE.BROWSE}${ROUTE.ARTICLES}/:tag?`}
            render={this.renderWithProps(ArticlesContent, { baseRoute })}
          />
          <Route
            exact
            path={[
              `${match.path}:referrer(${ROUTE.BROWSE}|${ROUTE.TOPPICKS}|${ROUTE.FAVORITES}|${ROUTE.SEARCH})${ROUTE.ARTICLES}${ROUTE.CONTENT}/:resourceKey`,
              `${match.path}:referrer(${ROUTE.BROWSE}|${ROUTE.TOPPICKS}|${ROUTE.FAVORITES}|${ROUTE.SEARCH})${ROUTE.ARTICLES}/:tag${ROUTE.CONTENT}/:resourceKey`,
              `${match.path}:referrer(${ROUTE.BROWSE}|${ROUTE.TOPPICKS}|${ROUTE.FAVORITES}|${ROUTE.SEARCH})/:brandSpecific${ROUTE.ARTICLES}/:resourceKey`,
            ]}
            render={this.renderWithProps(ArticleViewer, { baseRoute })}
          />
          <Route
            exact
            path={`${match.path}${ROUTE.BROWSE}${ROUTE.VIDEOS}`}
            render={this.renderWithProps(Videos, { baseRoute })}
          />
          <Route
            exact
            path={`${match.path}${ROUTE.BROWSE}${ROUTE.VIDEOS}/:pseudoFolderName`}
            render={this.renderWithProps(Videos, { baseRoute })}
          />
          <Route
            exact
            path={[
              `${match.path}:referrer(${ROUTE.BROWSE}|${ROUTE.TOPPICKS}|${ROUTE.FAVORITES}|${ROUTE.SEARCH})${ROUTE.VIDEOS}/:pseudoFolderName${ROUTE.CONTENT}/:videoId`,
              `${match.path}:referrer(${ROUTE.BROWSE}|${ROUTE.TOPPICKS}|${ROUTE.FAVORITES}|${ROUTE.SEARCH})${ROUTE.VIDEOS}${ROUTE.CONTENT}/:videoId`,
              `${match.path}:referrer(${ROUTE.BROWSE}|${ROUTE.TOPPICKS}|${ROUTE.FAVORITES}|${ROUTE.SEARCH})/:brandSpecific${ROUTE.VIDEOS}/:videoId`,
            ]}
            render={this.renderWithProps(VideoViewer, { baseRoute, ownersManualPdfs })}
          />
          <Route
            exact
            path={`${match.path}${ROUTE.BROWSE}${ROUTE.PDFS}`}
            render={this.renderWithProps(ComponentManuals, { baseRoute })}
          />
          <Route
            exact
            path={`${match.path}${ROUTE.BROWSE}${ROUTE.PDFS}/:pseudoFolderName`}
            render={this.renderWithProps(ComponentManuals, { baseRoute })}
          />
          <Route
            exact
            path={[
              `${match.path}:referrer(${ROUTE.BROWSE}|${ROUTE.TOPPICKS}|${ROUTE.FAVORITES}|${ROUTE.SEARCH})${ROUTE.PDFS}/:pseudoFolderName${ROUTE.CONTENT}/:pdfResourceKey`,
              `${match.path}:referrer(${ROUTE.BROWSE}|${ROUTE.TOPPICKS}|${ROUTE.FAVORITES}|${ROUTE.SEARCH})${ROUTE.PDFS}${ROUTE.CONTENT}/:pdfResourceKey`,
              `${match.path}:referrer(${ROUTE.BROWSE}|${ROUTE.TOPPICKS}|${ROUTE.FAVORITES}|${ROUTE.SEARCH})/:brandSpecific/publication/:pdfResourceKey`,
            ]}
            render={this.renderWithProps(PdfViewer, { baseRoute, ownersManualPdfs })}
          />
          <Route
            exact
            path={`${match.path}${ROUTE.BROWSE}${ROUTE.BRAND_SPECIFIC}`}
            render={this.renderWithProps(BrandSpecificInformation, { baseRoute })}
          />
          <Route
            exact
            path={`${match.path}${ROUTE.BROWSE}(${ROUTE.FOLDER})?/:folderId?`}
            render={this.renderWithProps(BrowseDita, { baseRoute, ownersManualPdfs })}
          />
          <Route
            exact
            path={[
              `${match.path}:referrer(${ROUTE.BROWSE}|${ROUTE.TOPPICKS}|${ROUTE.FAVORITES}|${ROUTE.SEARCH})${ROUTE.TOPIC}/:topicId/related-contents/:videoId/:time?`,
              `${match.path}:referrer(${ROUTE.BROWSE}|${ROUTE.TOPPICKS}|${ROUTE.FAVORITES}|${ROUTE.SEARCH})${ROUTE.TOPIC}/:topicId`,
            ]}
            render={this.renderWithProps(Topic, { baseRoute, ownersManualPdfs })}
          />
          {FEATURE_ENABLED_FAVORITES && (
            <Route
              exact
              path={`${match.path}${ROUTE.FAVORITES}`}
              component={this.renderWithProps(Favorites, { ownersManualPdfs })}
            />
          )}
          <Route exact path={`${match.path}${ROUTE.TOPPICKS}`} component={TopPicks} />
          <Route
            exact
            path={`${match.path}${ROUTE.SEARCH}`}
            render={this.renderWithProps(Search, { baseRoute, ownersManualPdfs })}
          />
          <Redirect from={match.path} to={match.url} /> {/*Redirect to Guide root if other not found:*/}
        </Switch>
        <Media type='desktop'>
          <StickyBar className='sticky-nav-bar'>
            <NavBar baseRoute={match.url} topPicks={topPicksAvailable} favorites={FEATURE_ENABLED_FAVORITES} />
          </StickyBar>
        </Media>
        <Media type='mobile'>
          <NavBar baseRoute={match.url} topPicks={topPicksAvailable} favorites={FEATURE_ENABLED_FAVORITES} />
        </Media>
      </div>
    )
  }
}

function mapStateToProps({ vehicle, contents }) {
  const brand = BRANDS.find(brand => brand.name === vehicle.currentBrand)
  return {
    contents,
    vehicle,
    brandRoute: brand ? `/${brand.key}` : '/',
  }
}

function mapDispatchToProps(dispatch) {
  return {
    VehicleActions: bindActionCreators(VehicleActions, dispatch),
    ContentsActions: bindActionCreators(ContentsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Guide)
