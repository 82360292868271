import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ENV_PROD, ENV_STAGE, MESSAGE } from "../config"



export default class LoginForm extends Component {

  static propTypes = {
    onLogin: PropTypes.func.isRequired,
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
    loginFormError: PropTypes.string,
    currentEnv: PropTypes.oneOf([ENV_STAGE, ENV_PROD]).isRequired,
    onEnvChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    currentEnv: ENV_PROD
  }

  constructor(props) {
    super(props)

    this.state = {
      clientId: props.clientId,
      clientSecret: props.clientSecret,
      loginFormError: props.loginFormError,
      currentEnv: props.currentEnv
    }
  }

  onSubmit = async(e) => {
    const { onLogin } = this.props

    e.preventDefault()

    // validate login & password
    if (!this.state.clientId) {
      return this.setState({
        loginFormError: 'Client ID can\'t be empty'
      })
    }

    if (!this.state.clientSecret) {
      return this.setState({
        loginFormError: 'Client Secret can\'t be empty'
      })
    }

    // login to Content Delivery and verify the provided credentials work as expected
    this.setState({
      loginFormError: null // clear prior validation errors
    })

    if (onLogin instanceof Function) {
      onLogin(this.state.clientId, this.state.clientSecret, this.state.currentEnv)
    }
  }

  onEnvChange = async(currentEnv) => {
    this.setState({currentEnv})
  }


  render() {
    return (
      <form className="editor-section login-form" onSubmit={this.onSubmit}>
        {!!this.state.loginFormError && <div className="editor-error">{this.state.loginFormError}</div>}
        <label className="input-label">PubHub Client ID</label>
        <div className="Input">
          <input type="text" name="client-id" onChange={(e) => this.setState({clientId: e.target.value})} value={this.state.clientId}/>
        </div>
        <label className="input-label">PubHub Client Secret</label>
        <div className="Input">
          <input type="text" name="client-secret" onChange={(e) => this.setState({clientSecret: e.target.value})} value={this.state.clientSecret}/>
        </div>
        <button className="editor-forgot-password" type="button" onClick={() => {alert(MESSAGE.FORGOT_PASSWORD)}}>Forgot your password?</button>
        <div className="Select">
          <select value={this.state.currentEnv} onChange={e => this.onEnvChange(e.currentTarget.value)}>
            <option value={ENV_PROD}>Production</option>
            <option value={ENV_STAGE}>Stage</option>
          </select>
        </div>
        <div className="editor-submit">
          <button className="PrimaryButton" type="submit">Login</button>
        </div>
      </form>
    )
  }
}
