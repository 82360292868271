import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Icon from './Icon'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

class BreadCrumb extends Component {

  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
    }))
  }

  render() {
    const { items } = this.props

    if (!items || items.length === 0) {
      return null
    }

    return (
      <ul className={classNames("topics-breadcrumb")}>
        {items.map((item, i) => {
          if (i === items.length - 1) {
            return <li key={item.title}><span>{item.title}</span></li>
          }

          return <li key={item.title}>
            <Link to={item.link}>{item.title}</Link>
            <Icon type="arrow-left"/>
          </li>
        })}
      </ul>
    )
  }

}

export default BreadCrumb
