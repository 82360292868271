import React from 'react'

export function check() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" style={{enableBackground:"new 0 0 20 20"}} xmlSpace="preserve">
      <g stroke="none" strokeWidth="1" fill='none' fillRule="evenodd">
        <g strokeWidth="2" className="stroke" fill='none' fillRule="evenodd">
          <polyline points="1.12304688 10.0957031 7.3299191 16.3025754 19.3565003 4.2759941" />
        </g>
      </g>
    </svg>
  )
}
