import { fromJS, List } from 'immutable'
import { HTML_URL_PLACEHOLDERS, CONTENT_DELIVERY, CONTENT_TYPE, ROUTE } from '../config'
import { getConfig } from 'tw-oi-core'
import { isIos } from 'tw-oi-core/utils/userAgent'

/**
 * Gets sorted product types for given year
 *
 * @param {Array.<Object>} vehicles
 * @param {Number} year
 * @returns {Array.<String>}
 */
export function getVehicleProductTypes(vehicles, year) {
  return [
    ...new Set(
      vehicles
        .filter(item => item.year === year && item.FOREST_RIVER_PRODUCT_TYPE)
        .map(i => i.FOREST_RIVER_PRODUCT_TYPE)
    ),
  ].sort()
}

/**
 * Gets sorted models for given product type
 *
 * @param {Array.<String>} vehicles
 * @param {Number} year
 * @param {String} productType
 * @returns {Array.<Object>}
 */
export function getVehiclesForProductType(vehicles, year, productType) {
  return productType
    ? vehicles
        .filter(item => item.year === year)
        .filter(vehicle => vehicle.FOREST_RIVER_PRODUCT_TYPE === productType)
        .sort((a, b) => a.model.localeCompare(b.model))
    : vehicles
}

/**
 * Gets sorted models for given product type
 *
 * @param {Array.<String>} vehicles
 * @param {String} productSubType
 * @returns {Array.<Object>}
 */
export function filterVehiclesBySubType(vehicles, productSubType) {
  return productSubType
    ? vehicles.filter(vehicle => vehicle.FOREST_RIVER_PRODUCT_SUB_TYPE === productSubType)
    : vehicles
}

/**
 * Gets unique subtypes for given models
 *
 * @param {Array.<Object>} models
 * @returns {Array.<String>}
 */
export function getVehicleSubTypes(models) {
  return [
    ...new Set(
      models
        .map(model => model.FOREST_RIVER_PRODUCT_SUB_TYPE)
        .filter(subtype => subtype)
        .sort()
    ),
  ]
}

/**
 * Get root folder of given publication list
 *
 * @param {Immutable.List} publications
 * @param {String} publicationType
 * @param {Immutable.Map} foldersIndex
 * @param {String} defaultRootResourceKey
 * @return {String}
 */
export function getRootFolderResourceKey(publications, publicationType, foldersIndex, defaultRootResourceKey) {
  const rootPublication =
    publications &&
    publications.find(
      item =>
        item.get('publicationType') === publicationType &&
        // publication with contents is the one we need
        item.get('contents') &&
        item.get('contents').size
    )
  const rootKey = rootPublication && publications.size > 1 ? rootPublication.get('resourceKey') : defaultRootResourceKey
  const rootFolder = foldersIndex.get(rootKey)
  // support publications with and without "Home" folder in root
  const rootContents = rootFolder && rootFolder.get('contents')
  return rootContents && rootContents.size === 1 ? rootContents.keySeq().first() : rootKey
}

/**
 * Check if folder is root folder of publication
 *
 * @param {Immutable.Map} folder
 * @param {String} rootFolderResourceKey
 * @return {Boolean}
 */
export function isRootFolder(folder, rootFolderResourceKey) {
  return folder ? folder.get('resourceKey') === rootFolderResourceKey : false
}

/**
 * Get folder parent from folders index
 *
 * @param {Immutable.Map} folder
 * @param {Immutable.Map} foldersIndex
 * @return {Immutable.Map} parentFolder
 */
export function getParentFolder(folder, foldersIndex) {
  const resourceKey = folder && folder.get('resourceKey')
  return resourceKey
    ? foldersIndex.find(folder => {
        return folder.get('contents').keySeq().contains(resourceKey)
      })
    : null
}

/**
 * Check if item is publication
 * @param {Object} item
 * @return {Boolean}
 */
export function isPublication(item) {
  return !!(item && item.type === CONTENT_TYPE.PUBLICATION)
}

/**
 * Check if item is folder
 * @param {Object} item
 * @return {Boolean}
 */
export function isFolder(item) {
  return !!(item && item.type === CONTENT_TYPE.FOLDER && !item.bodyHtml)
}

/**
 * Check if folder is collapsed folder with content
 *
 * @param {Object} item
 * @return {Boolean}
 */
export function isCollapsedFolder(item) {
  return !!(item && item.type === CONTENT_TYPE.FOLDER && item.bodyHtml)
}

/**
 * Check if publication contains pdf asset
 *
 * @param {Immutable.Map} publication
 * @return {Boolean}
 */
export function isPdfPublication(publication) {
  const bodyPdf = publication.get('bodyPdf')
  return bodyPdf && bodyPdf.get('objectKey')
}

/**
 * Go through the list of related contents and assemble sorted list of pdfs and videos
 *
 * @param {Array} relatedContents
 * @param {Object} foldersIndex
 * @param {Array} publications
 * @param {Object} videosIndex
 * @returns {Object} Object with keys: 'pdfs' and 'videos'
 */
export function getVideosAndPdfsFromRelatedContents(relatedContents, foldersIndex, publications, videosIndex) {
  const contents = relatedContents.reduce(
    (acc, resourceKey) => {
      const folder = foldersIndex.get(resourceKey)
      if (folder && isPublication(folder.toJS())) {
        const publication = publications.find(pub => pub.get('resourceKey') === resourceKey)

        if (isPdfPublication(publication)) {
          acc.pdfs.push(publication)
        }
      } else if (resourceKey in videosIndex) {
        const video = videosIndex[resourceKey]
        acc.videos.push(fromJS(video))
      }

      return acc
    },
    { pdfs: [], videos: [] }
  )

  contents.pdfs.sort((a, b) => a.get('title').localeCompare(b.get('title')))
  contents.videos.sort((a, b) => a.get('title').localeCompare(b.get('title')))
  return contents
}

/**
 * Determines whether or not a url is from youtube
 *
 * @param {String} url
 * @returns {Boolean}
 */
export function isYouTubeUrl(url) {
  const regExp = /^https?:\/\/(w{3}.)?youtu?.be/gm
  return !!(url && url.match(regExp))
}

/**
 * Grouped vehicles firstly by year then by product type
 *
 * @param {Array} vehicles
 * @returns {Object}
 */
export function groupedVehiclesByYearAndProductType(vehicles) {
  return vehicles.reduce((acc, item) => {
    const year = item.year
    const productType = item.FOREST_RIVER_PRODUCT_TYPE && item.FOREST_RIVER_PRODUCT_TYPE.toUpperCase()

    if (!productType) return acc

    let yearGroup = acc[year] || {}
    let productTypeGroup = yearGroup[productType] || []

    acc[year] = yearGroup
    yearGroup[productType] = productTypeGroup
    productTypeGroup.push(item)

    return acc
  }, {})
}

/**
 * Check year for legacy state (<=2017)
 *
 * @param {Number} year
 * @returns {Boolean}
 */
export function isLegacyYear(year) {
  return year <= 2017
}

/**
 * Sort publications by locale
 *
 * @param {Immutable.List} publications
 * @param {Array} localeOrdering
 * @returns {Immutable.List}
 */
export function sortPublicationByLocale(publications, localeOrdering) {
  return publications.sort((a, b) => {
    const indexA = localeOrdering.indexOf(a.get('locale'))
    const indexB = localeOrdering.indexOf(b.get('locale'))
    if (indexA === indexB) {
      return 0
    }
    return indexA > indexB ? 1 : -1
  })
}

/**
 * Replaces the URL placeholders with an actual URLs
 *
 * @param {String} content
 * @return {String}
 */
export function updateUrlsPlaceholders(content) {
  Object.keys(HTML_URL_PLACEHOLDERS).forEach(placeholder => {
    const re = new RegExp(placeholder, 'g')
    const link = '<a href="' + HTML_URL_PLACEHOLDERS[placeholder] + '" target="_blank">Open Web Page</a>'
    content = content.replace(re, link)
  })
  return content
}

/**
 * Detects phone numbers in the text and replaces those to clickable links
 *
 * @param {String} content
 * @return {String}
 */
export function updatePhoneNumberToClickable(content) {
  const re = new RegExp('[>]([\\+]?[\\d]?[-\\s\\.]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6})[<]', 'gi')
  return content.replace(re, (match, number) => {
    return '><a href="tel:' + number + '">' + number + '</a><'
  })
}

/**
 * Merge topics with collapsed folders (treated as topics)
 * @param {Immutable.List} topics
 * @param {Immutable.List} folders
 * @return {Immutable.List}
 */
export function mergeTopicsWithCollapsedFolders(topics, folders) {
  return topics && topics.concat && folders && folders.filter
    ? topics.concat(folders.filter(folder => isCollapsedFolder(folder.toJS())))
    : topics
}

/**
 * Detects the environment by base URL
 *
 * @returns {string}
 */
export function getEnv() {
  return Object.keys(CONTENT_DELIVERY).find(key => CONTENT_DELIVERY[key].BASE_URL === getConfig().BASE_URL)
}

/**
 * Hack for ios to recalculate .Topics height when .topics-subtitle is long so it will not make .Navbar partially visible
 * Currently appears on Browse and Topic components
 * potentially fixable with css transform: translateZ(0), but had no luck with it
 * https://tweddle.atlassian.net/browse/OI-1175
 */
export function recalculateTopicsHeightForIOS() {
  if (isIos(navigator.userAgent)) {
    const bodyHeight = document.querySelector('body').clientHeight
    const navHeight = document.querySelector('.NavBar').clientHeight
    const topicsEl = document.querySelector('.Topics')

    if (bodyHeight && navHeight && topicsEl) topicsEl.style.height = bodyHeight - navHeight + 'px'
  }
}

/**
 * Show list of found topics including collapsed content
 *
 * TODO: Get rid of collapsed search results
 *
 * @param {Immutable.List} searchTopics
 * @param {Immutable.List} searchFolders
 * @param {Immutable.List} folders
 * @param {Immutable.Map} foldersIndex
 * @returns {Immutable.List}
 */
export function getCollapsedSearchResults(searchTopics, searchFolders, folders, foldersIndex) {
  if (searchTopics) {
    searchTopics = searchTopics.map(topic => {
      const parentFolder = foldersIndex.find(folder => {
        return folder.get('contents').keySeq().contains(topic.get('resourceKey'))
      })
      if (parentFolder && parentFolder.get('bodyHtml'))
        return folders.find(folder => folder.get('resourceKey') === parentFolder.get('resourceKey'))
      return topic
    })
    // concat with results from folders containing bodyHtml
    if (searchFolders) searchTopics = searchTopics.concat(searchFolders.filter(folder => !!folder.get('bodyHtml')))
    // remove duplicates
    searchTopics = searchTopics.reduce(
      (uniques, item) => (!uniques.includes(item) ? uniques.push(item) : uniques),
      List()
    )
  }
  return searchTopics
}

/**
 * We've introduced Articles / Tags as a new way to author the replacement for legacy DITA XML publication
 * A special type of tag "Owner's Manual" will be used to form a publication root.
 */
export const OWNERS_MANUAL_TITLE = "Owner's Manual"

/**
 * We are emulating the folders hierarchy with tags in a following way:
 * - Content that contains only 1 tag assigned and isn't titled with "Ownder's Manual" contributes 1st level of folders
 * - Content that contains 2 or more tags contributes to the seconds level of hierarchy
 *
 * @param {array} items
 * @return {array}
 */
export const getRootLevelArticles = items => items.filter(x => x.tags.length === 1 && x.tags[0] !== OWNERS_MANUAL_TITLE)

/**
 * We have two types of content for Forest River:
 * - Content assigned to particular vehicle
 * - Content available from the content library (lots of this type in draft preview)
 * Here we are selecting only the publications that is assigned to a vehicle
 *
 * @param {array} pubs
 * @return {array}
 */
export const getPublicationsForVehicle = pubs =>
  pubs.filter(x => x.vehicles && x.vehicles[0].make && x.vehicles[0].model && x.vehicles[0].year)

/**
 * Check if item is topic
 *
 * @param {Object} x
 * @return {Boolean}
 */
export const isDitaTopic = x => !!(x && (x.type === CONTENT_TYPE.TOPIC || x.bodyHtml))
export const isPdf = x => x.type === CONTENT_TYPE.PUBLICATION && !!x.bodyPdf && x.publicationType !== 'OG' // legacy Owners Manual PDF doesn't belong in the app
export const isVideo = x => x.type === CONTENT_TYPE.VIDEO
export const isDita = x => x.publicationType === 'OG' && x.bundle
export const isArticle = x =>
  [CONTENT_TYPE.PUBLICATION, CONTENT_TYPE.TOPIC].includes(x.type) &&
  (x.publicationType || x.publication.publicationType) === 'DEFAULT' &&
  (x.bundle || x.bodyHtml) &&
  !x.bodyPdf
export const isArticlePublication = x =>
  x.type === CONTENT_TYPE.PUBLICATION && x.publicationType === 'DEFAULT' && x.bundle && !x.bodyPdf
export const isTopic = x =>
  x.type === CONTENT_TYPE.TOPIC && !!x.bodyHtml && x.publication && x.publication.publicationType === 'OG'
export const isSupportedContent = x => isPdf(x) || isVideo(x) || isArticlePublication(x) || isTopic(x) // || isDitaTopic(x)
export const hasTags = x => x.tags && x.tags.length
export const containsDita = pubs => pubs.some(isDita)
export const containsPdfs = pubs => pubs.some(isPdf)
export const containsArticles = pubs => pubs.some(isArticle)
export const containsVideos = contents => contents.videos && contents.videos.length > 0
export const withTags = pubs => pubs.filter(hasTags)
export const getPdfs = pubs => pubs.filter(isPdf)
export const getArticlesWithTags = pubs => pubs.filter(x => isArticle(x) && hasTags(x))

/**
 * Articles have a 1-to-1 relation between publication and topic
 * so lets pull the corresponding topic for selected publication
 *
 * @param {Object} pub
 * @param {Array} topics
 * @return {Object}
 */
export const getTopicForArticlePublication = (pub, topics) => {
  const topicId = pub.contents[0].id
  return topics.find(x => x.id === topicId)
}

/**
 * Returns content tagged with particular tag
 * Note that when tag is null then it should return all content with no tags
 *
 * @param {String|null} tag
 * @param {Array} pubs
 * @return {Array}
 */
export const getContentByTag = (tag, pubs) => {
  return pubs.filter(x => (tag ? x.tags && x.tags.includes(tag) : !x.tags || x.tags.length === 0))
}

export const getTags = contents => {
  return contents.reduce((acc, cur) => acc.concat(cur.tags || []), [])
}

export const getUniqueTags = contents => [...new Set(getTags(contents))]

export const tagToRenderItem = tag => ({ title: tag })

export const extractNumberFromText = text => parseInt(text.replace(/^\D+/g, ''), 10)

/**
 * This is a temporary workaround for the tags used as folders until we have an explicit way to set order of folders
 *
 * Editors can use numbers in titles to define order, like
 * Section 1. SomeTitle
 * Section 2. SomeTitle
 * ....
 * Section 10. SomeTitle
 *
 * Unlike A-Z ordering this method should respect the numeric value of the section.
 *
 * IMPORTANT!!!: Only single occurrence of number is supported, otherwise it may lead to unpredictable results.
 * Editor can't use another number if tag name, as having "Section 1. SomeTitle1" will lead into position "11".
 *
 * @param {String} a
 * @param {String} b
 * @return {number}
 */
export const compareNumberedStrings = (a, b) => {
  const an = extractNumberFromText(a)
  const bn = extractNumberFromText(b)

  if (!Number.isNaN(an) && !Number.isNaN(bn)) {
    return an - bn
  }

  // fallback to A-Z order when numbers are not available
  return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
}

/**
 * Orders contents by title
 *
 * @param {Object[]} items
 * @returns {Object[]}
 */
export function sortContentsByTitle(items) {
  return [...items].sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
}

const REFERRER_TO_BREADCRUMB_MAP = {
  [ROUTE.TOPPICKS]: 'Top Picks',
  [ROUTE.FAVORITES]: 'Favorites',
  [ROUTE.SEARCH]: 'Search',
}

export function getStartingBreadCrumb(referrer) {
  return REFERRER_TO_BREADCRUMB_MAP[referrer] || 'Browse'
}
