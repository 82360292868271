import React, { Component } from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import Icon from './Icon'
import { isPdfPublication } from '../extras/utils'
import { Link } from 'react-router-dom'
import { trackPdfClick, trackVideoClick } from 'tw-oi-core/services/analytics'

export default class RelatedContentList extends Component {
  static propTypes = {
    relatedContents: PropTypes.array.isRequired,
    emptyText: PropTypes.string,
    baseRoute: PropTypes.string.isRequired,
  }

  renderPdfLink(content, index = '') {
    const pdfUrl = content.get('bodyPdf').get('url')
    return pdfUrl ? (
      <li onClick={() => trackPdfClick(content.get('title'))} key={content.get('resourceKey') + index}>
        <a className='item-inner' href={pdfUrl} target="_blank" rel="noopener noreferrer">
          <div className="item-title">
            {content.get('title')}
          </div>
          <div className="item-iconbar">
            <Icon type="list-pdf"/>
          </div>
        </a>
      </li>
    ) : null
  }

  renderVideoLink(content, index = '') {
    const { baseRoute } = this.props

    return (
      <li onClick={() => trackVideoClick(content.get('title'))} key={content.get('resourceKey') + index}>
        <Link to={`${baseRoute}/related-contents/${content.get('resourceKey')}`} className='item-inner'>
          <div className="item-title">
            {content.get('title')}
          </div>
          <div className="item-iconbar">
            <Icon type="list-film"/>
          </div>
        </Link>
      </li>
    )
  }

  render() {
    const { relatedContents, emptyText } = this.props

    if (_.isEmpty(relatedContents)) {
      return <div className='RelatedContentEmptyList'>{emptyText}</div>
    }

    const items = relatedContents.map((content, index) => isPdfPublication(content) ? this.renderPdfLink(content, index) : this.renderVideoLink(content, index))

    return (
      <ul className='RelatedContentList'>{items}</ul>
    )
  }
}
