import React from 'react'

export function truckCamper() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373 125" style={{enableBackground: "new 0 0 373 125"}} xmlSpace="preserver">
      <g className="fill">
        <path d="M150.967 111c-.514 7.818-7.019 14-14.967 14-7.945 0-14.447-6.176-14.966-13.99H84.505A2.505 2.505 0 0 1 82 108.5v-4.497V104c0-.667-.384-1-1.152-1H80a2 2 0 0 1-2-2v-1a2 2 0 0 1 2-2h.848c.768 0 1.152-.333 1.152-1V83.5c0-.17.017-.336.05-.497A2.505 2.505 0 0 1 84.505 80H188V40.5a2.5 2.5 0 0 1 2.505-2.5h22.996l.073.001L214 38c9.228 0 15.617 2.066 19.168 6.197.222.107.432.25.622.426L258.859 68H281.5c.047 0 .094.001.14.004 5.693.07 10.145 1.068 13.359 2.996 4 2.667 6 6.667 6 12h-.05c.033.162.05.329.05.5v25.043a2.505 2.505 0 0 1-2.505 2.468h-15.529C282.446 118.824 275.945 125 268 125c-7.948 0-14.453-6.182-14.967-14H150.967zm-.506-5h103.078c1.75-6.342 7.562-11 14.461-11 6.9 0 12.71 4.658 14.46 11H296V83.5c0-.171.017-.338.05-.5H296c0-6.516-4.776-9.848-14.33-9.995a2.543 2.543 0 0 1-.169.006h-22.996c-.028 0-.057 0-.085-.002a2.502 2.502 0 0 1-2.205-.623l-25.843-24.098a2.52 2.52 0 0 1-.3-.336L230 48c-4.377-3.751-9.633-5.45-15.769-5.097-.23.07-.476.108-.73.108H193v39.311a2.505 2.505 0 0 1-2.499 2.689H87V106h34.54c1.75-6.342 7.56-11 14.46-11 6.9 0 12.71 4.658 14.46 11zM183 36.977v32.968c0 2.83-1.968 5.055-4.472 5.055H83.382c-2.125.132-3.553 1.101-4.286 2.908h-.001L75 87.945C72.98 93 71.032 93 68.528 93h-9.056C56.968 93 55 90.776 55 87.945V7.055C55 4.225 56.968 2 59.472 2h119.056C181.032 2 183 4.224 183 7.055v20.99c-.17-.016-.345-.031-.523-.045l-2.243 6h-13.678c-1.991 0-3.556-.945-3.556-2.147V4.147C163 2.945 164.565 2 166.556 2h58.888c16.34 0 34.257 24.871 35.234 27.585.976 2.714-.208 4.415-2.853 4.415h-71.37c-2.257.17-3.409 1.161-3.455 2.977zM136 117a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm132 0a7 7 0 1 0 0-14 7 7 0 0 0 0 14z"/>
        <g>
          <path d="M247.777 73.19h-50.175V48.053h19.095a15 15 0 0 1 10.675 4.463l20.405 20.672zm-45.09-20.109v15.22h32.522L223.4 55.88a9 9 0 0 0-6.522-2.799h-14.191zM207.136 80.73h-6.355v5.027h6.355z"/>
        </g>
      </g>
    </svg>
  )
}
