import React from 'react'

export function searchNoDot() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{enableBackground:"new 0 0 24 24"}} xmlSpace="preserve">
      <g className="inactive" stroke="none" fill="none">
        <g transform="translate(0, 2.000000)">
          <path fillRule="evenodd" clipRule="evenodd" d="M1.99975 8C1.99975 4.69141 4.69141 1.99975 8 1.99975C11.3086 1.99975 14.0002 4.69141 14.0002 8C14.0002 11.3086 11.3086 14.0002 8 14.0002C4.69141 14.0002 1.99975 11.3086 1.99975 8ZM0 8C0 12.4114 3.58855 16 8 16C9.85634 16 11.567 15.3646 12.9258 14.2998L18.3412 19.7153C18.5306 19.9048 18.7794 20 19.0281 20C19.2769 20 19.5256 19.9048 19.7151 19.7153C20.095 19.3354 20.095 18.7213 19.7151 18.3414L14.2997 12.9259C15.3645 11.567 16 9.85636 16 8C16 3.58855 12.4114 0 8 0C3.58855 0 0 3.58855 0 8Z" fill="#7C8D98"/>
        </g>
      </g>
      <g className="active" stroke="none" fill="none">
        <g transform="translate(0, 2.000000)">
          <path fillRule="evenodd" clipRule="evenodd" d="M1.99975 8C1.99975 4.69141 4.69141 1.99975 8 1.99975C11.3086 1.99975 14.0002 4.69141 14.0002 8C14.0002 11.3086 11.3086 14.0002 8 14.0002C4.69141 14.0002 1.99975 11.3086 1.99975 8ZM0 8C0 12.4114 3.58855 16 8 16C9.85634 16 11.567 15.3646 12.9258 14.2998L18.3412 19.7153C18.5306 19.9048 18.7794 20 19.0281 20C19.2769 20 19.5256 19.9048 19.7151 19.7153C20.095 19.3354 20.095 18.7213 19.7151 18.3414L14.2997 12.9259C15.3645 11.567 16 9.85636 16 8C16 3.58855 12.4114 0 8 0C3.58855 0 0 3.58855 0 8Z" fill="#323031"/>
        </g>
      </g>
    </svg>
  )
}

