/*eslint no-restricted-globals: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { sortItemsByTitle, filterTopicsByField } from 'tw-oi-core/utils/data'
import { mergeTopicsWithCollapsedFolders } from '../extras/utils'

import { MESSAGE } from '../config'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'

import ScreenHead from '../components/ScreenHead'
import TopicList from '../components/ContentList'
import Media from '../components/Media'

import '../styles/Topics.scss'

class Favorites extends Component {

  static propTypes = {
    topics: ImmutablePropTypes.list,
    favorites: ImmutablePropTypes.list,
    ownersManualPdfs: ImmutablePropTypes.list,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    UserActions: PropTypes.shape({
      addFavorite: PropTypes.func.isRequired,
      removeFavorite: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired,
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool
    }).isRequired
  }

  render() {
    const { topics, currentYear, currentBrand, currentModel, userMedia, match, favorites, ownersManualPdfs } = this.props

    const filteredTopics = sortItemsByTitle(filterTopicsByField(topics, favorites, 'resourceKey'))

    return (
      <div className="Topics">
        <ScreenHead ownersManualPdfs={ownersManualPdfs}><em>{currentYear} {currentBrand}</em><span>{currentModel}</span></ScreenHead>

        <Media type="mobile">
          <div className="topics-subtitle">{MESSAGE.FAVORITES}</div>
        </Media>

        <div className="screen-content">
          <div className="screen-container">
            <div className="topics">
              {filteredTopics.size === 0 && <div className="topics-subtitle">{MESSAGE.EMPTY_FAVORITES}</div>}
              <TopicList
                className={userMedia.isDesktop ? 'favorites-topic-list' : ''}
                topics={filteredTopics.toJS()}
                baseRoute={match.url}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ contents, vehicle, user, user: {favorites} }) {
  const { topics, folders } = contents
  const { currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype } = vehicle
  const { media:userMedia } = user

  favorites = favorites.getIn([currentBrand, currentYear, currentModel, currentProductType, currentProductSubtype].filter(path => path))

  return {
    topics: mergeTopicsWithCollapsedFolders(topics, folders),
    currentYear,
    currentModel,
    currentBrand,
    favorites,
    userMedia
  }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites)
