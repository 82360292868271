import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import '../styles/StickyBar.scss'

export default class StickyBar extends Component {

  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {offsetTop: 0}
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll() {
    const stickyBarTopPos = this.stickyBar.getBoundingClientRect().top
    if (Math.abs(stickyBarTopPos) <  this.state.offsetTop || stickyBarTopPos < 0) {
      this.stickyBarInner.classList.add('sticky')
    } else {
      this.stickyBarInner.classList.remove('sticky')
    }
  }

  componentDidMount() {
    //calculate top offset
    const stickyBarInnerStyle = window.getComputedStyle(this.stickyBarInner)
    this.setState({offsetTop: parseInt(stickyBarInnerStyle.getPropertyValue('top'), 10) || 0})

    document.addEventListener('scroll', this.handleScroll)
    this.handleScroll() //handle current scroll position
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const { children } = this.props
    if (!children) {
      return null
    }

    return (
      <div ref={node => this.stickyBar = node} className={classNames('StickyBar', this.props.className)}>
        <div ref={node => this.stickyBarInner = node} className='sticky-bar-inner sticky'>
          {children}
        </div>
      </div>
    )
  }
}
