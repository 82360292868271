import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getVehicleForBrand, getModelUrl, getVehiclesYears, getPathAlias } from 'tw-oi-core/utils/vehicle'
import { groupedVehiclesByYearAndProductType, isLegacyYear } from '../extras/utils'
import {
  setVehicleProgram,
  getVehicleProgram,
  trackChangeVehicleClick,
  setYearDimension,
  setTypeSubType,
  setBrand
} from 'tw-oi-core/services/analytics'

import ScreenHead from '../components/ScreenHead'
import { ROUTE, MESSAGE, UI, PRODUCT_MAP, PRODUCT_SUBTYPE_MAP } from '../config'

import * as VehicleActions from 'tw-oi-core/actions/VehicleActions'
import VehicleYearSelector from '../components/VehicleYearSelector'
import VehicleList from "../components/VehicleList"
import Loader from "../components/Loader"
import ErrorMessage from "../components/ErrorMessage"

import '../styles/Vehicles.scss'
import * as ContentsActions from 'tw-oi-core/actions/ContentsActions'
import _ from 'lodash'
import StickyBar from '../components/StickyBar'
import Media from '../components/Media'


class Vehicles extends Component {

  static propTypes = {
    vehicle: PropTypes.shape({
      currentModel: PropTypes.string,
      currentYear: PropTypes.string,
      currentProductType: PropTypes.string,
      currentBrand: PropTypes.string,
      vehicles: PropTypes.array,
      fetching: PropTypes.bool,
      error: PropTypes.object,
    }).isRequired,
    VehicleActions: PropTypes.shape({
      getVehicles: PropTypes.func.isRequired,
      setVehicleProductType: PropTypes.func.isRequired,
      setVehicleYear: PropTypes.func.isRequired,
      setVehicleModel: PropTypes.func.isRequired,
    }).isRequired,
    ContentsActions: PropTypes.shape({
      getHeroShot: PropTypes.func.isRequired,
    }).isRequired,
    heroShotMap: PropTypes.object,
    location: PropTypes.shape({
      state: PropTypes.object,
      pathname: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        year: PropTypes.string,
        group: PropTypes.string,
      }).isRequired
    }).isRequired,
    isDesktop: PropTypes.bool,
    baseRoute: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      models: [],
      groups: [],
      years: [],
      vehiclesReady: false,
      vehiclesMap: {}
    }
  }

  componentDidMount() {
    const { getVehicles } = this.props.VehicleActions
    const { vehicles } = this.props.vehicle

    if (_.isEmpty(vehicles)) {
      getVehicles().then(()  => {
        this.updateVehiclesList()
      })
    } else {
      this.updateVehiclesList()
    }
  }

  componentDidUpdate(prevProps) {
    const { match: {params: { year, group }}} = this.props
    const { match: {params: { year: prevYear, group: prevGroup }}} = prevProps
    const { currentBrand } = this.props.vehicle
    const { currentBrand: prevCurrentBrand } = prevProps.vehicle
    const { vehiclesReady } = this.state

    if (!vehiclesReady) {
      return
    }

    if (currentBrand !== prevCurrentBrand || prevYear !== year || prevGroup !== group ) {
      this.updateVehiclesList()
    }
  }

  /**
   * Resolve product type
   *
   * @param {String} group
   * @returns {String}
   */
  resolveProductType(group) {
    return _.isEmpty(group) ? null : _.toUpper(group)
  }

  updateVehiclesList() {
    const { match: { params: { year, group }}, history, baseRoute, VehicleActions: { setVehicleProductType, setVehicleYear } } = this.props
    const { vehicles, currentBrand, currentYear, currentProductType } = this.props.vehicle
    const newProductType = this.resolveProductType(group)

    const brandVehicles = getVehicleForBrand(vehicles, currentBrand)
    const vehiclesMap = groupedVehiclesByYearAndProductType(brandVehicles)
    const groups = Object.keys(vehiclesMap[year] || {}).sort()
    const years = getVehiclesYears(brandVehicles).filter(y => y)
    const models = ((newProductType && vehiclesMap[year] && vehiclesMap[year][newProductType]) || [])
      .sort((a, b) => a.model.localeCompare(b.model))

    if (year !== currentYear) {
      setVehicleYear(year)
    }
    if (newProductType !== currentProductType) {
      setVehicleProductType(newProductType, groups)
    }

    this.setState({models, groups, vehiclesMap, years, vehiclesReady: true})

    // redirect directly to the highest year if other not provided
    if (!year && !_.isEmpty(years)) {
      history.replace(`${baseRoute}${ROUTE.VEHICLES}/${years[0]}`)
    }
    // redirect to year home if there is no such group
    if (newProductType && groups.indexOf(newProductType) === -1) {
      history.replace(`${baseRoute}${ROUTE.VEHICLES}/${year}`)
    }
    // redirect directly to vehicles list if there is only one group
    if (!isLegacyYear(year) && year && groups && groups.length === 1) {
      history.replace(`${baseRoute}${ROUTE.VEHICLES}/${year}/${getPathAlias(groups[0])}`)
    }
    // redirect directly to model if this is legacy year (<=2017) and there's only one model inside
    if (isLegacyYear(year) && models && models.length === 1 && models[0].year === year && !models[0].FOREST_RIVER_PRODUCT_SUB_TYPE) {
      this.updateAnalyticsContext(models[0])

      history.replace(`${baseRoute}${ROUTE.GUIDE}${getModelUrl(year,`${models[0].id} ${models[0].model}`)}${ROUTE.BROWSE}`)
    }
  }

  /**
   * Updates analytics dimension to reflect the selected model
   *
   * @param {
   *    {
   *      model: String,
   *      FOREST_RIVER_PRODUCT_TYPE: String,
   *      FOREST_RIVER_PRODUCT_SUB_TYPE: String
   *    }
   *  } vehicle - object containing vehicle info
   */
  updateAnalyticsContext(vehicle) {
    const { currentYear, currentBrand } = this.props.vehicle
    const { model, FOREST_RIVER_PRODUCT_TYPE, FOREST_RIVER_PRODUCT_SUB_TYPE } = vehicle

    // update analytics context
    const vehicleProgramStringBefore = getVehicleProgram()
    const vehicleProgramStringAfter = `${currentYear} ${currentBrand} ${model}`
    setVehicleProgram(currentYear, currentBrand, model)
    setYearDimension(currentYear)
    setBrand(currentBrand)

    const selectedProductType = PRODUCT_MAP[FOREST_RIVER_PRODUCT_TYPE] ? PRODUCT_MAP[FOREST_RIVER_PRODUCT_TYPE].title : FOREST_RIVER_PRODUCT_TYPE
    const selectedProductSubtype = PRODUCT_SUBTYPE_MAP[FOREST_RIVER_PRODUCT_SUB_TYPE] || FOREST_RIVER_PRODUCT_SUB_TYPE
    setTypeSubType(selectedProductType, selectedProductSubtype)

    trackChangeVehicleClick(vehicleProgramStringBefore, vehicleProgramStringAfter)
  }

  render() {
    const { baseRoute, isDesktop, heroShotMap } = this.props
    const { currentYear, currentBrand, currentModel, currentProductType, error } = this.props.vehicle
    const { setVehicleYear } = this.props.VehicleActions
    const { getHeroShot } = this.props.ContentsActions
    const { vehiclesReady, years, groups, models } = this.state

    if (error) {
      return <ErrorMessage retryAction={() => this.updateVehiclesList()}/>
    }

    if (!vehiclesReady) {
      return <Loader className="inverse"/>
    }

    return (
      <div className="Vehicles">
        <ScreenHead title={UI.VEHICLES_SCREEN_HEAD_TITLE}/>
        <Media type="desktop">
          <StickyBar className="sticky-vehicle-year-selector" >
            <VehicleYearSelector
              currentYear={currentYear}
              years={years}
              onSelected={setVehicleYear}
              slider={!isDesktop && years.length > 4}
              baseRoute={baseRoute}
            />
          </StickyBar>
        </Media>
        <Media type="mobile">
          <VehicleYearSelector
            currentYear={currentYear}
            years={years}
            onSelected={setVehicleYear}
            slider={!isDesktop && years.length > 4}
            baseRoute={baseRoute}
          />
        </Media>
        {!_.isEmpty(groups) || !_.isEmpty(models) ?
          <VehicleList
            isDesktop={isDesktop}
            currentYear={currentYear}
            productType={currentProductType}
            currentModel={currentModel}
            currentBrand={currentBrand}
            vehicles={models}
            groups={groups}
            heroShotMap={heroShotMap}
            getHeroShot={getHeroShot}
            setVehicleModel={(model) => this.updateAnalyticsContext(model)}
            baseRoute={baseRoute}
          /> : <Loader type="status" className={isDesktop ? 'inverse' : ''}>{MESSAGE.EMPTY_VEHICLES}</Loader>}
      </div>
    )
  }
}

function mapStateToProps({vehicle, contents, user: {media}}) {
  return {vehicle, isDesktop: media.isDesktop, heroShotMap: contents.heroShotMap}
}

function mapDispatchToProps(dispatch) {
  return {
    VehicleActions: bindActionCreators(VehicleActions, dispatch),
    ContentsActions: bindActionCreators(ContentsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles)
