/*eslint no-restricted-globals: 0*/
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { MESSAGE, ROUTE, UI, CONTENT_TYPE } from '../config'
import { getPublicationsForVehicle, sortContentsByTitle } from '../extras/utils'
import { trackVideosFolderClick } from 'tw-oi-core/services/analytics'

import { connect } from 'react-redux'
import ScreenHead from '../components/ScreenHead'
import ScreenContainer from '../components/ScreenContainer'
import ContentList from '../components/ContentList'
import BreadCrumb from '../components/BreadCrumb'
import StickyBar from '../components/StickyBar'
import Media from '../components/Media'

import '../styles/Topics.scss'
import { filteredContents } from './BrandSpecificInformation/BrandSpecificInformation'

const Videos = ({ items, currentYear, currentModel, currentBrand, match, baseRoute }) => {
  // videos are manually placed in fake folders (tags) to implement some sort of navigation
  const [videos, folders, breadcrumbs] = useMemo(() => {
    const breadcrumbs = [
      { title: 'Browse', link: `${baseRoute}${ROUTE.BROWSE}` },
      { title: 'Videos', link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.VIDEOS}` },
    ]

    if (match.params.pseudoFolderName) {
      let videosWithinTag = items
        .filter(video => video.tags && video.tags.includes(match.params.pseudoFolderName))
        .map(video => ({
          ...video,
          link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.VIDEOS}/${match.params.pseudoFolderName}${ROUTE.CONTENT}/${video.resourceKey}`,
        }))
      breadcrumbs.push({ title: match.params.pseudoFolderName, link: '' })
      return [sortContentsByTitle(videosWithinTag), [], breadcrumbs]
    }

    let videosWitoutTags = [...items]
      .filter(vid => !vid.tags || !vid.tags.length)
      .map(vid => ({ ...vid, link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.VIDEOS}${ROUTE.CONTENT}/${vid.resourceKey}` }))
      .sort((a, b) => a.title.localeCompare(b.title))

    const folders = [...new Set(items.reduce((tags, vid) => tags.concat(vid.tags || []), []))].sort().map(tagTitle => ({
      title: tagTitle,
      type: CONTENT_TYPE.FOLDER,
      resourceKey: tagTitle,
      link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.VIDEOS}/${tagTitle}`,
    }))

    return [videosWitoutTags, folders, breadcrumbs]
  }, [items])

  return (
    <div className='Topics'>
      <ScreenHead
        back={
          match.params.pseudoFolderName ? `${baseRoute}${ROUTE.BROWSE}${ROUTE.VIDEOS}` : `${baseRoute}${ROUTE.BROWSE}`
        }
        backTitle={match.params.pseudoFolderName ? 'Videos' : UI.BROWSE_TITLE}
      >
        <em>
          {currentYear} {currentBrand}
        </em>
        <span>{currentModel}</span>
      </ScreenHead>

      <Media type='mobile'>
        <div className='topics-subtitle'>{match.params.pseudoFolderName || 'Videos'}</div>
      </Media>

      <div className='screen-content'>
        <Media type='desktop'>
          <StickyBar className='sticky-topics-bar'>
            <div className='topics-bar'>
              <BreadCrumb items={breadcrumbs} />
            </div>
          </StickyBar>
        </Media>

        <ScreenContainer currentUrl={match.url}>
          <div className='topics'>
            {folders.length ? (
              <ContentList
                topics={folders}
                emptyText={MESSAGE.EMPTY_TOPICS}
                baseRoute={match.url}
                className='VideoFolders'
                onClick={item => trackVideosFolderClick(item.title)}
              />
            ) : null}

            {videos.length ? (
              <ContentList topics={videos} emptyText={MESSAGE.EMPTY_TOPICS} baseRoute={match.url} className='Videos' />
            ) : null}
          </div>
        </ScreenContainer>
      </div>
    </div>
  )
}

Videos.propTypes = {
  items: PropTypes.array,
  currentYear: PropTypes.string,
  currentModel: PropTypes.string,
  currentBrand: PropTypes.string,
  match: PropTypes.object.isRequired,
  baseRoute: PropTypes.string,
}

function mapStateToProps({ contents, vehicle }) {
  const { currentYear, currentModel, currentBrand } = vehicle
  const processedContents = filteredContents(contents)

  const contentVideos = getPublicationsForVehicle(processedContents.videos)
  const items = sortContentsByTitle(contentVideos)
  return { items, currentYear, currentModel, currentBrand }
}

export default connect(mapStateToProps)(Videos)
