import React from 'react'

export function motorhomes() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373 125" style={{enableBackground: "new 0 0 373 125"}} xmlSpace="preserver">
      <g className="fill">
        <path d="M334 61h-61V18h-37v43H0V12C0 5.373 5.373 0 12 0h289.96a58 58 0 0 1 48.258 25.827l19.087 28.63A21.999 21.999 0 0 1 372.26 61h-7.554l-19.702-30.434A10 10 0 0 0 336.61 26H334v35zm-61 3h99.838a22 22 0 0 1 .162 2.661V106a2 2 0 0 1-2 2h-28.104c-1-9.553-9.078-17-18.896-17-9.818 0-17.897 7.447-18.896 17H122.896c-1-9.553-9.078-17-18.896-17-9.818 0-17.897 7.447-18.896 17H12c-6.627 0-12-5.373-12-12V64h236v28h37V64zm-37 34v3h37v-3h-37zM93 39v13h22V39H93zm0-19v13h22V20H93zm-70 6v26h46V26H23zm257 0v26h46V26h-46z"/>
        <path d="M104 125c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-8a7 7 0 1 0 0-14 7 7 0 0 0 0 14zM324 125c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-8a7 7 0 1 0 0-14 7 7 0 0 0 0 14z"/>
      </g>
    </svg>
  )
}
