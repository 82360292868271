import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isAndroid } from 'tw-oi-core/utils/userAgent'

import { ROUTE, UI } from '../config'

import "../styles/Promo.scss"

class Promo extends Component {

  static propTypes = {
    promoSkipped: PropTypes.bool,
    UserActions: PropTypes.shape({
      skipPromo: PropTypes.func.isRequired
    }),
    currentBrand: PropTypes.string.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        referrer: PropTypes.string
      })
    }),
    match: PropTypes.object.isRequired,
  }

  onSkipClick() {
    const { skipPromo } = this.props.UserActions
    skipPromo()
  }

  render() {
    const {match: {params: {brandKey}}, location} = this.props

    return (
      <div className="Promo">
        <div className="container">
          <div className="overlay"/>
          <div className="head">
            <div className="promo-logo"/>
          </div>

          <div className="text">
            <h1>We have a new <br/> mobile app!</h1>
            <div>
              {UI.PROMO_MSG}
            </div>
          </div>

          <div className="navigation">
            <div className="store-badge">
              <a href={isAndroid(navigator.userAgent) ? UI.URL_PLAYMARKET : UI.URL_APPSTORE}
                className={classNames('download', isAndroid(navigator.userAgent) ? 'android' : 'ios')}>&nbsp;</a>
            </div>
            <div className="browse">
              <Link onClick={this.onSkipClick.bind(this)} to={(location && location.state && location.state.referrer) || `/${brandKey}${ROUTE.INDEX}`}>Browse web application</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({vehicle}) {
  return {
    currentBrand: vehicle.currentBrand,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Promo)
