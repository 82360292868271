import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Main from './Main'
import ErrorMessage from '../components/ErrorMessage'

import { BRANDS, MESSAGE, ROUTE } from '../config'
import { getConfig } from 'tw-oi-core'

export default class ThemeRoute extends Component {

  static propTypes = {
    currentBrand: PropTypes.string,
    setVehicleBrand: PropTypes.func.isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        brandKey: PropTypes.string,
      }).isRequired
    }).isRequired,
  }

  updateCurrentBrand(brandKey) {
    const { DEFAULT_BRAND } = getConfig()
    const { setVehicleBrand, currentBrand, history } = this.props
    const brandsRouteKey = ROUTE.EDITOR.replace(/^\//, '')

    const brand = BRANDS.find(brand => (brandKey && brandKey !== brandsRouteKey) ? brand.key === brandKey : brand.name === DEFAULT_BRAND)

    if (brand) {
      if (!brandKey) {
        history.replace(`/${brand.key}`)
        return
      } else if (brandKey === brandsRouteKey) {
        history.replace(`/${brand.key}${ROUTE.EDITOR}`)
        return
      }
      if (brand.name !== currentBrand) {
        setVehicleBrand(brand.name)
      }
    }
  }

  componentDidMount() {
    const { match: {params: { brandKey }} } = this.props

    this.updateCurrentBrand(brandKey)
  }

  componentDidUpdate(prevProps) {
    const { match: {params: { brandKey }}  } = this.props
    const { match: {params: { brandKey: prevBrandKey }} } = prevProps

    if (brandKey !== prevBrandKey) {
      this.updateCurrentBrand(brandKey)
    }
  }

  render() {
    const brand = BRANDS.find(brand => brand.key === this.props.match.params.brandKey)

    if (!brand) {
      return <ErrorMessage className="inverse" title={MESSAGE.ERROR_NOT_FOUND} retryAction={`/`} retryTitle="Visit Home Page" message={MESSAGE.ERROR_NOT_FOUND_MESSAGE}/>
    }

    return <Main {...this.props}/>
  }
}
