import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import api from 'tw-oi-core/services/ContentDelivery'

import Rating from './Rating'
import FeedbackInput from './FeedbackInput'
import RadioInput from './RadioInput'

import './FeedbackModal.scss'

const FeedbackModal = ({ onClose, currentBrand, currentModel, currentYear }) => {
  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm()

  return (
    <div className='FeedbackModal'>
      <form
        className='FeedbackModal__content'
        onSubmit={handleSubmit(async(data) => {
          try {
            await api.postFeedback({
              ...data,
              informationFound: JSON.parse(data.informationFound),
              brand: currentBrand || '',
              model: currentModel || '',
              year: currentYear || '',
              source: 'Web'
            })
            onClose()
          } catch (err) {
            console.log('Error submitting feedback form', err)
          }
        })}
      >
        <header className='FeedbackModal__header'>
          <div className='FeedbackModal__header-title'>
            SEND FEEDBACK
          </div>
        </header>
        <div className='FeedbackModal__body'>
          <div className='FeedbackModal__body-message'>
            How would you rate us?
          </div>

          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => {
              return <Rating value={value} onChange={onChange} />
            }}
            name="rating"
          />

          <div className='FeedbackModal__found-answer'>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <RadioInput
                    value={value}
                    label='Did you find what you are looking for?'
                    options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
                    name="informationFound"
                    onChange={ev => onChange(ev.currentTarget.value)}
                  />
                )
              }}
              name="informationFound"
              rules={{ required: true }}
            />
          </div>

          <div className='FeedbackModal__form'>
            <Controller
              render={({
                field: { ref, ...rest },
              }) => {
                return (
                  <FeedbackInput
                    {...rest}
                    innerRef={ref}
                    placeholder="What can we do better?"
                  />
                )
              }}
              name="suggestions"
              control={control}
              rules={{ required: true }}
            />
            <Controller
              render={({
                field: { ref, ...rest },
              }) => {
                return (
                  <FeedbackInput
                    optional
                    {...rest}
                    innerRef={ref}
                    placeholder="Name"
                  />
                )
              }}
              name="name"
              control={control}
            />
            <Controller
              render={({
                field: { ref, ...rest },
              }) => {
                return (
                  <FeedbackInput
                    optional
                    {...rest}
                    innerRef={ref}
                    placeholder="Email"
                  />
                )
              }}
              name="email"
              control={control}
            />
          </div>
        </div>

        <footer className='FeedbackModal__footer'>
          <button className='FeedbackModal__action-btn' onClick={onClose}>Cancel</button>
          <button className='FeedbackModal__action-btn' type="submit" disabled={!isValid}>Send</button>
        </footer>
      </form>
    </div>
  )
}

FeedbackModal.propTypes = {
  onClose: PropTypes.func,
  currentBrand: PropTypes.string,
  currentModel: PropTypes.string,
  currentYear: PropTypes.string,
}

export default FeedbackModal
