import React from 'react'

export function attachment() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{enableBackground:"new 0 0 24 24"}} xmlSpace="preserve">
      <g strokeWidth="1" fillRule="evenodd">
        <path className="fill" d="M18.8850712,9.06343557 L17.455836,10.4764831 L18.8850712,9.06343557 Z M18.5283568,12.2437718 L17.6345894,13.1274162 L19.0646173,14.5412472 L20.8527821,12.7733355 L13.7007529,19.8443593 C10.9372099,22.5766018 6.45666508,22.5766449 3.69181837,19.8431135 C0.927579451,17.1101829 0.930134998,12.6767004 3.69055826,9.94754225 L10.8425874,2.87651851 L10.8422724,2.87682996 L12.2688044,1.46645514 C14.2450175,-0.48737489 17.4472337,-0.489212043 19.4221242,1.46331042 C21.3965806,3.41540367 21.3988425,6.57813586 19.4189435,8.53561014 L11.5570612,16.308447 C10.3723377,17.479752 8.44915111,17.4774086 7.26688786,16.3085359 C6.08221407,15.13728 6.08254945,13.2379673 7.26697781,12.066954 L15.1320096,4.29100322 L13.7020236,5.70479287 L15.1320515,7.11862386 L8.69700563,13.480785 C8.30221539,13.8711037 8.30217155,14.5044282 8.69691745,14.8947032 C9.09046597,15.2837943 9.73316084,15.2840275 10.1270335,14.894616 L17.9889156,7.12177915 C19.1778159,5.94634461 19.1779862,4.04959959 17.9920964,2.87714142 C16.8074501,1.70591267 14.8857242,1.70683718 13.6988322,2.88028615 L5.12058608,11.3613732 C3.14881066,13.3108159 3.1485243,16.4783108 5.12184616,18.4292824 C7.09675414,20.3818222 10.2968085,20.3820878 12.2707251,18.4305283 L18.5283568,12.2437718 Z M13.702013,5.70480342 L11.5569713,7.82554993 L12.9869991,9.23938091 L15.1320408,7.11863441 L13.702013,5.70480342 Z M11.9144782,7.47209217 L13.344506,8.88592315 L12.9869991,9.23938091 L11.5569713,7.82554993 L11.9144782,7.47209217 Z M12.9852539,6.41344432 C13.381109,6.02207277 14.0193825,6.01857836 14.4170269,6.41171894 C14.8119182,6.80213759 14.8124654,7.43459021 14.4152817,7.82727531 L13.344506,8.88592315 L11.9144782,7.47209217 L12.9852539,6.41344432 Z M18.7053651,12.0687683 C19.1012202,11.6773968 19.7394937,11.6739024 20.1371381,12.0670429 C20.5320294,12.4574616 20.5325766,13.0899142 20.1353929,13.4825993 L19.0646173,14.5412472 L17.6345894,13.1274162 L18.7053651,12.0687683 Z"/>
      </g>
    </svg>
  )
}
