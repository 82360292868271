import React, { Component } from 'react'
import classNames from 'classnames'
import '../styles/Toast.scss'
import { UI } from '../config'

export default class Toast extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showToast: false,
      fadeoutAnimation: false,
    }

    this.toastMessage = ''
    this.timerID = null
  }

  componentWillUnmount() {
    this.timerID && clearTimeout(this.timerID)
  }

  show(message, duration = 2000) {
    this.timerID && clearTimeout(this.timerID)
    this.toastMessage = message

    this.setState({showToast: true, fadeoutAnimation: false})
    this.timerID = setTimeout(() => {
      this.setState({fadeoutAnimation: true})
      this.timerID = setTimeout(() => this.setState({fadeoutAnimation: false, showToast: false}), UI.TOAST_FADEOUT_TIME)
    }, duration)
  }

  render() {
    return <div className={classNames('ToastContainer',this.state.showToast && 'show', this.state.fadeoutAnimation && 'fadeout')}>
      <div className={'toast-message-container'}>
        {this.toastMessage}
      </div>
    </div>
  }
}
