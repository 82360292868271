import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Document, Page, pdfjs } from 'react-pdf'

import Loader from './Loader'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
}

const PdfRenderer = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return <Document
    file={pdfUrl}
    onLoadSuccess={onDocumentLoadSuccess}
    options={options}
    loading={<Loader className="inverse"/>}
  >
    {Array.from(
      new Array(numPages),
      (el, index) => <Page
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        width={1024}
      />
    )}
  </Document>
}

PdfRenderer.propTypes = {
  pdfUrl: PropTypes.string.isRequired
}

export default connect()(PdfRenderer)
