import React from 'react'

export function listDownloadPdf() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 18" style={{enableBackground: "new 0 0 13 18"}} xmlSpace="preserve">
      <g fill="none" stroke="none" strokeWidth="1">
        <g className="fill">
          <g>
            <path d="M4.03735352,1.42108547e-14 L4.03735352,4.01391602 L0,4.01391602 L4.03735352,1.42108547e-14 Z M5.0402832,0.00256347656 L5.0402832,4.6126709 C5.0402832,4.85812544 4.8777832,5.02176181 4.6340332,5.02176181 L0.00524902344,5.02176181 L0.00524902344,17.1843817 C0.00524902344,17.6343817 0.370874023,18.0025635 0.817749023,18.0025635 L12.192749,18.0025635 C12.639624,18.0025635 13.005249,17.6343817 13.005249,17.1843817 L13.005249,0.820745295 C13.005249,0.370745295 12.639624,0.00256347656 12.192749,0.00256347656 L5.0402832,0.00256347656 Z M3.00524902,14.0025635 L10.005249,14.0025635 L10.005249,13.5025635 C10.005249,13.2264211 10.2291066,13.0025635 10.505249,13.0025635 C10.7813914,13.0025635 11.005249,13.2264211 11.005249,13.5025635 L11.005249,14.5025635 C11.005249,14.7787059 10.7813914,15.0025635 10.505249,15.0025635 L2.50524902,15.0025635 C2.22910665,15.0025635 2.00524902,14.7787059 2.00524902,14.5025635 L2.00524902,13.5025635 C2.00524902,13.2264211 2.22910665,13.0025635 2.50524902,13.0025635 C2.7813914,13.0025635 3.00524902,13.2264211 3.00524902,13.5025635 L3.00524902,14.0025635 Z M9.36507161,10.3168467 L6.85293579,12.8645935 C6.75397746,12.9642439 6.65501912,13.014069 6.50658162,13.014069 C6.35814412,13.014069 6.25918579,12.9642439 6.16022746,12.8645935 L3.72444661,10.3168467 C3.52652995,10.117546 3.52652995,9.81859498 3.72444661,9.61929428 C3.92236328,9.41999358 4.21923828,9.41999358 4.41715495,9.61929428 L6.00524902,11.263525 L6.00524902,5.98205652 C6.00524902,5.68310547 6.20648193,5.48599243 6.50335693,5.48599243 C6.80023193,5.48599243 7.00524902,5.68310547 7.00524902,5.98205652 L7.00524902,11.263525 L8.67236328,9.61929428 C8.87027995,9.41999358 9.16715495,9.41999358 9.36507161,9.61929428 C9.56298828,9.81859498 9.56298828,10.117546 9.36507161,10.3168467 Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
