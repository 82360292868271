import React from 'react'

export function success() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" style={{enableBackground: "new 0 0 28 28"}} xmlSpace="preserver">
      <g fill="none" opacity="0.508816964" stroke="none" strokeWidth="1">
        <g stroke="#FFFFFF" strokeWidth="2" transform="translate(-415.000000, -716.000000)">
          <g transform="translate(415.000000, 716.000000)">
            <circle cx="14" cy="14" r="13"/>
            <polyline points="8 13.8629876 12.1280765 17.9910641 20.1191406 10"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
