import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { MESSAGE, ROUTE, UI } from '../../config'
import { isArticle, isVideo } from '../../extras/utils'
import Media from '../../components/Media'
import ScreenHead from '../../components/ScreenHead'
import StickyBar from '../../components/StickyBar'
import BreadCrumb from '../../components/BreadCrumb'
import ScreenContainer from '../../components/ScreenContainer'
import ContentList from '../../components/ContentList'

export const BRAND_SPECIFIC_TAG = 'Brand Specific Information'

const isBrandSpecificContent = item => item.tags && item.tags.includes(BRAND_SPECIFIC_TAG)

// filter out brand specific content from all contents
export const filteredContents = contents => {
  const publiations = contents.publications.toJS()

  const brandSpecificContents = [...publiations, ...contents.videos].filter(isBrandSpecificContent)
  const filteredPubs = publiations.filter(contentItem => !isBrandSpecificContent(contentItem))
  const filteredVideos = contents.videos.filter(contentItem => !isBrandSpecificContent(contentItem))

  return {
    ...contents,
    publications: filteredPubs,
    videos: filteredVideos,
    brandSpecificContents: brandSpecificContents,
  }
}

// This componenent renders brand specific content
const BrandSpecificInformation = ({ baseRoute, contents, currentYear, currentModel, currentBrand, match }) => {
  const linkedContent = useMemo(() => {
    return contents.map(item => {
      const contentTypeRoute = isArticle(item) ? ROUTE.ARTICLES : isVideo(item) ? ROUTE.VIDEOS : '/publication'

      return {
        ...item,
        link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.BRAND_SPECIFIC}${contentTypeRoute}/${item.resourceKey}`,
      }
    })
  }, [contents])

  return (
    <div className='Topics'>
      <ScreenHead back={`${baseRoute}${ROUTE.BROWSE}`} backTitle={UI.BROWSE_TITLE}>
        <em>
          {currentYear} {currentBrand}
        </em>
        <span>{currentModel}</span>
      </ScreenHead>

      <Media type='mobile'>
        <div className='topics-subtitle'>Brand Specific Information</div>
      </Media>

      <div className='screen-content'>
        <Media type='desktop'>
          <StickyBar className='sticky-topics-bar'>
            <div className='topics-bar'>
              <BreadCrumb
                items={[
                  { title: 'Browse', link: `${baseRoute}${ROUTE.BROWSE}` },
                  {
                    title: 'Brand Specific Information',
                    link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.BRAND_SPECIFIC}`,
                  },
                ]}
              />
            </div>
          </StickyBar>
        </Media>

        <ScreenContainer currentUrl={match.url}>
          <div className='topics'>
            {linkedContent.length ? (
              <ContentList topics={linkedContent} emptyText={MESSAGE.EMPTY_TOPICS} baseRoute={match.url} />
            ) : null}
          </div>
        </ScreenContainer>
      </div>
    </div>
  )
}

BrandSpecificInformation.propTypes = {
  contents: PropTypes.array,
  currentYear: PropTypes.string,
  currentModel: PropTypes.string,
  currentBrand: PropTypes.string,
  match: PropTypes.object,
  baseRoute: PropTypes.object,
}

function mapStateToProps({ contents, vehicle }) {
  const { fetching, currentAsset, fetchingAsset } = contents
  const { currentYear, currentModel, currentBrand } = vehicle
  const { brandSpecificContents } = filteredContents(contents)

  return {
    contents: brandSpecificContents,
    fetching,
    currentAsset,
    fetchingAsset,
    currentYear,
    currentModel,
    currentBrand,
  }
}

export default connect(mapStateToProps)(BrandSpecificInformation)
