import React, { Component, createRef } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { trackContentView, trackContentViewArticle, trackContentViewByType } from 'tw-oi-core/services/analytics'

import {
  getPublicationsForVehicle,
  getArticlesWithTags,
  getTopicForArticlePublication,
  updateUrlsPlaceholders,
  updatePhoneNumberToClickable,
  getStartingBreadCrumb,
} from '../extras/utils'

import { connect } from 'react-redux'
import ScreenHead from '../components/ScreenHead'

import '../styles/Topics.scss'
import { trackTextView } from 'tw-oi-core/services/analytics'
import classNames from 'classnames'
import Media from '../components/Media'
import Toast from '../components/Toast'
import { bindActionCreators } from 'redux'
import * as ContentsActions from 'tw-oi-core/actions/ContentsActions'
import * as UserActions from 'tw-oi-core/actions/UserActions'
import StickyBar from '../components/StickyBar'
import BreadCrumb from '../components/BreadCrumb'
import { CONTENT_TYPE, ROUTE } from '../config'
import Loader from '../components/Loader'
import api from 'tw-oi-core/services/ContentDelivery'

import '../styles/ArticleTopic.scss'
import { BRAND_SPECIFIC_TAG } from './BrandSpecificInformation'

class ArticleViewer extends Component {
  static propTypes = {
    publications: PropTypes.array,
    topics: PropTypes.array,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    currentProductType: PropTypes.string,
    currentProductSubtype: PropTypes.string,
    baseRoute: PropTypes.string,
    ContentsActions: PropTypes.shape({
      getAsset: PropTypes.func.isRequired,
    }).isRequired,
    UserActions: PropTypes.shape({
      addRecent: PropTypes.func.isRequired,
      removeFavorite: PropTypes.func.isRequired,
      addFavorite: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        resourceKey: PropTypes.string.isRequired,
        referrer: PropTypes.string.isRequired,
        tag: PropTypes.string,
        brandSpecific: PropTypes.string,
      }).isRequired,
    }).isRequired,
    history: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.state = {
      currentTopic: null,
      html: null,
    }

    this.htmlContentRef = createRef()
  }

  componentDidMount() {
    this.loadTopic(this.props.match.params.resourceKey)
  }

  componentDidUpdate(prevProps) {
    const { resourceKey: curResourceKey } = this.props.match.params
    const { resourceKey: prevResourceKey } = prevProps.match.params

    if (prevResourceKey !== curResourceKey) {
      this.loadTopic(curResourceKey)
    }
  }

  buildBreadcrumbs() {
    const { match, baseRoute } = this.props

    const breadcrumbs = [
      { title: getStartingBreadCrumb(match.params.referrer), link: `${baseRoute}${match.params.referrer}` },
    ]

    if (match.params.tag) {
      breadcrumbs.push({
        title: match.params.tag,
        link: `${baseRoute}${match.params.referrer}${ROUTE.ARTICLES}/${match.params.tag}`,
      })
    }

    if (match.params.brandSpecific) {
      breadcrumbs.push({
        title: BRAND_SPECIFIC_TAG,
        link: `${baseRoute}${match.params.referrer}${ROUTE.BRAND_SPECIFIC}`,
      })
    }

    breadcrumbs.push({ title: this.state.currentTopic?.title, link: '' })

    return breadcrumbs
  }

  /**
   * Loads topic data for provided resource key
   *
   * @param {String} pubResourceKey
   */
  loadTopic(pubResourceKey) {
    const { addRecent } = this.props.UserActions
    const { topics, publications, currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype } =
      this.props

    const pub = publications.find(item => item.resourceKey === pubResourceKey)
    const topic = getTopicForArticlePublication(pub, topics.toJS())

    // topic not found
    if (!topic) return

    this.setState(
      {
        fetchingAsset: false,
        currentTopic: topic,
      },
      () => this.updateAsset(topic)
    )

    trackTextView(topic.title)
    trackContentView(topic.title)
    trackContentViewArticle(topic.title)
    trackContentViewByType(topic.title, CONTENT_TYPE.ARTICLE)
    addRecent(currentYear, currentModel, pub.resourceKey, currentBrand, currentProductType, currentProductSubtype)
  }

  /**
   * Loads HTML asset for the specified topic
   *
   * @param {object} topic
   */
  updateAsset = async topic => {
    // load HTML into local state
    if (!this.state.html) {
      this.setState({
        loading: true,
      })
      try {
        const res = await api.getHtmlAsset(topic.bodyHtml.objectKey)

        this.setState(
          {
            html: res.data,
          },
          () => setTimeout(() => this.adjustHtmlLinks(), 1000) // on HTML is rendered to DOM
        )
      } catch (e) {
        this.setState({
          error: 'Failed to load article',
        })
      } finally {
        this.setState({
          loading: false,
        })
      }
    }
  }

  renderTopicContent = () => {
    if (this.state.loading) {
      return <Loader className='inverse' />
    }

    if (!this.state.html) {
      return null
    }

    return (
      <div className='topic-text-container'>
        <div
          className='article-topic-content'
          ref={this.htmlContentRef}
          dangerouslySetInnerHTML={{
            __html: updateUrlsPlaceholders(updatePhoneNumberToClickable(this.state.html)),
          }}
        />
      </div>
    )
  }

  adjustHtmlLinks = () => {
    if (!this.htmlContentRef.current) return

    const links = this.htmlContentRef.current.querySelectorAll('a')
    links.forEach(link => link.setAttribute('target', '_blank'))
  }

  render() {
    const { currentYear, currentBrand, currentModel, history } = this.props
    const breadcrumbs = this.buildBreadcrumbs()

    return (
      <div className={classNames('Topics Article', 'one')}>
        <ScreenHead back={() => history.goBack()} landscapeOverlay={true}>
          <em>
            {currentYear} {currentBrand}
          </em>
          <span>{currentModel}</span>
        </ScreenHead>

        <Media type='mobile'>
          <div className='topics-subtitle'>{_.get(this.state.currentTopic, 'title')}</div>
        </Media>

        <div className='screen-content'>
          <Toast ref={this.toast} />

          <Media type='desktop'>
            <>
              <StickyBar className='sticky-topics-bar'>
                <div className='topics-bar'>
                  <BreadCrumb items={breadcrumbs} />
                </div>
              </StickyBar>

              <div className='screen-container'>
                <div className='content-container'>
                  <div className='content-container-wrapper'>
                    <h1 className='topic-title'>{_.get(this.state.currentTopic, 'title')}</h1>
                    {this.renderTopicContent()}
                  </div>
                </div>
              </div>
            </>
          </Media>

          <Media type='mobile'>
            <div className='screen-container'>{this.renderTopicContent()}</div>
          </Media>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ contents: { publications, topics, assetMap }, vehicle }) {
  const pubs = getPublicationsForVehicle(publications.toJS())
  const { currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype } = vehicle
  return {
    publications: getArticlesWithTags(pubs),
    topics,
    currentYear,
    currentModel,
    currentBrand,
    currentProductType,
    currentProductSubtype,
    assetMap,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ContentsActions: bindActionCreators(ContentsActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArticleViewer))
