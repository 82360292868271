import React from 'react'

export function campingTrailer() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373 125" style={{enableBackground: "new 0 0 373 125"}} xmlSpace="preserver">
      <g className="fill">
        <path d="M151 125c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20-8.954 20-20 20zm0-8c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12z"/>
        <path d="M309 94h-24v14H175v-4c0-12.703-10.297-23-23-23h-2c-12.703 0-23 10.297-23 23v4h-27c-5.523 0-10-4.477-10-10V72h101v28h37V72h57v17h37v5h-8v31h-5V94zm-81-24.957V19h-37v50.043H26V23.685a2 2 0 0 1 1.661-1.97L89.978 11H286l60.41 12.493a2 2 0 0 1 1.596 1.959v43.59H228zM34 29v32h56V19L34 29zm307 0l-56-10v42h56V29zM97 19v42h87V19H97zm138 0v42h43V19h-43zM98 0h180a8 8 0 0 1 8 8H90a8 8 0 0 1 8-8z"/>
      </g>
    </svg>
  )
}
