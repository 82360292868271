import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import '../styles/VehicleListItem.scss'
import HeroShot from './HeroShot'

export default class VehicleListItem extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    link: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    heroshot: PropTypes.object.isRequired
  }

  static defaultProps = {
    onClick: null
  }

  render() {
    const { title, subtitle, link, onClick, heroshot } = this.props

    return (
      <Link
        className="VehicleListItem"
        to={link}
        {...(typeof onClick === 'function' ? {onClick} : null)}>
        <div className="vehicle-text">
          <span
            className="title">
            {title}
          </span>
          {subtitle && <span className="subtitle">{subtitle}</span>}
        </div>
        <HeroShot {...heroshot}/>
      </Link>
    )
  }
}
